import React, { useState } from 'react'
import { Button, Popup, Form, Modal, Grid, Message, Input, Dropdown, Checkbox, TextArea } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { customTableEditHandleOnChange, checkForEditFieldErrors, updateSearchEditData } from '../../../utils/tableUtils';
import { logout } from '../../../auth/authRedirect';
import './EditModal.scss';

const EditModal = (props) => {
    const { tableRows, setTableRows, index, apiUrl, openEditModal, setOpenEditModal, tableName, fieldsAdd, fieldsEdit, setMessage, searchState, setSearchState } = props;
    const [editableRow, setEditableRow] = useState(tableRows);
    const [open, setOpen] = useState(props.open);
    const [error, setError] = useState({ visible: false, list: [] });
    const [submittingChanges, setSubmittingChanges] = useState(false);
    const jwtIdToken = useSelector(state => state.authReducer.authResponse.jwtIdToken);
    const exp = useSelector(state => state.authReducer.authResponse.idTokenClaims.exp);

    const openModal = () => setOpen(true);
    const closeModal = () => { setOpen(false); setError({ visible: false, list: [] }); };
    const closeModalCancel = () => { 
        setError({ visible: false, list: [] });
        if (openEditModal.visible) {
            const tr = [...tableRows];
            const previousTableRows = tr.splice(0, tr.length - 1);
            setTableRows(previousTableRows);
            setOpenEditModal({ visible: false, index: null });
        } else {
            setOpen(false);
        }
    };

    const handleOnChange = (e, data, cellName, rIndex, fieldType) => setEditableRow(customTableEditHandleOnChange(e, data, editableRow, cellName, rIndex, fieldType));

    const createField = (cellName, cellData, fieldType, rIndex) => {
        switch(fieldType) {
            case "read-only": return typeof cellData == "boolean" ? cellData === true ? "Yes" : "No" : cellData;
            case "text": return <Input onChange={(e, data) => handleOnChange(e, data, cellName, rIndex, fieldType)} type="text" defaultValue={cellData}></Input>
            case "text-required": return <Input onChange={(e, data) => handleOnChange(e, data, cellName, rIndex, fieldType)} type="text" defaultValue={cellData} required></Input>
            case "text-area": return <TextArea rows={5} onChange={(e, data) => handleOnChange(e, data, cellName, rIndex, fieldType)} defaultValue={cellData.replace(/\\r\\n/g, '\n')}/>
            case "email": return <Input onChange={(e, data) => handleOnChange(e, data, cellName, rIndex, fieldType)} type="email" defaultValue={cellData} required></Input>
            case "checkbox": return <Checkbox onChange={(e, data) => handleOnChange(e, data, cellName, rIndex, fieldType)} defaultChecked={cellData === "Yes" ? true : false} toggle required/>
            case "dropdown":
            case "dropdown-required": return <Dropdown key={rIndex} onChange={(e, data) => handleOnChange(e, data, cellName, rIndex, fieldType)} 
                placeholder={cellData} selection fluid scrolling={true} selectOnBlur={false} options={props.dropdowns[cellName] !== undefined ?
                props.dropdowns[cellName].map((dropdown, index) => { return { key: index, value: dropdown, text: dropdown }}) : []} defaultValue={cellData}/>
            case "number": return <Input onChange={(e, data) => handleOnChange(e, data, cellName, rIndex, fieldType)} type="number" defaultValue={cellData} required/>; 
            case "number-min-max": return <Input onChange={(e, data) => handleOnChange(e, data, cellName, rIndex, fieldType)} type="number" 
                min={props.numbers[cellName].min} max={props.numbers[cellName].max} defaultValue={cellData} required/>
            default: return <></>
        }
    }

     const checkForChanges = (tableRows, editableRow) => JSON.stringify(tableRows) === JSON.stringify(editableRow) ? true : false;

     const handleSubmit = async() => {
        setSubmittingChanges(true);

        const fieldErrors = checkForEditFieldErrors(editableRow, index, fieldsAdd, fieldsEdit, props.headers);

        if (fieldErrors.length > 0) {
            setError({visible: true, list: fieldErrors});
            setSubmittingChanges(false);
            return;
        } else {
            setError({visible: false, list: []});
        }

        let postData;
        if (tableName === "company-details-table") {
            postData = { ...editableRow[index], CompanyName: tableRows[index]["CompanyName"], NewCompanyName: editableRow[index]["CompanyName"] }
        } else {
            postData = { ...editableRow[index] };
        }

        try {
            await axios.put(apiUrl, { ...postData }, 
                { headers: { Authorization: `Bearer ${jwtIdToken}`, "Ocp-Apim-Subscription-Key": process.env.REACT_APP_OCP_SUBSCRIPTION_KEY }})
            setMessage({ visible: true, positive: true, negative: false, header: "Changes saved", text: "Your changes have been successfully updated." });
            setTableRows(editableRow);
            if (searchState !== undefined && searchState !== "") {
                setSearchState(updateSearchEditData(searchState, editableRow[index]));
            }
            closeModal();
        } catch (error) {
            if (new Date().getTime() >= exp * 1000) {
                logout();
            } else {
                console.error(error);
                setMessage({ visible: true, positive: false, negative: true, header: `Unable to save changes.`, text: error.response.data.ErrorMessage });
                closeModalCancel();
            }
        } finally { 
            setSubmittingChanges(false);
            setError({ visible: false, list: [] });
        }
     }

    const modalRow = Object.entries(tableRows[index]).map((cell, cIndex) => {
        return open === true && cell[0] !== "ReactKey" ?
        <React.Fragment key={cIndex}>
            <Grid>
                <Grid.Column width={6} className="label-column">
                    <label className="edit-modal-label">{`${props.fieldHeaders[cIndex]}:`}</label>
                </Grid.Column>
                <Grid.Column className={`${props.fieldsEdit[cell[0]]}-field`} width={10}>
                    <Form.Input>
                        <>{createField(cell[0], cell[1], props.fieldsEdit[cell[0]], props.index)}</>
                    </Form.Input>
                </Grid.Column>
            </Grid>
        </React.Fragment>
        :
        <React.Fragment key={cIndex}></React.Fragment>
    });

    return (
    <>
        <Popup content="Edit row" trigger={<Button onClick={openModal} icon="edit"></Button>}/>
        <Modal
            className="edit-modal"
            as={Form}
            onClose={void(0)}
            onOpen={openModal}
            open={open}
            onSubmit={handleSubmit}
            >
            <Modal.Header>Edit row</Modal.Header>
            <Modal.Content className="edit-modal-content" scrolling>
               {modalRow}
            </Modal.Content>
            <Modal.Actions>
                {error.visible ? 
                    <Message
                        className="edit-modal-error-message"
                        negative
                        header="Error"
                        list={error.list}
                    ></Message>
                    :
                    <></>
                }
                <Button type="button" onClick={closeModalCancel} content="Cancel"/>
                <Button disabled={checkForChanges(tableRows[index], editableRow[index]) || submittingChanges === true} 
                    loading={submittingChanges === true} type="submit" icon="save" content="Save changes" color="green"/>
            </Modal.Actions>
        </Modal>
    </>
    );
}

EditModal.propTypes = {
    tableName: PropTypes.string,
    tableRows: PropTypes.array,
    setTableRows: PropTypes.func,
    editableRow: PropTypes.array,
    index: PropTypes.number,
    apiUrl: PropTypes.string
}

EditModal.defaultProps = {
    apiUrl: null
};

export default EditModal;
