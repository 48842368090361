import { SET_AUTH_DETAILS, SET_AUTH_DETAILS_ERROR } from '../actions/authActions';
import { msalAuth } from '../App';
import { getClientId } from '../auth/authConfig';
import { getCookie } from '../utils/cookieUtils';

const initialState = {
    authResponse: {
      ...msalAuth.getAccount(),
      jwtIdToken: getCookie(`msal.${getClientId()}.idtoken`)
    }
};

const authReducer = (state = initialState, action) => {
    switch (action.type) {
      case SET_AUTH_DETAILS:
        return { ...state, authResponse: action.payload };
      case SET_AUTH_DETAILS_ERROR:
        return { ...state }
      default:
        return state;
    };
};

export default authReducer;