import React, { useState } from 'react';
import { connect } from 'react-redux';
import Cookies from 'universal-cookie';
import { Form, Checkbox, Button, Message } from 'semantic-ui-react';
import './TermsAndConditions.scss';

const TermsAndConditions = props => {
    const { oid } = props;
    const cookies = new Cookies();
    const [checkboxChecked, setCheckboxChecked] = useState(false);
    const [showError, setShowError] = useState(false);

    const handleCheckboxOnChange = (e, { checked }) => setCheckboxChecked(checked);

    const handleAcceptTermsAndConditions = () => {
        if (checkboxChecked === true) {
            const date = Date.now();
            const current = new Date();
            const longExpirationDate = new Date();
            longExpirationDate.setFullYear(current.getFullYear() + 100);
    
            cookies.set(`${oid}_termsAndConditions`, { oid, accepted: true, dateAccepted: date }, {
                path: '/',
                expires: longExpirationDate,
            });
            window.location.reload();
        } else {
            if (setShowError !== true) {
                setShowError(true);
            }
        }
    }

    const WarningMessage = () => {
        return showError === true ?  
        (
            <Message warning>
            <p>Please agree to the terms and conditions to continue.</p>
          </Message>
        )
        :
        <></>
    }

    const termsAndConditionsText = `
    Trade Members’ Terms of Use

    1. Terms capitalised but not defined shall have the same meaning given to them in the Statement of Work between Capita Business Services Limited (“Capita”) and Water UK governing the provision of the Levels of Service (LoS) System (the “Statement of Work”).

    2. Subject to (f) below, and in consideration of Water UK’s payment of the Licence Fees under the Statement of Work and its compliance with the terms of the Statement of Work and Managed Services Schedule (MSS) between Capita and Water UK, Capita grants to Trade Member, a non-exclusive, non-transferable, non-sub-licensable and revocable licence solely within the Territory and for the Authorised Purpose for Trade Member to use solely by and through Authorised Users, the Solution to view and/or upload metrics data in relation to their company and other Trade Members, dependent on user permissions. (Terms defined in the MSS and Statement of Work shall bear the same meaning when used in these terms).

    3. In consideration of being permitted to use the Solution and Support Services, Trade Member shall comply with the following terms and conditions (the “Terms”):
            a. The Solution shall be hosted on Microsoft’s Azure platform and Trade Member shall comply with the Hosting Provider terms of use set out at https://docs.microsoft.com/en-us/partner-center/agreements or such other terms as are notified to Trade Member from time to time; 
            b.	Capita may limit use of the telephone helpline to certain designated contacts who are appropriately qualified, trained, and authorised to request the Support Services, such designated contacts being notified to Capita by Water UK;
            c.	Trade Member shall maintain procedures to facilitate reconstruction of any lost or altered files, data, or programmes to the extent deemed necessary by Trade Member, and Trade Member agrees that Capita shall not be liable under any circumstances for any consequence arising from lost or corrupted data, files, or programmes;
            d.	Trade Member shall be solely responsible for carrying out all necessary back-up procedures for Trade Member’s own benefit, to ensure that data integrity can be maintained in the event of loss of data for any reason;
            e.	Trade Member shall provide telephone and appropriate access (for example, via a VPN connection) to facilitate the remote investigation of Solution issues; 
            f.	Trade Member shall have no claim or recourse against Capita for any interruption, delay, delivery failure, error, loss, suspension, claim, or other liability in relation to the Solution or Support Services and Capita shall have no liability to Trade Member in respect of the same;
            g.	Trade Member acknowledges and agrees that Capita and/or its licensors own all intellectual property rights in the Solution and Support Services. Trade Member has no rights to, under, or in, any patents, copyright, database right, trade secrets, trade names, trademarks (whether registered or unregistered), or any other rights or licences in respect of the Solution and Support Services; 
            h.	Trade Member shall comply with all applicable laws and regulations, including data protection legislation and regulation, in its use of the Solution and Support Services;
                    i.	Trade Member shall comply with the following obligations in the Managed Services Schedule as if they were the “Client”:
                    i.	Clauses 3.3 and 3.7; 
                    ii.	Clauses 4.1 and 4.3; 
                    iii.	Clause 5.3(B)(3); 
                    iv.	Clauses 6.1, 6.3, and 6.4;
                    v.	Clauses 7.9 and 7.10; and 
                    vi.	Clause 10.3. 
            j.	Trade Member shall do nothing to cause Water UK to be in breach of the MTC, Managed Services Schedule, or Statement of Work. 
            k.	Subject to (l), (m) and (n) below, Trade Member shall indemnify and hold Capita harmless against all direct losses, demands, claims, actions, proceedings, damages, payments, costs (including legal and other professional costs), expenses or other liabilities (“Losses”) arising out of or in connection with any breach of these Terms. 
            l.	Trade Member shall not be liable to Capita in respect of any: 
                    i.	indirect, special or consequential Losses; 
                    ii.	loss of profits (except with respect to the Fees or other Capita charges specified in the Statement of Work), loss of sales, anticipated savings or goodwill, loss of business opportunity or contracts in each case whether direct or indirect, in each case even if such Losses could have been foreseen.
            m.	The liability of Trade Member for any loss, damage, or expense suffered by Capita arising out of or in connection with its use of the Solution or these Terms, including in respect of the indemnity in (k), shall not exceed £140,000 per breach, provided always that Trade Member shall not be liable for any breach by any other Trade Member or Water UK. 
            n.	The limits in (l) and (m) shall not apply to:
                    i.	death or personal injury caused by negligence;
                    ii.	fraud or fraudulent misrepresentation; 
                    iii.	the indemnity at (o) below; 
                    iv.	any other matter for which it would be unlawful to exclude or attempt to exclude liability.
            o.	Trade Member shall indemnify and hold Capita (and applicable Group members) harmless against all direct Losses arising out of or in connection with any infringement (actual or alleged) of a third party's IPR which results from Capita’s use of the Trade Member’s IPR in connection with the Solution or Support Services provided always that this indemnity shall be subject to the limits set out in MTC Clause 7.6(B) and 7.7 (mutatis mutandis). 
            p.	These Terms and any dispute or claim arising out of or in connection with them or their subject matter or formation (including non-contractual disputes or claims) shall be governed by and construed in accordance with the law of England and Wales.
            q.	The courts of England and Wales shall have exclusive jurisdiction to settle any dispute or claim arising out of or in connection with these Terms or their subject matter or formation (including non-contractual disputes or claims).

    4.	By [signing the below box] the Trade Member confirms in favour of Capita:
            a.	that they have read and shall comply with the Terms; and 
            b.	without prejudice to any other right or remedy Capita may have and in addition to Capita’s rights to suspend use of the Solution or Support Services under the terms of the MTC and Managed Services Schedule, any failure by the Trade Member to comply with these Terms shall give Capita the right to suspend use of the Solution or Support Services in respect of that Trade Member on reasonable notice to that Trade Member. 
    `;

    return (
        <div className="terms-and-conditions-container">
            <h1>Terms and Conditions</h1>
            <WarningMessage/>
            <Form>
                <Form.TextArea value={termsAndConditionsText} readOnly />
                <Checkbox onChange={handleCheckboxOnChange} checked={checkboxChecked} label='I agree to the Terms and Conditions' />
            </Form>
            <Button onClick={handleAcceptTermsAndConditions}>Continue</Button>
        </div>
    )
}

const mapStateToProps = (state) => {
    const oid = state.authReducer.authResponse.idTokenClaims.oid;
    return {
        oid
    }
} 

export default connect(mapStateToProps)(TermsAndConditions);
