import React from 'react';
import { connect } from 'react-redux';
import { setAuthDetails } from '../../../actions/authActions';
import PropTypes from 'prop-types';
import { Message } from 'semantic-ui-react';

const ResponseMessage = props => {
    const { setMessage} = props;

    const handleDismiss = () => {
        setMessage({ visible: false, positive: false, negative: false, header: "", text: "" }); 
    } 

    const ShowMessage = () => {
        return (
            <div className="response-message-container">
                <Message style={{ margin: "1em auto" }} compact positive={props.positive} negative={props.negative} onDismiss={handleDismiss}>
                    <Message.Header>{props.header}</Message.Header>
                    <p>{props.text}</p>
                </Message>
            </div>
        );
    }

    return <ShowMessage/>
}

ResponseMessage.propTypes = {
    header: PropTypes.string,
    text: PropTypes.string,
    positive: PropTypes.bool,
    negative: PropTypes.bool
}

ResponseMessage.defaultProps = {
    header: "",
    text: "",
    positive: false,
    negative: false
}

const mapStateToProps = (state) => {
    const exp = state.authReducer.authResponse.idTokenClaims.exp;
    const account = state.accountReducer.Account;
    const jwtIdToken =state.authReducer.authResponse.jwtIdToken;
    return {
        exp,
        account,
        jwtIdToken
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setAuthDetails: (authResponse) => dispatch(setAuthDetails(authResponse)),
    }
  }

export default connect(mapStateToProps, mapDispatchToProps)(ResponseMessage);
