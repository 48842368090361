import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Table, Pagination, Search, Modal, Button, Icon } from 'semantic-ui-react';
import MetricDefinitionsTableEditModal from './MetricDefinitionsTableEditModal';
import MetricDefinitionsTableAddModal from './MetricDefinitionsTableAddModal';
import ResponseMessage from '../CustomTable/ResponseMessage/ResponseMessage';
import { getDefaultSortDirection, handleTableSort, getSortIconDirection, searchFilter } from '../../utils/tableUtils';
import { metricsPagePublicCsvReport, metricsPagePrivateCsvExport } from '../../utils/csvUtils';
import '../CustomTable/CustomTable.scss';

const MetricDefinitionsTable = props => {
    const { headers, sortFields, fieldHeaders, sortFieldHeaders, rows, tableName } = props;
    const [tableRows, setTableRows] = useState(rows);
    const [openEditModal, setOpenEditModal] = useState({ visible: false, index: null});
    const [message, setMessage] = useState({ visible: false, positive: false, negative: false, header: "", text: "" });
    const [searchState, setSearchState] = useState(tableRows);
    const paginationInitialState = { activePage: 1, rowStart: 0, rowEnd: 10 };
    const [pagination, setPagination] = useState(paginationInitialState);
    const [sortDirection, setSortDirection] = useState(getDefaultSortDirection(sortFieldHeaders));

    const handleSearchChange = (e, { value }) => {
        if (pagination !== paginationInitialState) {
            setPagination(paginationInitialState);
        }
        const filteredData = searchFilter(tableRows, value);
        setSearchState(filteredData);
    }

    const handlePaginationChange = (e, { activePage }) => {
        const rowStart = (activePage - 1) * paginationInitialState.rowEnd;
        const rowEnd = activePage * paginationInitialState.rowEnd;
        setPagination({ activePage, rowStart, rowEnd });
    }

    const tableHeaders = headers.map((header, index) => {

        return (
        <Table.HeaderCell
            key={index}
            className="table-header"
            textAlign="center"
            onClick={() => handleTableSort(sortFields[header], header, sortDirection[sortFields[header]], tableRows, searchState, getDefaultSortDirection(fieldHeaders), setSortDirection, setSearchState, setTableRows)}
            content={
            <>
                {header} 
                {header === sortDirection.lastClicked ? <Icon className="table-sort-icon" size="small" name={getSortIconDirection(sortDirection[sortFields[header]])}></Icon> : <></>}
            </>
            }>
        </Table.HeaderCell>
        );
    });

    const TableRow = ({ rIndex, row }) => {
        return (
            <Table.Row key={rIndex}>
                {props.editable === true ? 
                <Table.Cell textAlign="center" className="table-action-buttons" collapsing>
                    <MetricDefinitionsTableEditModal
                        tableName={props.tableName}
                        open={openEditModal.visible}
                        tableRows={tableRows}
                        setTableRows={setTableRows}
                        apiUrl={props.apiUrl}
                        headers={props.headers}
                        fieldsEdit={props.fieldsEdit}
                        fieldsAdd={props.fieldsAdd}
                        dropdowns={props.dropdowns}
                        index={tableRows.findIndex(x => x.ReactKey === row.ReactKey)}
                        openEditModal={openEditModal}
                        setOpenEditModal={setOpenEditModal}
                        setMessage={setMessage}
                        searchState={searchState}
                        setSearchState={setSearchState}
                        data={props.data}
                        setData={props.setData}
                    />
                </Table.Cell>
                : <></>}
                <Table.Cell singleLine textAlign="center">{row.Code}</Table.Cell>
                <Table.Cell textAlign="center">{row.Title}</Table.Cell>
                <Table.Cell singleLine textAlign="center">
                    <Modal trigger={<li className="view-definition">View Definition</li>} 
                    header={`Metric Definition: ${row.Code}`}
                    content={<Modal.Content scrolling>
                        <div className="view-metrics-definition-modal display-linebreak"><p>{row.Definition.replace(/\\r\\n/g, '\n')}</p></div>
                        </Modal.Content>} 
                        actions={[{ key: 'OK', content: 'OK', positive: true }]}/>
                </Table.Cell>
                {tableName !== "metric-report-table-public" ?
                <>
                    <Table.Cell textAlign="center" singleLine>{row.ValidFrom}</Table.Cell>
                    <Table.Cell textAlign="center" singleLine>{row.ValidTo}</Table.Cell>
                </>
                : <></>}
                <Table.Cell textAlign="center">{row.Type}</Table.Cell>
                <Table.Cell textAlign="center">{row.ReportType}</Table.Cell>
                <Table.Cell textAlign="center">{row.Target}</Table.Cell>
                {tableName !== "metric-report-table-public" ?
                <>
                    <Table.Cell textAlign="center">{row.DMeXQualitative}</Table.Cell>
                    <Table.Cell textAlign="center">{row.DMeXQuantitative}</Table.Cell>
                    <Table.Cell textAlign="center">{row.DMexReportableQualitative}</Table.Cell>
                    <Table.Cell textAlign="center">{row.DMexReportableQuantitative}</Table.Cell>
                    <Table.Cell textAlign="center">{row.Category}</Table.Cell>
                    <Table.Cell textAlign="center">{row.CustomerType}</Table.Cell>
                    <Table.Cell textAlign="center">{row.CodesViewable}</Table.Cell>
                </>
                : <></>}
            </Table.Row>
        );
    }

    const Body = () => {
        if (searchState.length === 0) {
            return <Table.Row><Table.Cell colSpan='16'><h1 className="table-no-results">No results found.</h1></Table.Cell></Table.Row>
        } else if (searchState.length > 0) {
            return searchState.slice(pagination.rowStart, pagination.rowEnd).map((row, rIndex) => {
                return <TableRow key={rIndex} rIndex={rIndex} row={row}/>
            });
        } else {
            return tableRows.slice(pagination.rowStart, pagination.rowEnd).map((row, rIndex) => {
                return <TableRow key={rIndex} rIndex={rIndex} row={row}/>
        })}
    };

    const Footer = () => {
        return (
            <Table.Row>
                <Table.HeaderCell colSpan='16'>
                    <Pagination
                        defaultActivePage={pagination.activePage}
                        onPageChange={handlePaginationChange}
                        totalPages={searchState.length === 0 ? 0 : searchState.length > 0 ? Math.ceil(searchState.length / paginationInitialState.rowEnd) : Math.ceil(tableRows.length / paginationInitialState.rowEnd)}
                        ellipsisItem={null}
                        disabled={searchState.length === 0 ? true : searchState.length > 0 ? searchState.length < paginationInitialState.rowEnd : tableRows.length < paginationInitialState.rowEnd}
                    />
                </Table.HeaderCell>
            </Table.Row>
        )
    };

    const ExportAsCsv = () => {
        if (tableName === "metric-report-table-public") {
            return <Button onClick={() => metricsPagePublicCsvReport(tableRows, "Metrics Report")}>Export to Excel</Button>
        } else if (tableName === "metric-report-table-private") {
            return  <Button onClick={() => metricsPagePrivateCsvExport(tableRows, "Metrics Report")}>Export to Excel</Button>

        } else if (tableName === "metrics-definitions-table") {
            return  <Button onClick={() => metricsPagePrivateCsvExport(tableRows, "Metrics Definitions")}>Export to Excel</Button>
        } else { return <></> }
    };

    const renderTable = () => {
        return tableRows.length > 0
        ?
            <div className="custom-table-container metrics-table">
                {message.visible ? <ResponseMessage {...message} setMessage={setMessage}/> : <></>}
                <Search className="table-search" placeholder="Search" onSearchChange={handleSearchChange} open={false}></Search>
                <Table id="table" striped celled compact>
                    <Table.Header>
                        <Table.Row>
                            {props.editable ? <Table.HeaderCell singleLine textAlign="center">Action</Table.HeaderCell> : <></>}
                            {tableHeaders}
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        <Body/>
                    </Table.Body>
                    <Table.Footer>
                        <Footer/>
                    </Table.Footer>
                </Table>
                {props.editable ?
                <MetricDefinitionsTableAddModal
                    tableName={props.tableName}
                    initialFields={props.initialFields}
                    open={openEditModal.visible}
                    tableRows={tableRows}
                    setTableRows={setTableRows}
                    apiUrl={props.apiUrl}
                    headers={props.headers}
                    fieldsEdit={props.fieldsEdit}
                    fieldsAdd={props.fieldsAdd}
                    dropdowns={props.dropdowns}
                    index={tableRows.length + 1}
                    openEditModal={openEditModal}
                    setOpenEditModal={setOpenEditModal}
                    setMessage={setMessage}
                    searchState={searchState}
                    setSearchState={setSearchState}
                />
                : <></>}
                <ExportAsCsv/>
            </div>
        :
        <h2 className="no-table-data">No table data.</h2>
    };

    return renderTable();
}

MetricDefinitionsTable.propTypes = {
    tableName: PropTypes.string,
    headers: PropTypes.array,
    body: PropTypes.array,
    fieldsEdit: PropTypes.object,
    fieldsAdd: PropTypes.object,
    dropdowns: PropTypes.object,
    numbers: PropTypes.object,
    apiUrl: PropTypes.string,
    editable: PropTypes.bool,
    rowDeletion: PropTypes.bool,
    initialFields: PropTypes.object
};

MetricDefinitionsTable.defaultProps = {
    headers: [],
    body: [],
    fieldsEdit: {},
    fieldsAdd: {},
    dropdowns: {},
    numbers: {},
    apiUrl: null,
    editable: false,
    rowDeletion: false,
    initialFields: {}
};

export default MetricDefinitionsTable;