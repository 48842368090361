export const HEADERS = [
    "Forename",
    "Surname",
    "Email Address",
    "Active",
    "User Role",
    "Company",
];

export const FIELD_HEADERS = [
    "Forename",
    "Surname",
    "Email Address",
    "Active",
    "User Role",
    "Company Name",
];

export const INITIAL_STATE = { 
    Forename: "",
    Surname: "",
    EmailAddress: "",
    Active: "Yes",
    UserRole: "",
    CompanyName: ""
};

export const FIELDS = {
    Forename: "text",
    Surname: "text",
    EmailAddress: "email",
    Active: "checkbox",
    UserRole: "dropdown-required",
    CompanyName: "dropdown-required"
};

export const SORT_FIELDS = {
    "Forename": "Forename",
    "Surname": "Surname",
    "Email Address": "EmailAddress",
    "Active": "Active",
    "User Role": "UserRole",
    "Company": "CompanyName"
};