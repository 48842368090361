import React, { useEffect } from 'react';
import { models } from "powerbi-client";
import { PowerBIEmbed } from 'powerbi-client-react';
import { connect } from 'react-redux';
import { setAuthDetails } from '../../../actions/authActions';
import { getPowerBiPublicConfig, setPowerBiAuthenticatedConfig } from '../../../actions/powerBIActions';
import { getAccountDetails } from '../../../actions/accountActions';
import { logout } from '../../../auth/authRedirect';
import './PowerBiAuditLog.scss';

const PowerBiAuditLog = props => {
    const { jwtIdToken, powerBiAuditLogConfig, accountReducer, authenticated, getPowerBiPublicConfig, 
        setPowerBiAuthenticatedConfig, getAccountDetails, setAuthDetails, exp, setErrorMessage, auditLogExp } = props;
    const { Account } = accountReducer;

    if (auditLogExp === null) {
        logout();
    }

    useEffect(() => {
        const tokenCheck = async() => {
            const currentTime = new Date().getTime();
                try {
                    if (currentTime >= exp * 1000) {
                        logout();
                    } else if (currentTime > new Date(new Date(auditLogExp).getTime()) - (15 * 60000)) {
                        await setAuthDetails();
                        await getAccountDetails();
                        await setPowerBiAuthenticatedConfig(jwtIdToken);
                    }
                } catch (error) {
                    logout();
                }

            const performTokenCheck = setInterval(() => {
                tokenCheck();
            }, 2700000);
            return () => clearInterval(performTokenCheck);
        }
        tokenCheck();
    }, [jwtIdToken, Account, getPowerBiPublicConfig, setPowerBiAuthenticatedConfig, authenticated,
        setAuthDetails, getAccountDetails, exp, auditLogExp, setErrorMessage]);

    const reportConfig = {
		type: 'report',
		embedUrl: `https://app.powerbi.com/reportEmbed?reportId=${powerBiAuditLogConfig?.rID}&groupId=${powerBiAuditLogConfig?.wID}`,
        tokenType: models.TokenType.Embed,
        accessToken: powerBiAuditLogConfig?.token,
        settings: {
            navContentPaneEnabled: false,
			panes: {
				filters: {
					expanded: false,
					visible: false
				}
			},
			background: models.BackgroundType.Transparent,
		}
    };

    const eventHandlersMap = new Map([
		['loaded', function () {
			console.log('Report has loaded');
		}],
		['rendered', function () {
            console.log('Report has rendered');
		}],
		['error', function (event) { 
			if (event) {
				console.error(event.detail);
			}
		}]
    ]);
	
    return (
        <PowerBIEmbed
            embedConfig = { reportConfig }
            eventHandlers = { eventHandlersMap }
            cssClassName = { "power-bi-audit-log-dashboard" }
            getEmbeddedComponent = { (embeddedReport) => { }}
        />
    )
}

const mapStateToProps = (state) => {
    const powerBiAuditLogConfig = state.powerBiReducer.powerBiAuditLogConfig;
    const jwtIdToken = state.authReducer.authResponse !== null ? state.authReducer.authResponse.jwtIdToken : null;
    const accountReducer = state.accountReducer;
    const authenticated = state.powerBiReducer.authenticated;
    const exp = state.authReducer.authResponse.idTokenClaims?.exp !== undefined ? state.authReducer.authResponse.idTokenClaims.exp : null;
    const auditLogExp = state.powerBiReducer.powerBiAuditLogConfig?.expiration;
    return {
        powerBiAuditLogConfig,
        jwtIdToken,
        accountReducer,
        authenticated,
        exp,
        auditLogExp
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getPowerBiPublicConfig: () => dispatch(getPowerBiPublicConfig()),
        setPowerBiAuthenticatedConfig: (jwtIdToken) => dispatch(setPowerBiAuthenticatedConfig(jwtIdToken)),
        setAuthDetails: () => dispatch(setAuthDetails()),
        getAccountDetails: (jwtIdToken) => dispatch(getAccountDetails(jwtIdToken))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PowerBiAuditLog);