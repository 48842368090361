import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { setAuthDetails } from '../../actions/authActions';
import { Loader } from 'semantic-ui-react';
import axios from 'axios';
import ReportingPeriodsTable from '../../components/reportingPeriodsTable/ReportingPeriodsTable';
import './ReportingPeriods.scss';
import { HEADERS, FIELDS_ADD, FIELDS_EDIT, INITIAL_FIELDS, SORT_FIELDS, FIELD_HEADERS } from '../../components/reportingPeriodsTable/ReportingPeriodsTableSchema';
import { USER_ROLE } from '../../constants/userConsts';
import moment from 'moment';
import { logout } from '../../auth/authRedirect';

const ReportingPeriods = props => {
    const [data, setData] = useState(null);
    const [error, setError] = useState({ visible: false, message: "" });
    const { setAuthDetails, jwtIdToken, exp, userRole } = props;

    useEffect(() => {
        const fetchData = async() => {
            await setAuthDetails();
            try {
                const { data } = await axios.post(process.env.REACT_APP_PERIODS_URL, { origin: "periods" },
                    { headers: { Authorization: `Bearer ${jwtIdToken}`, "Ocp-Apim-Subscription-Key": process.env.REACT_APP_OCP_SUBSCRIPTION_KEY }});
                const rowsWithKeys = Object.values([...data.Rows]).map((row, index) => 
                { 
                    const current = moment(moment(), 'DD-MM-YYYY').isBetween(moment(row.PeriodEnd, 'DD-MM-YYYY'), moment(row.Deadline, 'DD-MM-YYYY'), null, '(]') ? "Yes" : "No";
                    return { ReactKey: index, Current: current, ...row }
                });
                data["Rows"] = rowsWithKeys;
                setData(data);
            } catch (error) {
                if (new Date().getTime() >= exp * 1000) {
                    logout();
                } else {
                    console.error(error);
                    setError({visible: true, message: `Unable to connect to Reporting Periods API.\n ${error.response.data.ErrorMessage}`});
                }
            }
        }
        fetchData();
    }, [setAuthDetails, jwtIdToken, exp]);

    const ShowTable = () => {
        const initialCompanies = data.PreSelectedCompanies.map((companyName) => {
            return { CompanyName: companyName, Eligible: "Yes", PeriodOpen: "Yes", Submitted: "No" }
        });

        if (userRole === USER_ROLE.WATER_COMPANY_ADMIN) {
            return (
                <>
                    <h1>Reporting Periods</h1>
                    <ReportingPeriodsTable
                        apiUrl={process.env.REACT_APP_PERIODS_URL}
                        tableName="reporting-periods-table"
                        headers={HEADERS}
                        rows={data.Rows}
                        fieldsEdit={FIELDS_EDIT}
                        fieldsAdd={FIELDS_ADD}
                        initialFields={{...INITIAL_FIELDS, Companies: initialCompanies}}
                        fieldHeaders={FIELD_HEADERS}
                        sortFields={SORT_FIELDS}
                        dropdowns={data.Dropdowns}
                        editable={false}
                    />
                </>
            )
        } else if (userRole === USER_ROLE.SUPER_ADMIN) {
            return (
                <>
                    <h1>Reporting Periods</h1>
                    <ReportingPeriodsTable
                        apiUrl={process.env.REACT_APP_PERIODS_URL}
                        tableName="reporting-periods-table"
                        headers={HEADERS}
                        rows={data.Rows}
                        fieldsEdit={FIELDS_EDIT}
                        fieldsAdd={FIELDS_ADD}
                        initialFields={{...INITIAL_FIELDS, Companies: initialCompanies}}
                        fieldHeaders={FIELD_HEADERS}
                        sortFields={SORT_FIELDS}
                        dropdowns={data.Dropdowns}
                        editable={true}
                    />
                </>
            )
        } else { return <></> };
    };

    const ShowLoading = () => {
        return error.visible === false ?
            <Loader active inline='centered'>Retrieving Reporting Periods</Loader> : error.message.split("\n").map((line, i) => <h1 key={i} className="error">{line}</h1>);
    };

    return (
        <div className="reporting-periods-container">
            {data === null ? <ShowLoading/> : <ShowTable/>}
        </div>
    );
}

const mapStateToProps = (state) => {
    const jwtIdToken = state.authReducer.authResponse.jwtIdToken;
    const exp = state.authReducer.authResponse.idTokenClaims.exp;
    const userRole = state.authReducer.authResponse.idTokenClaims.extension_UserRole;
    return {
        jwtIdToken,
        exp,
        userRole
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        setAuthDetails: () => dispatch(setAuthDetails())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportingPeriods);