export const FOOTER_LINKS = {
    SOCIAL_MEDIA_LINKS: [
        { title: "Twitter", icon: "twitter", url: "https://www.twitter.com/WaterUK" },
        { title: "LinkedIn", icon: "linkedin square", url: "https://www.linkedin.com/company/water-uk" }
    ],
    WATER_UK_LINKS: [
        [
            { title: 'Find your supplier', url: 'https://www.water.org.uk/advice-for-customers/find-your-supplier/' },
            { title: 'Publications', url: 'https://www.water.org.uk/publications/' },
            { title: 'Marketplace for ideas', url: 'https://www.water.org.uk/policy-topics/improving-services-for-customers/marketplace-for-ideas/' },
          ],
          [
            { title: 'Blog', url: 'https://www.water.org.uk/blog/' },
            { title: 'Campaigns', url: 'https://www.water.org.uk/news-campaigns/campaigns/' },
            { title: 'Accessibility', url: 'https://www.water.org.uk/website-accessibility/' },
          ],
          [
            { title: 'Contact', url: 'https://www.water.org.uk/contact/' },
            { title: 'Privacy and cookie policy', url: 'https://www.water.org.uk/privacy-and-cookie-policy/' },
            { title: 'Terms and conditions', url: 'https://www.water.org.uk/terms-and-conditions/' },
          ],
    ],
    CAPITA_LOGO: { title: "Capita", url: "https://www.capita.com/consulting" }
};