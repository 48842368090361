import axios from 'axios';

export const GET_ACCOUNT_DETAILS = "GET_ACCOUNT_DETAILS";
export const GET_ACCOUNT_DETAILS_ERROR = "GET_ACCOUNT_DETAILS_ERROR";

export const getAccountDetails = () => {
    return async (dispatch, getState) => {
        const jwtIdToken = getState().authReducer.authResponse.jwtIdToken;
        try {
            const accountDetails = await axios.post(process.env.REACT_APP_LOGIN_URL, {}, 
            { headers: { Authorization: `Bearer ${jwtIdToken}`, "Ocp-Apim-Subscription-Key": process.env.REACT_APP_OCP_SUBSCRIPTION_KEY }});
            dispatch({
                type: GET_ACCOUNT_DETAILS,
                payload: accountDetails.data
            })
        } catch (error) {
            dispatch({
                type: GET_ACCOUNT_DETAILS_ERROR,
                payload: error.message
            })
        }
    }
};