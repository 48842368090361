import axios from 'axios';

export const GET_POWER_BI_PUBLIC_CONFIG = "GET_POWER_BI_PUBLIC_CONFIG";
export const GET_POWER_BI_PUBLIC_CONFIG_ERROR = "GET_POWER_BI_PUBLIC_CONFIG_ERROR";

export const SET_POWER_BI_AUTHENTICATED_CONFIG = "SET_POWER_BI_AUTHENTICATED_CONFIG";
export const SET_POWER_BI_AUTHENTICATED_CONFIG_ERROR = "SET_POWER_BI_AUTHENTICATED_CONFIG_ERROR";
export const RESET_NUM_OF_ATTEMPTS = "RESET_NUM_OF_ATTEMPTS";

export const getPowerBiPublicConfig = () => {
    return async (dispatch, getState) => {
        const numOfAttempts = getState().powerBiReducer.numOfAttempts;
        try {
           const powerBiConfig = await axios.post(process.env.REACT_APP_PBI_PUBLIC_URL, {},
           { headers: { "Ocp-Apim-Subscription-Key": process.env.REACT_APP_OCP_SUBSCRIPTION_KEY }});
            dispatch({
                type: GET_POWER_BI_PUBLIC_CONFIG,
                payload: {
                    powerBiMainDashboardConfig: powerBiConfig.data,
                    numOfAttempts: 0
                }
            })
        } catch (error) {
            dispatch({
                type: GET_POWER_BI_PUBLIC_CONFIG_ERROR,
                payload: {
                    error: error.message,
                    numOfAttempts: numOfAttempts + 1
                }
            })
        }
    }
};

export const setPowerBiAuthenticatedConfig = () => {
    return async (dispatch, getState) => {
        const jwtIdToken = getState().authReducer.authResponse.jwtIdToken;
        const numOfAttempts = getState().powerBiReducer.numOfAttempts;
        try {
           let powerBiConfig = await axios.post(process.env.REACT_APP_PBI_TOKEN_URL, {},
           { headers: { Authorization: `Bearer ${jwtIdToken}`, "Ocp-Apim-Subscription-Key": process.env.REACT_APP_OCP_SUBSCRIPTION_KEY }});

            powerBiConfig.data.MainToken["rID"] = powerBiConfig.data.MainrID;
            powerBiConfig.data.MainToken["wID"] = powerBiConfig.data.MainwID;
            powerBiConfig.data.AuditToken["rID"] = powerBiConfig.data.AuditrID;
            powerBiConfig.data.AuditToken["wID"] = powerBiConfig.data.AuditwID;

            dispatch({
                type: SET_POWER_BI_AUTHENTICATED_CONFIG,
                payload: {
                    powerBiMainDashboardConfig: powerBiConfig.data.MainToken,
                    powerBiAuditLogConfig: powerBiConfig.data.AuditToken,
                    numOfAttempts: 0
                }
            })
        } catch (error) {
            dispatch({
                type: SET_POWER_BI_AUTHENTICATED_CONFIG_ERROR,
                payload: {
                    error: error.message,
                    numOfAttempts: numOfAttempts + 1
                }
            })
        }
    }
};

export const resetNumOfAttempts = (num = 0) => {
    return {
        type: RESET_NUM_OF_ATTEMPTS,
        payload: num
    }
};