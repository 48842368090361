import React from 'react';
import { DEVELOPER_CONTACT_DETAILS } from '../../constants/contactConsts';
import { Link } from 'react-router-dom';
import './Homepage.scss';

const Homepage = () => {
    const Notice = () => {
        return (
            <div className="homepage-notice">
                <h3>Water UK Levels of Service</h3>
                <p>In 2015, Water UK produced a set of standards that developers customers and others can expect from water companies in relation to the provision of infrastructure for development. The standards were introduced in April 2015 and since July 2015 Water UK has been reporting on levels of services achieved.</p>

                <p>Performance is monitored monthly and a quarterly report is published, which provides data on the levels of service achieved by water companies in delivering a range of services that are essential to enable new developments. You can view the metrics <b><Link to="/metrics-report">here</Link></b> and also the latest published results, by clicking the Dashboard link above.</p>

                <p>Water companies have been publishing this data to provide transparency about their performance and to help improve standards of delivery to developers, self-lay providers (SLPs) and new appointees (NAVs). These standards also form part of the government initiative to publish levels of service for all utility providers.</p>

                <h4>Codes for Adoption</h4>
                <p>In response to Ofwat’s Code for Adoption Agreements, the metrics relating to water and sewerage adoption have recently been revised for companies in England. Reporting against new sewerage metrics commenced from October 2020 and against water adoption in January 2021. Welsh companies will continue to report against the original metrics.</p>

                <h4>NAV metrics</h4>
                <p>Following a recent initiative involving both water companies and NAVs, new metrics have also been formulated relating to services by all water companies to NAV customers. Companies have been reporting against these since 1 April 2020 and these are available as part of our levels of service reporting.</p>

                <p>For any assistance or further information, please contact Water UK at <a href={`mailto:${DEVELOPER_CONTACT_DETAILS.email}`}>{DEVELOPER_CONTACT_DETAILS.email}</a></p>
            </div>
        )
    };

    return <Notice/>
}

export default Homepage;