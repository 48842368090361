import React, { useState } from 'react';
import { Button, Popup, Form, Modal, Grid, Message, Input, TextArea, Dropdown, Checkbox } from 'semantic-ui-react';
import { DateInput } from 'semantic-ui-calendar-react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { customTableEditHandleOnChange, checkForEditFieldErrors, updateSearchEditData } from '../../utils/tableUtils';
import { FIELD_HEADERS } from './MetricDefinitionsTableSchema';
import { logout } from '../../auth/authRedirect';
import '../CustomTable/EditModal/EditModal.scss';

const MetricDefinitionsTableEditModal = (props) => {
    const { tableRows, setTableRows, index, apiUrl, openEditModal, setOpenEditModal, fieldsEdit, setMessage, searchState, setSearchState } = props;
    const [editableRow, setEditableRow] = useState(tableRows);
    const [open, setOpen] = useState(props.open);
    const [error, setError] = useState({ visible: false, list: [] });
    const [submittingChanges, setSubmittingChanges] = useState(false);
    const jwtIdToken = useSelector(state => state.authReducer.authResponse.jwtIdToken);
    const exp = useSelector(state => state.authReducer.authResponse.idTokenClaims.exp);

    const openModal = () => setOpen(true);
    const closeModal = () => { setOpen(false); setError({ visible: false, list: [] }); }
    const closeModalCancel = () => {
        setError({ visible: false, list: [] });
        if (openEditModal.visible) {
            const tr = [...tableRows];
            const previousTableRows = tr.splice(0, tr.length - 1);
            setTableRows(previousTableRows);
            setOpenEditModal({ visible: false, index: null });
        } else {
            setEditableRow(tableRows);
            setOpen(false);
        }
    };

    const handleOnChange = (e, data, cellName, rIndex, fieldType) => {
        setEditableRow(customTableEditHandleOnChange(e, data, editableRow, cellName, rIndex, fieldType));
    };

    const checkForChanges = (tableRows, editableRow) => JSON.stringify(tableRows) === JSON.stringify(editableRow) ? true : false;

    const handleSubmit = async() => {
        setSubmittingChanges(true);

        const fieldErrors = checkForEditFieldErrors(editableRow, index, fieldsEdit, FIELD_HEADERS);

        if (fieldErrors.length > 0) {
            setError({visible: true, list: fieldErrors});
            setSubmittingChanges(false);
            return;
        } else {
            setError({visible: false, list: []});
        }

        try {
            await axios.put(apiUrl, { ...editableRow[index] }, 
                { headers: { Authorization: `Bearer ${jwtIdToken}`, "Ocp-Apim-Subscription-Key": process.env.REACT_APP_OCP_SUBSCRIPTION_KEY }})
            setMessage({ visible: true, positive: true, negative: false, header: "Changes saved", text: "Your changes have been successfully updated." });
            setTableRows(editableRow);
            if (searchState !== undefined && searchState !== "") {
                setSearchState(updateSearchEditData(searchState, editableRow[index]));
            }
            closeModal();
        } catch (error) {
            if (new Date().getTime() >= exp * 1000) {
                logout();
            } else {
                console.error(error);
                setMessage({ visible: true, positive: false, negative: true, header: `Unable to save changes.`, text: error.response.data.ErrorMessage });
                closeModalCancel();
            }
        } finally {
            setSubmittingChanges(false);
        }
    }

    const modalRow = () => {
        return  (
                <React.Fragment>
                    <Grid>

                        {/* Code */}
                        <Grid.Column width={6} className="label-column">
                            <label className="edit-modal-label">Code:</label>
                        </Grid.Column>
                        <Grid.Column className="input-field" width={10}>
                            <Form.Input>
                                <Input onChange={(e, data) => handleOnChange(e, data, "Code", props.index, "input")} type="text" defaultValue={editableRow[index]["Code"]} required></Input>
                            </Form.Input>
                        </Grid.Column>

                        {/* Title */}
                        <Grid.Column width={6} className="label-column">
                            <label className="edit-modal-label">Title:</label>
                        </Grid.Column>
                        <Grid.Column className="text-area-field" width={10}>
                            <Form.Input>
                                <TextArea rows={5} onChange={(e, data) => handleOnChange(e, data, "Title", props.index, "text-area")} 
                                defaultValue={editableRow[index]["Title"].replace(/\\r\\n/g, '\n')}/>
                            </Form.Input>
                        </Grid.Column>

                        {/* Definition */}
                        <Grid.Column width={6} className="label-column">
                            <label className="edit-modal-label">Definition:</label>
                        </Grid.Column>
                        <Grid.Column className="text-area-field" width={10}>
                            <Form.Input>
                                <TextArea rows={5} onChange={(e, data) => handleOnChange(e, data, "Definition", props.index, "text-area")} 
                                defaultValue={editableRow[index]["Definition"].replace(/\\r\\n/g, '\n')}/>
                            </Form.Input>
                        </Grid.Column>

                        {/* Valid From */}
                        <Grid.Column width={6} className="label-column">
                            <label className="edit-modal-label">Valid From:</label>
                        </Grid.Column>
                        <Grid.Column className="date-input-field" width={10}>
                            <Form.Input>
                                <DateInput onChange={(e, data) => handleOnChange(e, data, "ValidFrom", props.index, "date-input")} closable={true} closeOnMouseLeave={false}
                                    placeholder="DD/MM/YYYY" popupPosition="bottom center" animation='none' dateFormat="DD/MM/YYYY"
                                    maxLength={10} value={editableRow[index]["ValidFrom"]} required/>
                            </Form.Input>
                        </Grid.Column>

                        {/* Valid To */}
                        <Grid.Column width={6} className="label-column">
                            <label className="edit-modal-label">Valid To:</label>
                        </Grid.Column>
                        <Grid.Column className="date-input-field" width={10}>
                            <Form.Input>
                                <DateInput onChange={(e, data) => handleOnChange(e, data, "ValidTo", props.index, "date-input")} closable={true} closeOnMouseLeave={false}
                                    placeholder="DD/MM/YYYY" popupPosition="bottom center" animation='none' dateFormat="DD/MM/YYYY"
                                    maxLength={10} value={editableRow[index]["ValidTo"]} required/>
                            </Form.Input>
                        </Grid.Column>

                        {/* Type */}
                        <Grid.Column width={6} className="label-column">
                            <label className="edit-modal-label">Type:</label>
                        </Grid.Column>
                        <Grid.Column className="dropdown-field" width={10}>
                            <Form.Input>
                            <Dropdown onChange={(e, data) =>  handleOnChange(e, data, "Type", props.index, "dropdown")}
                                placeholder={editableRow[index]["Type"]} selection fluid scrolling={true} selectOnBlur={false} defaultValue={editableRow[index]["Type"]}
                                options={props.dropdowns["Type"].map((dropdown, index) => { return { key: index, value: dropdown, text: dropdown }})} required/>
                            </Form.Input>
                        </Grid.Column>

                        {/* Report Type */}
                        <Grid.Column width={6} className="label-column">
                            <label className="edit-modal-label">Report Type:</label>
                        </Grid.Column>
                        <Grid.Column className="dropdown-field" width={10}>
                            <Form.Input>
                            <Dropdown onChange={(e, data) =>  handleOnChange(e, data, "ReportType", props.index, "dropdown")}
                                placeholder={editableRow[index]["ReportType"]} selection fluid scrolling={true} selectOnBlur={false} defaultValue={editableRow[index]["ReportType"]}
                                options={props.dropdowns["ReportType"].map((dropdown, index) => { return { key: index, value: dropdown, text: dropdown }})} required/>
                            </Form.Input>
                        </Grid.Column>

                        {/* Target */}
                        <Grid.Column width={6} className="label-column">
                            <label className="edit-modal-label">Target:</label>
                        </Grid.Column>
                        <Grid.Column className="dropdown-field" width={10}>
                            <Form.Input>
                            <Dropdown onChange={(e, data) =>  handleOnChange(e, data, "Target", props.index, "dropdown")}
                                placeholder={editableRow[index]["Target"]} selection fluid scrolling={true} selectOnBlur={false} defaultValue={editableRow[index]["Target"]}
                                options={props.dropdowns["Target"].map((dropdown, index) => { return { key: index, value: dropdown, text: dropdown }})}/>
                            </Form.Input>
                        </Grid.Column>

                        {/* DMeX Qualitative */}
                        <Grid.Column width={6} className="label-column">
                            <label className="edit-modal-label">D-MeX Qualitative:</label>
                        </Grid.Column>
                        <Grid.Column className="checkbox-field" width={10}>
                            <Form.Input>
                            <Dropdown onChange={(e, data) => handleOnChange(e, data, "DMeXQualitative", props.index, "dropdown")}
                                placeholder={editableRow[index]["DMeXQualitative"]} selection fluid scrolling={true} selectOnBlur={false} 
                                defaultValue={editableRow[index]["DMeXQualitative"]}
                                options={props.dropdowns["DMeXQualitative"].map((dropdown, index) => { return { key: index, value: dropdown, text: dropdown }})} required/>
                            </Form.Input>
                        </Grid.Column>

                        {/* DMeX Quantitative */}
                        <Grid.Column width={6} className="label-column">
                            <label className="edit-modal-label">D-MeX Quantitative:</label>
                        </Grid.Column>
                        <Grid.Column className="checkbox-field" width={10}>
                            <Form.Input>
                            <Dropdown onChange={(e, data) => handleOnChange(e, data, "DMeXQuantitative", props.index, "dropdown")}
                                placeholder={editableRow[index]["DMeXQuantitative"]} selection fluid scrolling={true} selectOnBlur={false} 
                                defaultValue={editableRow[index]["DMeXQuantitative"]}
                                options={props.dropdowns["DMeXQuantitative"].map((dropdown, index) => { return { key: index, value: dropdown, text: dropdown }})} required/>
                            </Form.Input>
                        </Grid.Column>

                        {/* DMeX Reportable Qualitative */}
                        <Grid.Column width={6} className="label-column">
                            <label className="edit-modal-label">D-MeX Reportable Qualitative:</label>
                        </Grid.Column>
                        <Grid.Column className="dropdown-field" width={10}>
                            <Form.Input>
                            <Checkbox onChange={(e, data) => handleOnChange(e, data, "DMexReportableQualitative", props.index, "checkbox")} 
                                defaultChecked={editableRow[index]["DMexReportableQualitative"] === "Yes" ? true : false} toggle/>
                            </Form.Input>
                        </Grid.Column>
                        
                        {/* DMeX Reportable Qualitative */}
                        <Grid.Column width={6} className="label-column">
                            <label className="edit-modal-label">D-MeX Reportable Quantitative:</label>
                        </Grid.Column>
                        <Grid.Column className="dropdown-field" width={10}>
                            <Form.Input>
                            <Checkbox onChange={(e, data) => handleOnChange(e, data, "DMexReportableQuantitative", props.index, "checkbox")} 
                                defaultChecked={editableRow[index]["DMexReportableQuantitative"] === "Yes" ? true : false} toggle/>
                            </Form.Input>
                        </Grid.Column>
                        
                        {/* Category */}
                        <Grid.Column width={6} className="label-column">
                            <label className="edit-modal-label">Category:</label>
                        </Grid.Column>
                        <Grid.Column className="dropdown-field" width={10}>
                            <Form.Input>
                            <Dropdown onChange={(e, data) =>  handleOnChange(e, data, "Category", props.index, "dropdown")}
                                placeholder={editableRow[index]["Category"]} selection fluid scrolling={true} selectOnBlur={false} defaultValue={editableRow[index]["Category"]}
                                options={props.dropdowns["Category"].map((dropdown, index) => { return { key: index, value: dropdown, text: dropdown }})} required/>
                            </Form.Input>
                        </Grid.Column>

                        {/* Customer Type */}
                        <Grid.Column width={6} className="label-column">
                            <label className="edit-modal-label">Customer Type:</label>
                        </Grid.Column>
                        <Grid.Column className="dropdown-field" width={10}>
                            <Form.Input>
                            <Dropdown onChange={(e, data) =>  handleOnChange(e, data, "CustomerType", props.index, "dropdown")}
                                placeholder={editableRow[index]["CustomerType"]} selection fluid scrolling={true} selectOnBlur={false} defaultValue={editableRow[index]["CustomerType"]}
                                options={props.dropdowns["CustomerType"].map((dropdown, index) => { return { key: index, value: dropdown, text: dropdown }})} required/>
                            </Form.Input>
                        </Grid.Column>

                        {/* Codes Viewable */}
                        <Grid.Column width={6} className="label-column">
                            <label className="edit-modal-label">Codes Viewable:</label>
                        </Grid.Column>
                        <Grid.Column className="dropdown-field" width={10}>
                            <Form.Input>
                            <Dropdown onChange={(e, data) =>  handleOnChange(e, data, "CodesViewable", props.index, "dropdown")}
                                placeholder={editableRow[index]["CodesViewable"]} selection fluid scrolling={true} selectOnBlur={false} defaultValue={editableRow[index]["Category"]}
                                options={props.dropdowns["CodesViewable"].map((dropdown, index) => { return { key: index, value: dropdown, text: dropdown }})} required/>
                            </Form.Input>
                        </Grid.Column>

                    </Grid>
                </React.Fragment>
        );
    }

    return (
    <>
        <Popup content="Edit row" trigger={<Button onClick={openModal} icon="edit"></Button>}/>
        <Modal
            className="edit-modal"
            as={Form}
            onClose={void(0)}
            onOpen={openModal}
            open={open}
            onSubmit={handleSubmit}
            >
            <Modal.Header>Edit row</Modal.Header>
            <Modal.Content className="edit-modal-content" scrolling>
               {modalRow()}
            </Modal.Content>
            <Modal.Actions>
                {error.visible ? 
                    <Message
                        className="edit-modal-error-message"
                        negative
                        header="Error"
                        list={error.list}
                    ></Message>
                    :
                    <></>
                }
                <Button type="button" onClick={closeModalCancel} content="Cancel"/>
                <Button disabled={checkForChanges(tableRows[index], editableRow[index]) || submittingChanges === true}
                    loading={submittingChanges} type="submit" icon="save" content="Save changes" color="green"/>
            </Modal.Actions>
        </Modal>
    </>
    );
}

MetricDefinitionsTableEditModal.propTypes = {
    tableName: PropTypes.string,
    tableRows: PropTypes.array,
    setTableRows: PropTypes.func,
    editableRow: PropTypes.array,
    index: PropTypes.number,
    apiUrl: PropTypes.string
}

MetricDefinitionsTableEditModal.defaultProps = {
    apiUrl: null
};

export default MetricDefinitionsTableEditModal;