import React, { useState } from 'react'
import { Button, Popup, Form, Modal, Grid, Message, TextArea } from 'semantic-ui-react';
import { DateInput } from 'semantic-ui-calendar-react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { customTableEditHandleOnChange, checkForEditFieldErrors, updateSearchEditData } from '../../utils/tableUtils';
import { getFileName } from '../../utils/dataUploadUtils';
import { FIELD_HEADERS } from './TemplatesTableSchema';
import '../CustomTable/EditModal/EditModal.scss';
import { logout } from '../../auth/authRedirect';

const TemplatesTableEditModal = (props) => {
    const { tableRows, setTableRows, index, apiUrl, openEditModal, setOpenEditModal, fieldsEdit, searchState, setSearchState, setGlobalMessage } = props;
    const [editableRow, setEditableRow] = useState(tableRows);
    const [open, setOpen] = useState(props.open);
    const [error, setError] = useState({ visible: false, list: [] });
    const [submittingChanges, setSubmittingChanges] = useState(false);
    const jwtIdToken = useSelector(state => state.authReducer.authResponse.jwtIdToken);
    const exp = useSelector(state => state.authReducer.authResponse.idTokenClaims.exp);

    const openModal = () => setOpen(true);
    const closeModal = () => { setOpen(false); setError({ visible: false, list: [] }); }
    const closeModalCancel = () => {
        setError({ visible: false, list: [] });
        if (openEditModal.visible) {
            const tr = [...tableRows];
            const previousTableRows = tr.splice(0, tr.length - 1);
            setTableRows(previousTableRows);
            setOpenEditModal({ visible: false, index: null });
        } else {
            setEditableRow(tableRows);
            setOpen(false);
        }
    };

    const handleOnChange = (e, data, cellName, rIndex, fieldType) => {
        setEditableRow(customTableEditHandleOnChange(e, data, editableRow, cellName, rIndex, fieldType));
    };

    const checkForChanges = (tableRows, editableRow) => JSON.stringify(tableRows) === JSON.stringify(editableRow) ? true : false;

    const handleSubmit = async() => {
        setSubmittingChanges(true);
        const fieldErrors = checkForEditFieldErrors(editableRow, index, fieldsEdit, FIELD_HEADERS);

        if (fieldErrors.length > 0) {
            setError({visible: true, list: fieldErrors});
            setSubmittingChanges(false);
            return;
        } else {
            setError({visible: false, list: []});
        }

        if (apiUrl === null) {
            setTableRows(editableRow);
            if (searchState !== undefined && searchState !== "") {
                setSearchState(updateSearchEditData(searchState, editableRow[index]));
            }
            closeModal();
            setSubmittingChanges(false);
            return;
        }

        try {
            await axios.patch(apiUrl, { ...editableRow[index] }, 
                { headers: { Authorization: `Bearer ${jwtIdToken}`, "Ocp-Apim-Subscription-Key": process.env.REACT_APP_OCP_SUBSCRIPTION_KEY }});
            setGlobalMessage({ visible: true, positive: true, negative: false, header: "Changes saved", text: "Your changes have been successfully updated." });
            setTableRows(editableRow);
            if (searchState !== undefined && searchState !== "") {
                setSearchState(updateSearchEditData(searchState, editableRow[index]));
            }
            closeModal();
        } catch (error) {
            if (new Date().getTime() >= exp * 1000) {
                logout();
            } else {
                console.error(error);
                setGlobalMessage({ visible: true, positive: false, negative: true, header: `Unable to save changes.`, text: error.response.data.ErrorMessage });
                closeModalCancel();
            }
        } finally {
            setSubmittingChanges(false);
        }
    }

    const modalRow = () => {
        return  (
                <React.Fragment>
                    <Grid>

                        {/* File Name */}
                        <Grid.Column width={6} className="label-column">
                            <label className="edit-modal-label">File Name:</label>
                        </Grid.Column>
                        <Grid.Column className="input-field" width={10}>
                            {getFileName(editableRow[index]["FileName"])}
                        </Grid.Column>

                        {/* Template Version */}
                        <Grid.Column width={6} className="label-column">
                            <label className="edit-modal-label">Template Version:</label>
                        </Grid.Column>
                        <Grid.Column className="input-field" width={10}>
                            {editableRow[index]["TemplateVersion"]}
                        </Grid.Column>

                        {/* Current */}
                        <Grid.Column width={6} className="label-column">
                            <label className="edit-modal-label">Current:</label>
                        </Grid.Column>
                        <Grid.Column className="checkbox-field" width={10}>
                            {editableRow[index]["Current"]}
                        </Grid.Column>

                        {/* Valid From */}
                        <Grid.Column width={6} className="label-column">
                            <label className="edit-modal-label">Valid From:</label>
                        </Grid.Column>
                        <Grid.Column className="date-input-field" width={10}>
                            <Form.Input>
                                <DateInput onChange={(e, data) => handleOnChange(e, data, "ValidFrom", props.index, "date-input")} closable={true} closeOnMouseLeave={false}
                                    placeholder="DD/MM/YYYY" popupPosition="bottom center" animation='none' dateFormat="DD/MM/YYYY" maxLength={10}
                                    value={editableRow[index] !== undefined ? editableRow[index]["ValidFrom"] : ""} required/>
                            </Form.Input>
                        </Grid.Column>

                        {/* Valid To */}
                        <Grid.Column width={6} className="label-column">
                            <label className="edit-modal-label">Valid To:</label>
                        </Grid.Column>
                        <Grid.Column className="date-input-field" width={10}>
                            <Form.Input>
                                <DateInput onChange={(e, data) => handleOnChange(e, data, "ValidTo", props.index, "date-input")} closable={true} closeOnMouseLeave={false}
                                    placeholder="DD/MM/YYYY" popupPosition="bottom center" animation='none' dateFormat="DD/MM/YYYY" maxLength={10}
                                    value={editableRow[index] !== undefined ? editableRow[index]["ValidTo"] : ""} required/>
                            </Form.Input>
                        </Grid.Column>

                        {/* Uploaded By */}
                        <Grid.Column width={6} className="label-column">
                            <label className="edit-modal-label">Uploaded By:</label>
                        </Grid.Column>
                        <Grid.Column className="input-field" width={10}>
                            {editableRow[index]["UploadedBy"]}
                        </Grid.Column>

                        {/* Uploaded Date */}
                        <Grid.Column width={6} className="label-column">
                            <label className="edit-modal-label">Uploaded Date:</label>
                        </Grid.Column>
                        <Grid.Column className="input-field" width={10}>
                            {editableRow[index]["UploadedDate"]}
                        </Grid.Column>

                        {/* Version Comments */}
                        <Grid.Column width={6} className="label-column">
                            <label className="edit-modal-label">Version Comments:</label>
                        </Grid.Column>
                        <Grid.Column className="text-area-field" width={10}>
                            <Form.Input>
                                <TextArea rows={4} onChange={(e, data) => handleOnChange(e, data, "VersionComments", props.index, "text-area")} 
                                defaultValue={editableRow[index]["VersionComments"] !== null ? editableRow[index]["VersionComments"].replace(/\\r\\n/g, '\n') : ""}/>
                            </Form.Input>
                        </Grid.Column>

                    </Grid>
                </React.Fragment>
        );
    }

    return (
    <>
        <Popup content="Edit row" trigger={<Button onClick={openModal} icon="edit"></Button>}/>
        <Modal
            className="edit-modal"
            as={Form}
            onClose={void(0)}
            onOpen={openModal}
            open={open}
            onSubmit={handleSubmit}
            >
            <Modal.Header>Edit row</Modal.Header>
            <Modal.Content className="edit-modal-content" scrolling>
               {modalRow()}
            </Modal.Content>
            <Modal.Actions>
                {error.visible ? 
                    <Message
                        className="edit-modal-error-message"
                        negative
                        header="Error"
                        list={error.list}
                    ></Message>
                    :
                    <></>
                }
                <Button disabled={submittingChanges === true} type="button" onClick={closeModalCancel} content="Cancel"/>
                <Button disabled={checkForChanges(tableRows[index], editableRow[index]) || submittingChanges === true}
                    loading={submittingChanges} type="submit" icon="save" content="Save changes" color="green"/>
            </Modal.Actions>
        </Modal>
    </>
    );
}

TemplatesTableEditModal.propTypes = {
    tableName: PropTypes.string,
    tableRows: PropTypes.array,
    setTableRows: PropTypes.func,
    editableRow: PropTypes.array,
    index: PropTypes.number,
    apiUrl: PropTypes.string
}

TemplatesTableEditModal.defaultProps = {
    apiUrl: null
};

export default TemplatesTableEditModal;