export const HEADERS = [
    "Metric",
    "Historic Average In Target",
    "Historic Average Total",
    "Historic Average Percent",
    "This Month In Target",
    "This Month Total",
    "This Month Percent",
    "Compliance Varies By Over 10%",
    "Volume Varies By Over 30%",
    "In Target > Total",
];

export const FIELD_HEADERS = [
    "ReactKey",
    "Metric",
    "Historic Average In Target",
    "Historic Average Total",
    "Historic Average Percent",
    "This Month In Target",
    "This Month Total",
    "This Month Percent",
    "Compliance Varies By Over 10%",
    "Volume Vary By Over 30%",
    "In Target > Total",
];

export const FIELDS = {
    ReactKey: "hidden-field",
    Metric: "read-only",
    HistoricAvgInTarget: "read-only",
    HistoricAvgTotal: "read-only",
    HistoricAvgPercent: "read-only",
    ThisMonthInTarget: "number",
    ThisMonthTotal: "number",
    ThisMonthInPercent: "read-only",
    ComplianceVariesByOver10Percent: "read-only",
    VolumesVaryByOver30Percent: "read-only",
    InTargetIsMoreThanTotal: "read-only",
};

export const SORT_FIELDS = {
    "Metric": "Metric",
    "Historic Average In Target": "HistoricAvgInTarget",
    "Historic Average Total": "HistoricAvgTotal",
    "Historic Average Percent": "HistoricAvgPercent",
    "This Month In Target": "ThisMonthInTarget",
    "This Month Total": "ThisMonthTotal",
    "This Month Percent": "ThisMonthInPercent",
    "Compliance Varies By Over 10%": "ComplianceVariesByOver10Percent",
    "Volume Varies By Over 30%": "VolumesVaryByOver30Percent",
    "In Target > Total": "InTargetIsMoreThanTotal"
};