export const ERRORS = {
    INVALID_FILE: "Invalid file. Please select an Excel file.",
    NO_FILE_SELECTED: "Please select a file to upload.",
    GENERIC_FILE_UPLOAD_ERROR: "Error uploading file. Please try again later.",
    MIN_FILE_SIZE_ERROR: "The selected file is below the minimum file size.",
    MAX_FILE_SIZE_ERROR: "The maximum file size for uploading has been exceeded."
};

export const WARNINGS = {
    PAGE_LEAVE: "Are you sure you want to leave? The upload process will be cancelled."
};

export const SUCCESS = {
    FILE_UPLOAD: "Thank you. Your data has been successfully uploaded.",
    FILE_UPLOAD_AMENDMENT: "Thank you.\n\nYour amendment request has been successfully submitted."
};

export const MISC = {
    CLOSED: "The period for data uploads has elapsed.",
    UPLOAD_TEXT: "Click or drag a Excel file to this area to upload",
    MIN_FILE_SIZE: 20000, // 20 KB
    MAX_FILE_SIZE: 4194304  // 4 MB
};

export const STEPS = {
    STEP_ONE: {
        TITLE: "Upload",
        DESCRIPTION: "Upload your metrics file."
    },
    STEP_TWO: {
        TITLE: "Confirm Upload",
        DESCRIPTION: "Confirm the data being uploaded is correct."
    },
    STEP_THREE: {
        TITLE: "File Uploaded"
    }
};