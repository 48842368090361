
import moment from 'moment';

export const getQuarter = (quarterScore) => {
    if (quarterScore === "Q1_Score") {
      return 1;
    } else if (quarterScore === "Q2_Score") {
      return 2;
    } else if (quarterScore === "Q3_Score") {
      return 3;
    } else if (quarterScore === "Q4_Score") {
      return 4;
    } else if (quarterScore === "Q2_YTD") {
      return 200;
    } else if (quarterScore === "Q3_YTD") {
      return 300;
    } else if (quarterScore === "Q4_YTD") {
      return 400;
    }
};

export const getChangedEditValues = (tableRow, editableRow, type) => {
    let objectArray = [];
    Object.entries(tableRow).forEach(key => {
        if (key[1] !== editableRow[key[0]]) {
          let obj = { };
          obj["CompanyName"] = editableRow["CompanyName"];
          obj["DateEntered"] = moment().format("DD/MM/YYYY");
          obj["Score"] = editableRow[key[0]];
          obj["Quarter"] = getQuarter(key[0]);
          obj["Year"] = editableRow["Yr"]
          objectArray.push(obj);
        }
      })
    return objectArray;
};

export const getChangedAddValues = (object) => {
    const { CompanyName, Q1_Score, Q2_Score, Q3_Score, Q4_Score, Q2_YTD, Q3_YTD, Q4_YTD, Yr } = object;
    const currentDate = moment().format("DD/MM/YYYY");
    let objectArray = [];
  
    if (object["Q1_Score"] !== null && object["Q1_Score"]?.trim() !== "") {
      objectArray.push({ CompanyName, DateEntered: currentDate, Quarter: getQuarter("Q1_Score"), Score: Q1_Score, Year: Yr })
    }
  
    if (object["Q2_Score"] !== null && object["Q2_Score"]?.trim() !== "") {
      objectArray.push({ CompanyName, DateEntered: currentDate, Quarter: getQuarter("Q2_Score"), Score: Q2_Score, Year: Yr })
    }
  
    if (object["Q3_Score"] !== null && object["Q3_Score"]?.trim() !== "") {
      objectArray.push({ CompanyName, DateEntered: currentDate, Quarter: getQuarter("Q3_Score"), Score: Q3_Score, Year: Yr })
    }

    if (object["Q4_Score"] !== null && object["Q4_Score"]?.trim() !== "") {
        objectArray.push({ CompanyName, DateEntered: currentDate, Quarter: getQuarter("Q4_Score"), Score: Q4_Score, Year: Yr })
    }

    if (object["Q2_YTD"] !== null && object["Q2_YTD"]?.trim() !== "") {
      objectArray.push({ CompanyName, DateEntered: currentDate, Quarter: getQuarter("Q2_YTD"), Score: Q2_YTD, Year: Yr })
    }
  
    if (object["Q3_YTD"] !== null && object["Q3_YTD"]?.trim() !== "") {
      objectArray.push({ CompanyName, DateEntered: currentDate, Quarter: getQuarter("Q3_YTD"), Score: Q3_YTD, Year: Yr })
    }

    if (object["Q4_YTD"] !== null && object["Q4_YTD"]?.trim() !== "") {
        objectArray.push({ CompanyName, DateEntered: currentDate, Quarter: getQuarter("Q4_YTD"), Score: Q4_YTD, Year: Yr })
    }

    return objectArray;
};