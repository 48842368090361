import React, { useState } from 'react'
import { Button, Popup, Form, Modal, Grid, Message, Input, Dropdown, Checkbox, TextArea } from 'semantic-ui-react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { customTableAddHandleOnChange, checkForAddFieldErrors, updateSearchAddData } from '../../../utils/tableUtils';
import { logout } from '../../../auth/authRedirect';

const AddModal = props => {
	const { tableRows, setTableRows, apiUrl, fieldsAdd, headers, setMessage, searchState, setSearchState } = props;
    const [editableRow, setEditableRow] = useState(props.initialFields);
    const [open, setOpen] = useState(props.open);
    const [error, setError] = useState({ visible: false, list: [] });
    const [submittingChanges, setSubmittingChanges] = useState(false);
    const jwtIdToken = useSelector(state => state.authReducer.authResponse.jwtIdToken);
    const exp = useSelector(state => state.authReducer.authResponse.idTokenClaims.exp);

	const openModal = () => setOpen(true);
	const closeModal = () => {
        setError({ visible: false, list: [] });
		setEditableRow(props.initialFields);
		setOpen(false);
	};
	
	const handleOnChange = (e, data, editableRow, cellName, fieldType) => { 
        setEditableRow(customTableAddHandleOnChange(e, data, editableRow, cellName, fieldType)); 
    };

	const createField = (cellName, cellData, fieldType, rIndex) => {
        switch(fieldType) {
            case "read-only": return typeof cellData == "boolean" ? cellData === true ? "Yes" : "No" : cellData;
            case "text": return <Input onChange={(e, data) => handleOnChange(e, data, editableRow, cellName, fieldType)} type="text" defaultValue={cellData}></Input>
            case "text-required": return <Input onChange={(e, data) => handleOnChange(e, data, editableRow, cellName, fieldType)} type="text" defaultValue={cellData} required></Input>
            case "text-area": return <TextArea rows={5} onChange={(e, data) => handleOnChange(e, editableRow, data, cellName, fieldType)} defaultValue={cellData.replace(/\\r\\n/g, '\n')}/>
            case "email": return <Input onChange={(e, data) => handleOnChange(e, data, editableRow, cellName, fieldType)} type="email" defaultValue={cellData} required></Input>
            case "checkbox": return <Checkbox onChange={(e, data) => handleOnChange(e, data, editableRow, cellName, fieldType)} defaultChecked={cellData === "Yes" ? true : false} toggle required/>
            case "dropdown": 
            case "dropdown-required":
            return <Dropdown key={rIndex} onChange={(e, data) => handleOnChange(e, data, editableRow, cellName, fieldType)} 
                placeholder={cellData} selection fluid scrolling={true} selectOnBlur={false} options={props.dropdowns[cellName] !== undefined ?
                props.dropdowns[cellName].map((dropdown, index) => { return { key: index, value: dropdown, text: dropdown }}) : []} defaultValue={cellData}/>
            case "number": return <Input onChange={(e, data) => handleOnChange(e, data, editableRow, cellName, fieldType)} type="number" defaultValue={cellData} required/>; 
            case "number-min-max": return <Input onChange={(e, data) => handleOnChange(e, data, editableRow, cellName, fieldType)} type="number" 
                min={props.numbers[cellName].min} max={props.numbers[cellName].max} defaultValue={cellData} required/>
            default:  return <Input onChange={(e, data) =>  handleOnChange(e, data, editableRow, cellName, fieldType)} type="text" defaultValue={cellData} required></Input>;
        }
	}

	const handleSubmit = async() => {
        setSubmittingChanges(true);

        const fieldErrors = checkForAddFieldErrors(editableRow, fieldsAdd, headers);

        if (fieldErrors.length > 0) {
            setError({visible: true, list: fieldErrors});
            setSubmittingChanges(false);
            return;
        } else {
            setError({visible: false, list: []});
        }
     
        try {
            await axios.put(apiUrl, { ...editableRow }, 
                { headers: { Authorization: `Bearer ${jwtIdToken}`, "Ocp-Apim-Subscription-Key": process.env.REACT_APP_OCP_SUBSCRIPTION_KEY }});
            setMessage({ visible: true, positive: true, negative: false, header: "Changes saved", text: "Your changes have been successfully updated." });
            const tr = [...tableRows];
            tr.push({ReactKey: props.index - 1, ...editableRow});
            setTableRows(tr);
            if (searchState !== undefined && searchState !== "") {
                setSearchState(updateSearchAddData(searchState, editableRow, tableRows.length));
            }
            closeModal();
        } catch (error) {
            if (new Date().getTime() >= exp * 1000) {
                logout();
            } else {
                console.error(error);
                setMessage({ visible: true, positive: false, negative: true, header: `Unable to save changes.`, text: error.response.data.ErrorMessage });
                closeModal();
            }
        } finally {
            setSubmittingChanges(false);
        }
     }

	const modalRow = Object.entries(editableRow).map((cell, cIndex) => {
        return open === true && cell[0] !== "ReactKey"?
            <React.Fragment key={cIndex}>
                <Grid>
                    <Grid.Column width={6} className="label-column">
                        <label className="edit-modal-label">{`${props.headers[cIndex]}:`}</label>
                    </Grid.Column>
                    <Grid.Column className={`${props.fieldsAdd[cell[0]]}-field`} width={10}>
                        <Form.Input>
                            <>{createField(cell[0], cell[1], props.fieldsAdd[cell[0]], props.index)}</>
                        </Form.Input>
                    </Grid.Column>
                </Grid>
            </React.Fragment>
            :
            <React.Fragment key={cIndex}></React.Fragment>
    });

	return (
		<>
			<Popup content="Add row" trigger={<Button className="table-add-btn" onClick={openModal}>Add</Button>}/>
			<Modal
				className="edit-modal"
				as={Form}
				onClose={void(0)}
				onOpen={openModal}
				open={open}
				onSubmit={handleSubmit}
				>
				<Modal.Header>Add row</Modal.Header>
				<Modal.Content className="edit-modal-content" scrolling>
					{modalRow}
				</Modal.Content>
				<Modal.Actions>
					{error.visible ? 
						<Message
							className="edit-modal-error-message"
							negative
							header="Error"
							list={error.list}
						></Message>
						:
						<></>
					}
					<Button type="button" onClick={closeModal} content="Cancel"/>
					<Button disabled={submittingChanges === true} 
						loading={submittingChanges === true} type="submit" icon="save" content="Save changes" color="green"/>
				</Modal.Actions>
			</Modal>
		</>
		);
}

export default AddModal;