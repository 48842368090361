import React, { useState } from 'react'
import PropTypes from 'prop-types';
import { Table, Pagination, Search, Icon } from 'semantic-ui-react';
import EditModal from './EditModal/EditModal';
import ResponseMessage from './ResponseMessage/ResponseMessage';
import AddModal from './AddModal/AddModal';
import { getDefaultSortDirection, handleTableSort, getSortIconDirection, searchFilter } from '../../utils/tableUtils';
import './CustomTable.scss';

const CustomTable = props => {
    const { headers, sortFields, fieldHeaders, rows } = props;
    const [tableRows, setTableRows] = useState(rows);
    const [openEditModal, setOpenEditModal] = useState({ visible: false, index: null});
    const [openAddModal, setOpenAddModal] = useState({ visible: false, index: null });
    const [message, setMessage] = useState({ visible: false, positive: false, negative: false, header: "", text: "" });
    const [searchState, setSearchState] = useState(tableRows);
    const paginationInitialState = { activePage: 1, rowStart: 0, rowEnd: 10 }
    const [pagination, setPagination] = useState(paginationInitialState);
    const [sortDirection, setSortDirection] = useState(getDefaultSortDirection(fieldHeaders, props.headers[0]));

    const tableHeaders = headers.map((header, index) => {
        return (
        <Table.HeaderCell 
            key={index}
            className="table-header"
            textAlign="center"
            onClick={() => handleTableSort(sortFields[header], header, sortDirection[sortFields[header]], tableRows, searchState, getDefaultSortDirection(fieldHeaders), setSortDirection, setSearchState, setTableRows)}
            content={
            <>
                {header} 
                {header === sortDirection.lastClicked ? <Icon className="table-sort-icon" size="small" name={getSortIconDirection(sortDirection[sortFields[header]])}></Icon> : <></>}
            </>
            }>
        </Table.HeaderCell>
        );
    });

    const handleSearchChange = (e, { value }) => {
        if (pagination !== paginationInitialState) {
            setPagination(paginationInitialState);
        }
        const filteredData = searchFilter(tableRows, value);
        setSearchState(filteredData);
    }

    const handlePaginationChange = (e, { activePage }) => {
        const rowStart = (activePage - 1) * paginationInitialState.rowEnd;
        const rowEnd = activePage * paginationInitialState.rowEnd;
        setPagination({ activePage, rowStart, rowEnd });
    }

    const getRowActions = (reactKey) => {
        return (
            <Table.Cell textAlign="center" className="table-action-buttons" collapsing>
            <>
                <EditModal
                    tableName={props.tableName}
                    open={openEditModal.visible}
                    tableRows={tableRows}
                    setTableRows={setTableRows}
                    apiUrl={props.apiUrl}
                    headers={props.headers}
                    fieldsEdit={props.fieldsEdit}
                    fieldsAdd={props.fieldsAdd}
                    fieldHeaders={props.fieldHeaders}
                    fieldAlignment={props.fieldAlignment}
                    dropdowns={props.dropdowns}
                    numbers={props.numbers}
                    index={tableRows.findIndex(x => x.ReactKey === reactKey)}
                    openEditModal={openEditModal}
                    setOpenEditModal={setOpenEditModal}
                    searchState={searchState}
                    setSearchState={setSearchState}
                    setMessage={setMessage}
                    />
            </>
            </Table.Cell>
        )
    }

    const createFieldReadOnlyField = (cellData, fieldType) => {
        switch(fieldType) {
            case "read-only": return typeof cellData == "boolean" ? cellData === true ? "Yes" : "No" : cellData;
            case "text-area": return <div className="display-linebreak"> {cellData.replace(/\\r\\n/g, '\n')}</div>
            case "checkbox": return cellData === "Yes" ? "Yes" : "No";
            default: return cellData;
        }
    }

    const TableRow = ({ rIndex, row }) => {
        const tRows = Object.entries(row).map((cell, cIndex) => {
            return props.fieldsEdit[cell[0]] !== "hidden-field" ?
                <Table.Cell
                    key={cIndex}
                    textAlign={props.fieldAlignment !== undefined ? props.fieldAlignment[cell[0]] : "center" }>
                    <>{createFieldReadOnlyField(cell[1], props.fieldsEdit[cell[0]])}</>
                </Table.Cell>
                :
                <React.Fragment key={cIndex}></React.Fragment>
        });
        return (
            <Table.Row key={rIndex}>
                {props.editable ? getRowActions(row.ReactKey) : <></> }
                {tRows}
            </Table.Row>
        )
    }

    const Body = () => {
        if (searchState.length === 0) {
            return <><Table.Row><Table.Cell colSpan='16'><h1 className="table-no-results">No results found.</h1></Table.Cell></Table.Row></>
        } else if (searchState.length > 0) {
            return searchState.slice(pagination.rowStart, pagination.rowEnd).map((row, rIndex) => {
                return <TableRow key={rIndex} rIndex={rIndex} row={row}/>
            });
        } else {
            return tableRows.slice(pagination.rowStart, pagination.rowEnd).map((row, rIndex) => {
                return <TableRow key={rIndex} rIndex={rIndex} row={row}/>
            });
        }
    }

    const Footer = () => {
        return (
            <Table.Row>
                <Table.HeaderCell colSpan='16'>
                    <Pagination
                        defaultActivePage={pagination.activePage}
                        onPageChange={handlePaginationChange}
                        totalPages={searchState.length === 0 ? 0 : searchState.length > 0 ? Math.ceil(searchState.length / paginationInitialState.rowEnd) : Math.ceil(tableRows.length / paginationInitialState.rowEnd)}
                        ellipsisItem={null}
                        disabled={searchState.length === 0 ? true : searchState.length > 0 ? searchState.length < paginationInitialState.rowEnd : tableRows.length < paginationInitialState.rowEnd}
                    />
                </Table.HeaderCell>
            </Table.Row>
        )
    }

    const renderTable = () => {
        return tableRows.length > 0
        ?
            <div className="custom-table-container">
                {message.visible ? <ResponseMessage {...message} setMessage={setMessage}/> : <></>}
                <Search className="table-search" placeholder="Search" onSearchChange={handleSearchChange} open={false}></Search>
                <Table striped celled compact>
                    <Table.Header>
                        <Table.Row>
                            {props.editable ? <Table.HeaderCell singleLine textAlign="center">Action</Table.HeaderCell> : <></>}
                            {tableHeaders}
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        <Body/>
                    </Table.Body>
                    <Table.Footer>
                        <Footer/>
                    </Table.Footer>
                </Table>
                {props.addable ?
                <AddModal
                    tableName={props.tableName}
                    initialFields={props.initialFields}
                    tableRows={tableRows}
                    setTableRows={setTableRows}
                    apiUrl={props.apiUrl}
                    headers={props.headers}
                    fieldsEdit={props.fieldsEdit}
                    fieldsAdd={props.fieldsAdd}
                    fieldHeaders={props.fieldHeaders}
                    fieldAlignment={props.fieldAlignment}
                    dropdowns={props.dropdowns}
                    numbers={props.numbers}
                    index={tableRows.length + 1}
                    openAddModal={openAddModal}
                    setOpenAddModal={setOpenAddModal}
                    searchState={searchState}
                    setSearchState={setSearchState}
                    setMessage={setMessage}
                    />
                : <></>}
            </div>
        :
        <h2 className="no-table-data">No table data.</h2>
    };

    return renderTable();
}

CustomTable.propTypes = {
    tableName: PropTypes.string,
    headers: PropTypes.array,
    body: PropTypes.array,
    fieldsEdit: PropTypes.object,
    fieldsAdd: PropTypes.object,
    dropdowns: PropTypes.object,
    numbers: PropTypes.object,
    apiUrl: PropTypes.string,
    editable: PropTypes.bool,
    addable: PropTypes.bool,
    rowDeletion: PropTypes.bool,
    initialFields: PropTypes.object
};

CustomTable.defaultProps = {
    tableName: "table-" + new Date().getTime(),
    headers: [],
    body: [],
    fieldsEdit: {},
    fieldsAdd: {},
    dropdowns: {},
    numbers: {},
    apiUrl: null,
    editable: false,
    addable: false,
    rowDeletion: false,
    initialFields: {}
};

export default CustomTable;