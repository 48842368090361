import React, { useState, useCallback } from 'react';
import { Button, Form, Modal, Grid, Message, Popup, TextArea, Icon } from 'semantic-ui-react';
import { DateInput } from 'semantic-ui-calendar-react';
import { useDropzone } from 'react-dropzone';
import { useSelector } from 'react-redux';
import { customTableAddHandleOnChange } from '../../utils/tableUtils';
import moment from 'moment';
import axios from 'axios';
import readXlsxFile from 'read-excel-file';
import { ERRORS, MISC } from '../../constants/dataUploadConsts';
import { logout } from '../../auth/authRedirect';

const TemplatesTableAddModal = props => {
    const jwtIdToken = useSelector(state => state.authReducer.authResponse.jwtIdToken);
    const email = useSelector(state => state.authReducer.authResponse.idTokenClaims.emails[0]);
    const exp = useSelector(state => state.authReducer.authResponse.idTokenClaims.exp);
    const [file, setFile] = useState(null);
	const { tableRows, setGlobalMessage, fetchData, initialFields, } = props;
    const [editableRow, setEditableRow] = useState(initialFields);
    const [uploadStatus] = useState({ uploadIsComplete: false, uploadInProgress: false, percentCompleted: 0});
    const [open, setOpen] = useState(props.open);
    const [error, setError] = useState({ visible: false, list: [] });
    const [submittingChanges, setSubmittingChanges] = useState(false);
    const [templateVersion, setTemplateVersion] = useState("");
    const versions = tableRows.map(r => r.TemplateVersion);

	const openModal = () => setOpen(true);
    const closeModal = () => {
        setFile(null);
        setTemplateVersion("");
        setError({ visible: false, list: [] });
		setEditableRow(props.initialFields);
        setOpen(false);
    }

    const onDrop = useCallback(async(selectedFile) => {
        if (selectedFile.length === 0) {
            setError({ visible: true, list: [ERRORS.INVALID_FILE]});
            setFile(null);
            return;
        } else if (selectedFile.length === 1) {
            if (selectedFile[0].size < MISC.MIN_FILE_SIZE) {
                setError({ visible: true, list: [ERRORS.MIN_FILE_SIZE_ERROR]});
                return;
            } else if (selectedFile[0].size > MISC.MAX_FILE_SIZE) {
                setError({ visible: true, list: [ERRORS.MAX_FILE_SIZE_ERROR]});
                return;
            }

            try {
                let templateVersionTemp = "";
                let title = "";
                await readXlsxFile(selectedFile[0]).then((rows) => {
                    title = rows[1][0];
                    templateVersionTemp = rows[1][2];
                });

                if ((!versions.includes(templateVersionTemp)) && (title === "Water UK Upload Template")) {
                    setFile(selectedFile[0]); 
                    setTemplateVersion(templateVersionTemp);
                    setError({ visible: false, list: []});
                } else {
                    if (title !== "Water UK Upload Template") {
                        setError({ visible: true, list: [`Invalid file.`] })
                    } else {
                        setError({ visible: true, list: [`${templateVersionTemp} has already been uploaded.`] })
                    }
                    setTemplateVersion("");
                    setFile(null);
                }
            } catch (err) {
                setError({ visible: true, list: [...err] });
                setTemplateVersion("");
                setFile(null);
            }
        }
      }, [versions]);

    const {getRootProps, getInputProps} = useDropzone({
        accept: '.xls, .xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        multiple: false,
        disabled: uploadStatus.uploadInProgress,
        onDrop
    });

	const handleOnChange = (e, data, cellName, fieldType) => {
        setEditableRow(customTableAddHandleOnChange(e, data, editableRow, cellName, fieldType));
    };

	const handleFileUpload = async() => {
        setError({visible: false, list: []});
        setGlobalMessage({ visible: false, positive: false, negative: false, header: "", text: "" });
        setSubmittingChanges(true);
        const { ValidFrom, ValidTo } = editableRow;

        if (file === null) {
            setError({ visible: true, list: ["Please select a template to upload."] });
            setSubmittingChanges(false);
            return;
        }

        if (file === undefined) {
            setError({ visible: true, list: ["There appears to be an issue with the file. Please ensure the excel document is closed before uploading."] });
            setSubmittingChanges(false);
            return;
        }

        if (!moment(ValidFrom, 'DD/MM/YYYY', true).isValid()) {
            setError({ visible: true, list: ["Valid From has an invalid date."] });
            setSubmittingChanges(false);
            return;
        }
        
        if (!moment(ValidTo, 'DD/MM/YYYY', true).isValid()) {
            setError({ visible: true, list: ["Valid To has an invalid date."] });
            setSubmittingChanges(false);
            return;
        }

        try {
            const url = `${process.env.REACT_APP_TEMPLATE_URL}?TemplateVersion=${templateVersion}&ValidFrom=${ValidFrom}&ValidTo=${ValidTo}&Comments=${encodeURIComponent(editableRow["VersionComments"])}`;
            const config = { headers: { Authorization: `Bearer ${jwtIdToken}`, "Ocp-Apim-Subscription-Key": process.env.REACT_APP_OCP_SUBSCRIPTION_KEY }};
            await axios.put(url, file, config);
            await fetchData();
            setGlobalMessage({ visible: true, positive: true, negative: false, header: "Template Uploaded", text: "Your template has been successfully uploaded." });
        } catch (error) {
            if (new Date().getTime() >= exp * 1000) {
                logout();
            } else {
                console.error(error);
                setGlobalMessage({ visible: true, positive: false, negative: true, header: `Unable to save changes.`, text: error.response.data.ErrorMessage });
                setError({ visible: false, list: [] });
            }
        } finally {
			closeModal();
            setSubmittingChanges(false);
        }
    }

    const modalRow = () => {
        return  (
                <React.Fragment>
                    <Grid>

                        {/* File */}
                        <Grid.Column width={6} className="label-column">
                            <label className="edit-modal-label">File:</label>
                        </Grid.Column>
                        <Grid.Column className="input-field" width={10}>
                        <div {...getRootProps()} className={`dropzone-input ${uploadStatus.uploadInProgress}`}>
                            <input {...getInputProps()} />
                            <Icon name="upload"></Icon>
                            {file === null ? <p>{MISC.UPLOAD_TEXT}</p> : <p>{file.name}</p>} 
                        </div>
                        </Grid.Column>
                        
                        {/* Template Version */}
                        <Grid.Column width={6} className="label-column">
                            <label className="edit-modal-label">Template Version:</label>
                        </Grid.Column>
                        <Grid.Column className="input-field" width={10}>
                            {templateVersion}
                        </Grid.Column>

                        {/* Valid From */}
                        <Grid.Column width={6} className="label-column">
                            <label className="edit-modal-label">Valid From:</label>
                        </Grid.Column>
                        <Grid.Column className="date-input-field" width={10}>
                            <Form.Input>
                                <DateInput onChange={(e, data) => handleOnChange(e, data, "ValidFrom", "date-input")} closable={true} closeOnMouseLeave={false}
                                    placeholder="DD/MM/YYYY" popupPosition="bottom center" animation='none' dateFormat="DD/MM/YYYY"
                                    maxLength={10} value={editableRow["ValidFrom"]} required/>
                            </Form.Input>
                        </Grid.Column>

                        {/* Valid To */}
                        <Grid.Column width={6} className="label-column">
                            <label className="edit-modal-label">Valid To:</label>
                        </Grid.Column>
                        <Grid.Column className="date-input-field" width={10}>
                            <Form.Input>
                                <DateInput onChange={(e, data) => handleOnChange(e, data, "ValidTo", "date-input")} closable={true} closeOnMouseLeave={false}
                                    placeholder="DD/MM/YYYY" popupPosition="bottom center" animation='none' dateFormat="DD/MM/YYYY"
                                    maxLength={10} value={editableRow["ValidTo"]}/>
                            </Form.Input>
                        </Grid.Column>

                        {/* Uploaded By */}
                        <Grid.Column width={6} className="label-column">
                            <label className="edit-modal-label">Upload By:</label>
                        </Grid.Column>
                        <Grid.Column className="input-field" width={10}>
                                {email}
                        </Grid.Column>

                        {/* Uploaded Date */}
                        <Grid.Column width={6} className="label-column">
                            <label className="edit-modal-label">Upload Date:</label>
                        </Grid.Column>
                        <Grid.Column className="input-field" width={10}>
                                {moment().format("DD/MM/YYYY")}
                        </Grid.Column>

                        {/* Version Comments */}
                        <Grid.Column width={6} className="label-column">
                            <label className="edit-modal-label">Version Comments:</label>
                        </Grid.Column>
                        <Grid.Column className="text-area-field" width={10}>
                            <Form.Input>
                                <TextArea rows={5} onChange={(e, data) => handleOnChange(e, data, "VersionComments", "text-area")} 
                                defaultValue={editableRow["VersionComments"].replace(/\\r\\n/g, '\n')}/>
                            </Form.Input>
                        </Grid.Column>

                    </Grid>
                </React.Fragment>
        );
    }
	
	return (
		<>
        	<Popup content="Add template" trigger={<Button className="table-add-btn" onClick={openModal}>Add Template</Button>}/>
			<Modal
				className="edit-modal"
				as={Form}
				onClose={void(0)}
				onOpen={openModal}
				open={open}
				onSubmit={handleFileUpload}
				>
				<Modal.Header>Template Upload</Modal.Header>
				<Modal.Content className="edit-modal-content" scrolling>
					{modalRow()}
				</Modal.Content>
				<Modal.Actions>
					{error.visible ? 
						<Message
							className="edit-modal-error-message"
							negative
							header="Error"
							list={error.list}
						></Message>
						:
						<></>
					}
					<Button disabled={submittingChanges === true} type="button" onClick={closeModal} content="Cancel Template Upload"/>
					<Button disabled={submittingChanges === true} 
						loading={submittingChanges === true} type="submit" icon="upload" content="Upload Template" color="green"/>
				</Modal.Actions>
			</Modal>
		</>
		);
}

export default TemplatesTableAddModal;