import React, { useState } from 'react'
import { Button, Popup, Form, Modal, Grid, Message, Input } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { inTargetFieldsAreValid, getHistoricAvgPercent, getMonthPercent, validateComplianceVariesBy10Percent, validateVolumesVaryByOver30Percent, validateInTargetMoreThanTotal } from '../../utils/reviewTableUtils';
import { customTableEditHandleOnChange, updateSearchEditData } from '../../utils/tableUtils';
import { logout } from '../../auth/authRedirect';
import '../CustomTable/EditModal/EditModal.scss';

const ReviewTableEditModal = (props) => {
    const { tableRows, setTableRows, index, apiUrl, openEditModal, setOpenEditModal, setMessage, searchState, setSearchState, fileName, setInTargetError } = props;
    const [editableRow, setEditableRow] = useState(tableRows);
    const [open, setOpen] = useState(props.open);
    const [error, setError] = useState({ visible: false, list: [] });
    const [submittingChanges, setSubmittingChanges] = useState(false);
    const jwtIdToken = useSelector(state => state.authReducer.authResponse.jwtIdToken);
    const exp = useSelector(state => state.authReducer.authResponse.idTokenClaims.exp);

    const openModal = () => setOpen(true);
    const closeModal = () => { setOpen(false); setError({ visible: false, list: [] }); }
    const closeModalCancel = () => {
        setError({ visible: false, list: [] });
        if (openEditModal.visible) {
            const tr = [...tableRows];
            const previousTableRows = tr.splice(0, tr.length - 1);
            setTableRows(previousTableRows);
            setOpenEditModal({ visible: false, index: null });
        } else {
            setEditableRow(tableRows);
            setOpen(false);
        }
    };

    const handleOnChange = (e, data, cellName, rIndex, fieldType) => {
        setEditableRow(customTableEditHandleOnChange(e, data, editableRow, cellName, rIndex, fieldType));
    };

    const checkForChanges = (tableRows, editableRow) => JSON.stringify(tableRows) === JSON.stringify(editableRow) ? true : false;

    const handleSubmit = async() => {
        setSubmittingChanges(true);

        try {
            await axios.patch(apiUrl, { 
                ...editableRow[index],
                FileName: fileName,
                ThisMonthPercent: (Math.round(((editableRow[index]["ThisMonthInTarget"] / editableRow[index]["ThisMonthTotal"]) * 100) * 100) / 100)
            }, { headers: { Authorization: `Bearer ${jwtIdToken}`, "Ocp-Apim-Subscription-Key": process.env.REACT_APP_OCP_SUBSCRIPTION_KEY }})
            setMessage({ visible: true, positive: true, negative: false, header: "Changes saved", text: "Your changes have been successfully updated." });
            
            const tableRowsCopy = [...tableRows];
            tableRowsCopy[index] = [...editableRow];
            setInTargetError(!inTargetFieldsAreValid(tableRowsCopy));
            setTableRows(editableRow);

            if (searchState !== undefined && searchState !== "") {
                setSearchState(updateSearchEditData(searchState, editableRow[index]));
            }
            closeModal();
        } catch (error) {
            if (new Date().getTime() >= exp * 1000) {
                logout();
            } else {
                console.error(error);
                setMessage({ visible: true, positive: false, negative: true, header: `Unable to save changes.`, text: error.response.data.ErrorMessage });
                closeModalCancel();
            }
        } finally {
            setSubmittingChanges(false);
        }
    }

    const modalRow = () => {
        return  (
                <React.Fragment>
                    <Grid>

                        {/* Metric */}
                        <Grid.Column width={7} className="label-column">
                            <label className="edit-modal-label">Metric:</label>
                        </Grid.Column>
                        <Grid.Column className="input-field" width={9}>
                            {editableRow[index]["Metric"]}
                        </Grid.Column>

                        {/* Historic Average In Target */}
                        <Grid.Column width={7} className="label-column">
                            <label className="edit-modal-label">Historic Average (Target):</label>
                        </Grid.Column>
                        <Grid.Column className="input-field" width={9}>
                            {editableRow[index]["HistoricAvgInTarget"]}
                        </Grid.Column>

                        {/* Historic Average Total */}
                        <Grid.Column width={7} className="label-column">
                            <label className="edit-modal-label">Historic Average (Total):</label>
                        </Grid.Column>
                        <Grid.Column className="input-field" width={9}>
                            {editableRow[index]["HistoricAvgTotal"]}
                        </Grid.Column>

                        {/* Historic Average In Percent */}
                        <Grid.Column width={7} className="label-column">
                            <label className="edit-modal-label">Historic Average (Percent):</label>
                        </Grid.Column>
                        <Grid.Column className="input-field" width={9}>
                            {getHistoricAvgPercent(editableRow[index]["HistoricAvgInTarget"], editableRow[index]["HistoricAvgTotal"]) + "%"}
                        </Grid.Column>

                        {/* This Month In Target */}
                        <Grid.Column width={7} className="label-column">
                            <label className="edit-modal-label">This Month (Target):</label>
                        </Grid.Column>
                        <Grid.Column className="number-field" width={9}>
                            <Input onChange={(e, data) => handleOnChange(e, data, "ThisMonthInTarget", props.index, "number")} min={0}
                                type="number" defaultValue={editableRow[index]["ThisMonthInTarget"]} required/>
                        </Grid.Column>

                        {/* This Month Total */}
                        <Grid.Column width={7} className="label-column">
                            <label className="edit-modal-label">This Month (Total):</label>
                        </Grid.Column>
                        <Grid.Column className="input-field" width={9}>
                        <Input onChange={(e, data) => handleOnChange(e, data, "ThisMonthTotal", props.index, "number")} min={0}
                                type="number" defaultValue={editableRow[index]["ThisMonthTotal"]} required/>
                        </Grid.Column>

                        {/* This Month Percent */}
                        <Grid.Column width={7} className="label-column">
                            <label className="edit-modal-label">This Month (Percent):</label>
                        </Grid.Column>
                        <Grid.Column className="number-field" width={9}>
                            {getMonthPercent(editableRow[index]["ThisMonthInTarget"], editableRow[index]["ThisMonthTotal"]) + "%"}
                        </Grid.Column>

                        {/* Compliance Varies By Over 10% */}
                        <Grid.Column width={7} className="label-column">
                            <label className="edit-modal-label">Compliance Varies By Over 10%:</label>
                        </Grid.Column>
                        <Grid.Column className="input-field" width={9}>
                        {validateComplianceVariesBy10Percent(getHistoricAvgPercent(editableRow[index]["HistoricAvgInTarget"], editableRow[index]["HistoricAvgTotal"]), getMonthPercent(editableRow[index]["ThisMonthInTarget"], editableRow[index]["ThisMonthTotal"]))}

                        </Grid.Column>

                        {/* Volume Varies By Over 30% */}
                        <Grid.Column width={7} className="label-column">
                            <label className="edit-modal-label">Volume Varies By Over 30%:</label>
                        </Grid.Column>
                        <Grid.Column className="input-field" width={9}>
                        {validateVolumesVaryByOver30Percent(
                            editableRow[index]["historicAvgInTarget"], editableRow[index]["ThisMonthInTarget"],
                            editableRow[index]["HistoricAvgTotal"], editableRow[index]["ThisMonthTotal"])}
                        </Grid.Column>

                        {/* In Target > Total */}
                        <Grid.Column width={7} className="label-column">
                            <label className="edit-modal-label">{"In Target > Total:"}</label>
                        </Grid.Column>
                        <Grid.Column className="input-field" width={9}>
                            {validateInTargetMoreThanTotal(editableRow[index]["ThisMonthInTarget"], editableRow[index]["ThisMonthTotal"])}
                        </Grid.Column>

                    </Grid>
                </React.Fragment>
        );
    }

    return (
    <>
        <Popup content="Edit row" trigger={<Button onClick={openModal} icon="edit"></Button>}/>
        <Modal
            className="edit-modal"
            as={Form}
            onClose={void(0)}
            onOpen={openModal}
            open={open}
            onSubmit={handleSubmit}
            >
            <Modal.Header>Edit row</Modal.Header>
            <Modal.Content className="edit-modal-content" scrolling>
               {modalRow()}
            </Modal.Content>
            <Modal.Actions>
                {error.visible ? 
                    <Message
                        className="edit-modal-error-message"
                        negative
                        header="Error"
                        list={error.list}
                    ></Message>
                    :
                    <></>
                }
                <Button disabled={submittingChanges === true} type="button" onClick={closeModalCancel} content="Cancel"/>
                <Button disabled={
                    checkForChanges(tableRows[index], editableRow[index]) || editableRow[index]["ThisMonthInTarget"] > editableRow[index]["ThisMonthTotal"] 
                    || ((editableRow[index]["ThisMonthInTarget"].length > 0 && editableRow[index]["ThisMonthTotal"].length) === 0 ||
                        (editableRow[index]["ThisMonthTotal"].length > 0 && editableRow[index]["ThisMonthInTarget"].length === 0)) 
                    || submittingChanges === true
                }
                    loading={submittingChanges} type="submit" icon="save" content="Save changes" color="green"/>
            </Modal.Actions>
        </Modal>
    </>
    );
}

ReviewTableEditModal.propTypes = {
    tableName: PropTypes.string,
    tableRows: PropTypes.array,
    setTableRows: PropTypes.func,
    editableRow: PropTypes.array,
    index: PropTypes.number,
    apiUrl: PropTypes.string
}

ReviewTableEditModal.defaultProps = {
    apiUrl: null
};

export default ReviewTableEditModal;