import React from 'react';
import { isIE } from '../utils/generalUtils';

export const IENotice = () => {
    if (isIE()) {
        return <h1 className="ie-notice">Notice: Internet Explorer users will require the "Access data sources across domains" security setting to be enabled in order to view this content.</h1>
    } else {
        return <></>
    }
};

export const IELoadingNotice = () => {
    return <h2 style={{ marginTop: '1em', color: "white" }}>Notice: Please ensure the "Access data sources across domains" option is enabled in Internet Explorer.<br/><br/> If it isn't, please logout, enable the option, and login again. Thank You.<br/><br/></h2>
}