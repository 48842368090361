import React from 'react';
import { Loader, Button, Message } from 'semantic-ui-react';
import './PageLoading.scss';
import waterUkLogo from '../../assets/images/wateruk.svg';
import { isIE } from '../../utils/generalUtils';
import { IELoadingNotice } from '../../constants/generalConsts';
import { logout } from '../../auth/authRedirect';

const PageLoading = props => {

    const GenericLoading = () => {

        if (props.error) {
            return (
                <div className="generic-full-page-loading">
                    <div className="water-uk-branding">
                    <img className="water-uk-logo-loading" src={waterUkLogo} alt="Water UK"/>
                    <Message
                        className="generic-full-page-loading-error"
                        negative
                        header="Error"
                        content={props.error}
                    ></Message>
                    <Button className="logout-btn" onClick={() => logout()}>Logout</Button>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="generic-full-page-loading">
                    <div className="water-uk-branding">
                    <img className="water-uk-logo-loading" src={waterUkLogo} alt="Water UK"/>
                        <Loader className="full-page-loader" inverted active inline='centered' />
                        <h2>Loading</h2>
                    </div>
                </div>
            );
        }
    }

    const IELoading = () => {
        return (
            <div className="ie-full-page-loading">
                <div className="water-uk-branding">
                <img className="water-uk-logo-loading" src={waterUkLogo} alt="Water UK"/>
                    <Loader className="full-page-loader" inverted active inline='centered' />
                    <h2>Loading</h2>
                    <IELoadingNotice/>
                    <Button className="ie-loading-btn" onClick={() => logout()}>Logout</Button>
                </div>
            </div>
        )
    }

    if (isIE()) {
        return <IELoading/>
    } else {
        return <GenericLoading/>
    }
}

export default PageLoading;
