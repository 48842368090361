import React, { useState, useEffect } from 'react';
import { Loader } from 'semantic-ui-react';
import axios from 'axios';
import MetricDefinitionsTable from '../../../components/metricDefinitionsTable/MetricDefinitionsTable';
import { HEADERS, FIELDS, FIELD_HEADERS, SORT_FIELDS, SORT_FIELD_HEADERS } from './MetricsReportPublicTableSchema';
import './MetricsReportPublic.scss';

const MetricsReportPublic = props => {
    const [data, setData] = useState(null);
    const [error, setError] = useState({ visible: false, message: "" });
    
    useEffect(() => {
        const fetchData = async() => {
            try {
                const { data } = await axios.get(process.env.REACT_APP_METRICS_URL,
                    { headers: { "Ocp-Apim-Subscription-Key": process.env.REACT_APP_OCP_SUBSCRIPTION_KEY }});
                const rowsWithKeys = Object.values([...data.Rows]).map((row, index) => { return { 
                    ReactKey: index,
                    Code: row.Code,
                    Title: row.Title,
                    Definition: row.Definition,
                    Type: row.Type,
                    ReportType: row.ReportType,
                    Target: row.Target
                }});
                data["Rows"] = rowsWithKeys;
                setData(data);
            } catch (error) {
                console.error(error);
                setError({visible: true, message: `Unable to connect to Metrics Report API.\n ${error.response.data.ErrorMessage}`});
            }
        }
        fetchData();
    }, []);

    const ShowTable = () => {
        return (
            <>
                <h1>Metrics Report</h1>
                <MetricDefinitionsTable
                    tableName="metric-report-table-public"
                    headers={HEADERS}
                    fieldHeaders={FIELD_HEADERS}
                    rows={data.Rows}
                    fieldsEdit={FIELDS}
                    fieldsAdd={FIELDS}
                    sortFields={SORT_FIELDS}
                    sortFieldHeaders={SORT_FIELD_HEADERS}
                    dropdowns={data.Dropdowns}
                    editable={false}
                />
            </>
        );
    };

    const ShowLoading = () => {
        return error.visible === false ?
            <Loader active inline='centered'>Retrieving Metrics Report</Loader> : error.message.split("\n").map((line, i) => <h1 key={i} className="error">{line}</h1>);
    };

    return (
        <div className="metrics-report-container">
            {data === null ? <ShowLoading/> : <ShowTable/>}
        </div>
    );
}

export default MetricsReportPublic;