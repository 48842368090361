import React, { useState } from 'react'
import { Button, Popup, Form, Modal, Grid, Message, Input, Dropdown, Checkbox } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { customTableEditHandleOnChange, checkForEditFieldErrors, updateSearchEditData } from '../../utils/tableUtils';
import { FIELD_HEADERS } from './UserManagementTableSchema';
import { logout } from '../../auth/authRedirect';
import '../CustomTable/EditModal/EditModal.scss';

const UserManagementTableEditModal = (props) => {
    const { tableRows, setTableRows, index, apiUrl, openEditModal, setOpenEditModal, fieldsEdit, setMessage, searchState, setSearchState } = props;
    const [editableRow, setEditableRow] = useState(tableRows);
    const [open, setOpen] = useState(props.open);
    const [error, setError] = useState({ visible: false, list: [] });
    const [submittingChanges, setSubmittingChanges] = useState(false);
    const jwtIdToken = useSelector(state => state.authReducer.authResponse.jwtIdToken);
    const exp = useSelector(state => state.authReducer.authResponse.idTokenClaims.exp);

    const openModal = () => setOpen(true);
    const closeModal = () => { setOpen(false); setError({ visible: false, list: [] }); }
    const closeModalCancel = () => {
        setError({ visible: false, list: [] });
        if (openEditModal.visible) {
            const tr = [...tableRows];
            const previousTableRows = tr.splice(0, tr.length - 1);
            setTableRows(previousTableRows);
            setOpenEditModal({ visible: false, index: null });
        } else {
            setEditableRow(tableRows);
            setOpen(false);
        }
    };

    const handleOnChange = (e, data, cellName, rIndex, fieldType) => {
        setEditableRow(customTableEditHandleOnChange(e, data, editableRow, cellName, rIndex, fieldType));
    };

    const checkForChanges = (tableRows, editableRow) => JSON.stringify(tableRows) === JSON.stringify(editableRow) ? true : false;

    const handleSubmit = async() => {
        setSubmittingChanges(true);
        const fieldErrors = checkForEditFieldErrors(editableRow, index, fieldsEdit, FIELD_HEADERS);

        if (fieldErrors.length > 0) {
            setError({visible: true, list: fieldErrors});
            setSubmittingChanges(false);
            return;
        } else {
            setError({visible: false, list: []});
        }

        if (apiUrl === null) {
            setTableRows(editableRow);
            if (searchState !== undefined && searchState !== "") {
                setSearchState(updateSearchEditData(searchState, editableRow[index]));
            }
            closeModal();
            setSubmittingChanges(false);
            return;
        }

        try {
            await axios.put(apiUrl, { ...editableRow[index] }, 
                { headers: { Authorization: `Bearer ${jwtIdToken}`, "Ocp-Apim-Subscription-Key": process.env.REACT_APP_OCP_SUBSCRIPTION_KEY }})
            setMessage({ visible: true, positive: true, negative: false, header: "Changes saved", text: "Your changes have been successfully updated." });
            setTableRows(editableRow);
            if (searchState !== undefined && searchState !== "") {
                setSearchState(updateSearchEditData(searchState, editableRow[index]));
            }
            closeModal();
        } catch (error) {
            if (new Date().getTime() >= exp * 1000) {
                logout();
            } else {
                console.error(error);
                setMessage({ visible: true, positive: false, negative: true, header: `Unable to save changes.`, text: error.response.data.ErrorMessage });
                closeModalCancel();
            }
        } finally {
            setSubmittingChanges(false);
        }
    }

    const modalRow = () => {
        return  (
                <React.Fragment>
                    <Grid>

                        {/* Forename */}
                        <Grid.Column width={6} className="label-column">
                            <label className="edit-modal-label">Forename:</label>
                        </Grid.Column>
                        <Grid.Column className="input-field" width={10}>
                            <Form.Input>
                                <Input onChange={(e, data) => handleOnChange(e, data, "Forename", props.index, "input")} type="text" defaultValue={editableRow[index]["Forename"]} required></Input>
                            </Form.Input>
                        </Grid.Column>

                        {/* Surname */}
                        <Grid.Column width={6} className="label-column">
                            <label className="edit-modal-label">Surname:</label>
                        </Grid.Column>
                        <Grid.Column className="input-field" width={10}>
                            <Form.Input>
                                <Input onChange={(e, data) => handleOnChange(e, data, "Surname", props.index, "input")} type="text" defaultValue={editableRow[index]["Surname"]} required></Input>
                            </Form.Input>
                        </Grid.Column>

                        {/* Email */}
                        <Grid.Column width={6} className="label-column">
                            <label className="edit-modal-label">Email Address:</label>
                        </Grid.Column>
                        <Grid.Column className="email-field" width={10}>
                            <Form.Input>
                                <Input onChange={(e, data) => handleOnChange(e, data, "EmailAddress", props.index, "email")} type="email" defaultValue={editableRow[index]["EmailAddress"]} required></Input>
                            </Form.Input>
                        </Grid.Column>

                        {/* Active */}
                        <Grid.Column width={6} className="label-column">
                            <label className="edit-modal-label">Active:</label>
                        </Grid.Column>
                        <Grid.Column className="checkbox-field" width={10}>
                            <Form.Input>
                                <Checkbox onChange={(e, data) => handleOnChange(e, data, "Active", props.index, "checkbox")} defaultChecked={editableRow[index]["Active"] === "Yes" ? true : false} toggle/>
                            </Form.Input>
                        </Grid.Column>

                        {/* User Role */}
                        <Grid.Column width={6} className="label-column">
                            <label className="edit-modal-label">User Role:</label>
                        </Grid.Column>
                        <Grid.Column className="dropdown-field" width={10}>
                            <Form.Input>
                            <Dropdown onChange={(e, data) =>  handleOnChange(e, data, "UserRole", props.index, "dropdown")}
                                placeholder={editableRow[index]["UserRole"]} selection fluid scrolling={true} selectOnBlur={false} defaultValue={editableRow[index]["UserRole"]}
                                options={props.dropdowns["UserRole"].map((dropdown, index) => { return { key: index, value: dropdown, text: dropdown }})} required/>
                            </Form.Input>
                        </Grid.Column>
                    
                        {/* Company */}
                        <Grid.Column width={6} className="label-column">
                            <label className="edit-modal-label">Company:</label>
                        </Grid.Column>
                        <Grid.Column className="dropdown-field" width={10}>
                            <Form.Input>
                            <Dropdown onChange={(e, data) =>  handleOnChange(e, data, "CompanyName", props.index, "dropdown")}
                                placeholder={editableRow[index]["CompanyName"]} selection fluid scrolling={true} selectOnBlur={false} defaultValue={editableRow[index]["CompanyName"]}
                                options={props.dropdowns["CompanyName"].map((dropdown, index) => { return { key: index, value: dropdown, text: dropdown }})} required/>
                            </Form.Input>
                        </Grid.Column>

                    </Grid>
                </React.Fragment>
        );
    }

    return (
    <>
        <Popup content="Edit row" trigger={<Button onClick={openModal} icon="edit"></Button>}/>
        <Modal
            className="edit-modal"
            as={Form}
            onClose={void(0)}
            onOpen={openModal}
            open={open}
            onSubmit={handleSubmit}
            >
            <Modal.Header>Edit row</Modal.Header>
            <Modal.Content className="edit-modal-content" scrolling>
               {modalRow()}
            </Modal.Content>
            <Modal.Actions>
                {error.visible ? 
                    <Message
                        className="edit-modal-error-message"
                        negative
                        header="Error"
                        list={error.list}
                    ></Message>
                    :
                    <></>
                }
                <Button type="button" onClick={closeModalCancel} content="Cancel"/>
                <Button disabled={checkForChanges(tableRows[index], editableRow[index]) || submittingChanges === true}
                    loading={submittingChanges} type="submit" icon="save" content="Save changes" color="green"/>
            </Modal.Actions>
        </Modal>
    </>
    );
}

UserManagementTableEditModal.propTypes = {
    tableName: PropTypes.string,
    tableRows: PropTypes.array,
    setTableRows: PropTypes.func,
    editableRow: PropTypes.array,
    index: PropTypes.number,
    apiUrl: PropTypes.string
}

UserManagementTableEditModal.defaultProps = {
    apiUrl: null
};

export default UserManagementTableEditModal;