import React, { useState } from 'react';
import { Message, Button } from 'semantic-ui-react';
import Cookies from 'universal-cookie';
import './CookieNotice.scss';

const CookieNotice = () => {
    const cookies = new Cookies();
    const [cookieNotice, setCookieNotice] = useState(cookies.get("cookieNotice")?.accepted);

    const handleCookieAccept = () => {
        const date = Date.now();
        const current = new Date();
        const nextYear = new Date();
        nextYear.setFullYear(current.getFullYear() + 1);

        cookies.set("cookieNotice", { accepted: true, dateAccepted: date }, {
            path: '/',
            expires: nextYear,
        });
        setCookieNotice(true);
    }

    if (cookieNotice === true) {
        return <></>
    } else {
    return (
        <div className="cookie-banner">
        <Message className="cookie-message" onDismiss={handleCookieAccept}>
            <div className="cookie-content-wrapper">
            <Message.Header className="cookie-header">Cookies Policy</Message.Header>
            <Message.Content className="cookie-text">
                This website uses cookies to improve user experience. If you continue without changing your settings, we’ll assume you’re happy to receive all cookies from the Water UK website.
                <Button onClick={handleCookieAccept}>Accept Cookies</Button>
            </Message.Content>
            </div>
        </Message>
        </div>
        )
    }
};

export default CookieNotice;