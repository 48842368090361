import { msalAuth } from '../App';
import { getClientId } from '../auth/authConfig';

export const SET_AUTH_DETAILS = "SET_AUTH_DETAILS";
export const SET_AUTH_DETAILS_ERROR = "SET_AUTH_DETAILS_ERROR";

export const setAuthDetails = () => {
    return async (dispatch) => {
        try {
            const clientId = getClientId();
            const response = await msalAuth.acquireTokenSilent({ scopes: [clientId] });
            const jwtIdToken = response.idToken.rawIdToken;
            const result = { ...response, jwtIdToken };
        dispatch({
            type: SET_AUTH_DETAILS,
            payload: result
        })
        } catch (error) {
            dispatch({
                type: SET_AUTH_DETAILS_ERROR,
                payload: error.message
            })
        }
    }
};