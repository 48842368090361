import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { setAuthDetails } from '../../actions/authActions';
import { Loader } from 'semantic-ui-react';
import axios from 'axios';
import { Form, TextArea, Button, Modal, Card } from 'semantic-ui-react';
import ResubmissionTable from '../../components/resubmissionTable/ResubmissionTable';
import ResponseMessage from '../../components/CustomTable/ResponseMessage/ResponseMessage';
import { HEADERS, FIELD_HEADERS, SORT_FIELDS } from '../../components/reportingPeriodsTable/ReportingPeriodsTableSchema';
import { logout } from '../../auth/authRedirect';
import './Resubmission.scss';

const Resubmission = props => {
    const [data, setData] = useState(null);
    const [error, setError] = useState({ visible: false, message: "" });
    const [message, setMessage] = useState({ visible: false, positive: false, negative: false, header: "", text: "" });
    const [comments, setComments] = useState("");
    const [selectedReportPeriod, setSelectedReportPeriod] = useState(null);
    const [submittingChanges, setSubmittingChanges] = useState(false);
    const [resubmitted, setResubmitted] = useState(false);
    const { setAuthDetails, jwtIdToken, exp } = props;

    useEffect(() => {
        const fetchData = async() => {
            await setAuthDetails();
            try {
                const { data } = await axios.post(process.env.REACT_APP_PERIODS_URL, { origin: "resubmit" },
                    { headers: { Authorization: `Bearer ${jwtIdToken}`, "Ocp-Apim-Subscription-Key": process.env.REACT_APP_OCP_SUBSCRIPTION_KEY }});
                const rowsWithKeys = Object.values([...data.Rows]).map((row, index) => { return { ReactKey: index, ...row, Checked: "No" } });
                data["Rows"] = rowsWithKeys;
                setData(data);
            } catch (error) {
                if (new Date().getTime() >= exp * 1000) {
                    logout();
                } else {
                    console.error(error);
                    setError({visible: true, message: `Unable to connect to Resubmission API.\n ${error.response.data.ErrorMessage}`});
                }
            }
        }
        fetchData();
    }, [setAuthDetails, jwtIdToken, exp]);

    const handleTextAreaOnChange = (e, { value }) => setComments(value);

    const handleSubmit = async() => {
        setSubmittingChanges(true);

        try {
            await axios.post(process.env.REACT_APP_RESUBMIT_URL, { 
                Comments: comments,
                ...selectedReportPeriod
             }, { headers: { Authorization: `Bearer ${jwtIdToken}`, "Ocp-Apim-Subscription-Key": process.env.REACT_APP_OCP_SUBSCRIPTION_KEY }})
                setResubmitted(true);
        } catch (error) {
            if (new Date().getTime() >= exp * 1000) {
                logout();
            } else {
                console.error(error);
                setMessage({ visible: true, positive: false, negative: true, header: `Unable to save changes.`, text: error.response.data.ErrorMessage });
            }
        } finally { 
            setSubmittingChanges(false);
            setError({ visible: false, list: [] });
        }
    }
    
    const ShowLoading = () => {
        return error.visible === false ?
            <Loader active inline='centered'>Retrieving Resubmission Details</Loader> : error.message.split("\n").map((line, i) => <h1 key={i} className="error">{line}</h1>);
    }

    const ResubmissionComplete = () => {
        return (
            <>
                <Card className="resubmission-card">
                    <Card.Content>
                        <h2 className="success display-linebreak">Resubmission request sent successfully.</h2>
                    </Card.Content>
                </Card>
            </>
        )
    };

    return (
        <div className="resubmission-container">
            {data === null ? <ShowLoading/> : 
            <>
            <div className="resubmission-container">
                <h1>Resubmission</h1>
                <div className="resubmission-form">
                {resubmitted === true ? 
                    <ResubmissionComplete/>
                :
                    <>
                    <Modal
                    className="error-modal"
                    centered={false}
                    open={error.showError}
                    onClose={() => setError({ showError: false, message: ''})}
                    header='Error'
                    content={<Modal.Content><p>{error.message}</p></Modal.Content>}
                    actions={['OK']}
                    />
                    <ResubmissionTable
                        headers={HEADERS}
                        rows={data.Rows}
                        fieldHeaders={FIELD_HEADERS}
                        sortFields={SORT_FIELDS}
                        dropdowns={data.Dropdowns}
                        editable={false}
                        setSelectedReportPeriod={setSelectedReportPeriod}
                    />
                    {message.visible ? <ResponseMessage style={{ marginTop: '3em' }} {...message} setMessage={setMessage}/> : <div style={{ marginTop: '3em' }}></div>}
                    <Form className="comments-box" size="massive">
                    <h2 style={{ fontSize: "1em" }}>Comment:</h2>
                    <TextArea onChange={handleTextAreaOnChange} placeholder='Please explain the reason for the amendment here.' rows={5} required/>
                        <Button disabled={selectedReportPeriod === null || comments.trim() === "" || submittingChanges === true } 
                            size="large" onClick={handleSubmit}>Submit</Button>
                    </Form>
                    </>
                }
                </div>
            </div>
            </>
            }
        </div>
    );
}

const mapStateToProps = (state) => {
    const jwtIdToken = state.authReducer.authResponse.jwtIdToken;
    const exp = state.authReducer.authResponse.idTokenClaims.exp;
    return {
        jwtIdToken,
        exp,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        setAuthDetails: () => dispatch(setAuthDetails())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Resubmission);