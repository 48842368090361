import React from 'react';
import { Card, Button, Form, Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { downloadTemplate } from '../../utils/dataUploadUtils';
import { MISC } from '../../constants/dataUploadConsts';

const DataUploadDisabled = props => {

 const handleTemplateDownload = () => downloadTemplate(props.current?.FileName);

  return (
    <div className="data-upload-container">
      <Card className='data-upload-card'>
        <Card.Content>
          <Form size='big'>
            <Form.Field>
                <h1 style={{ marginTop: "5rem", marginBottom: "5rem" }}>{MISC.CLOSED}</h1>
            </Form.Field>

            <Form.Group className='data-upload-form'>
              <Form.Field className='data-upload-btn-field'>
                <Button size='large' onClick={handleTemplateDownload}>
                  <Icon name='download'></Icon>Download Template
                </Button>
              </Form.Field>
            </Form.Group>

            <Form.Group className='data-upload-additional-options'>
              <Form.Field className='data-upload-btn-field'>
                <Link to='/resubmission'>
                  <Button className='resubmission-btn' size='large'>
                    Amend My Data
                  </Button>
                </Link>
              </Form.Field>
              <Form.Field className='data-upload-btn-field'>
                <Link to='/data-upload/qualitative-data-upload'>
                  <Button size='large'>Qualitative Data Upload</Button>
                </Link>
              </Form.Field>
            </Form.Group>
          </Form>
        </Card.Content>
      </Card>
    </div>
  );
};

export default DataUploadDisabled;