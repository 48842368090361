export const HEADERS = [
    "Company Name",
    "Active",
    "Company Type",
    "Region"
];

export const INITIAL_STATE = {
    CompanyName: "",
    CompanyActive: "Yes",
    CompanyType: "",
    Region: "",
};

export const FIELD_HEADERS = [
    "ReactKey",
    "Company Name",
    "Active",
    "Company Type",
    "Region"
];

export const FIELDS = {
    ReactKey: "hidden-field",
    CompanyName: "text-required",
    CompanyActive: "checkbox",
    CompanyType: "dropdown-required",
    Region: "dropdown-required"
};

export const SORT_FIELDS = {
    "Company Name": "CompanyName",
    "Active": "CompanyActive",
    "Company Type": "CompanyType",
    "Region": "Region",
};