export const NAVIGATION_LINKS = {
    PUBLIC: [
        { title: "Homepage", url: "/" },
        { title: "Dashboard", url: "/dashboard" },
        { title: "Metrics Report", url: "/metrics-report" },
        { title: "Contact", url: "/contact" },
        { title: "Login", url: "/login" },
    ],
    USER: [
        { title: "Homepage", url: "/homepage" },
        { title: "Dashboard", url: "/" },
        { title: "Metrics Report", url: "/metrics-report" },
        { title: "Contact", url: "/contact" },
        { title: "Logout", url: "/logout" },
    ],
    WATER_COMPANY_ADMIN: [
        { title: "Homepage", url: "/homepage" },
        { title: "Dashboard", url: "/" },
        { title: "Administration", subMenus: [
                { title: "Audit Log", url: "/audit-log" },
                { title: "Data Upload", url: "/data-upload" },
                { title: "Reporting Periods", url: "/reporting-periods" },
                { title: "Metrics Report", url: "/metrics-report" },
                { title: "User Management", url: "/user-management" },
            ] 
        },
        { title: "Contact", url: "/contact" },
        { title: "Logout", url: "/logout" }
    ],
    SUPER_ADMIN: [
        { title: "Homepage", url: "/homepage" },
        { title: "Dashboard", url: "/" },
        { title: "Administration", subMenus: [
                { title: "Audit Log", url: "/audit-log" },
                { title: "Company Details", url: "/company-details" },
                { title: "Data Upload", url: "/data-upload" },
                { title: "Reporting Periods", url: "/reporting-periods" },
                { title: "Metric Definitions", url: "/metric-definitions" },
                { title: "Metrics Report", url: "/metrics-report" },
                { title: "User Management", url: "/user-management" },
            ]
        },
        { title: "Contact", url: "/contact" },
        { title: "Logout", url: "/logout" }
    ],
};