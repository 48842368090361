import React from 'react';
import { Icon } from 'semantic-ui-react';

const ErrorSign = ({warningType}) => {
    return warningType === "error" ? <Icon color="red" name="warning sign"/> : <Icon name="warning sign"/>
};

export const inTargetFieldsAreValid = tableRows => tableRows.every(r => validateInTargetMoreThanTotal(r.ThisMonthInTarget, r.ThisMonthTotal) === "");

export const getHistoricAvgPercent = (historicAvgInTarget, historicAvgTotal) => {
    return (Math.round(((historicAvgInTarget / historicAvgTotal ) * 100) * 100) / 100) || 0;
};

export const getMonthPercent = (thisMonthInTarget, thisMonthTotal) => {
    return (Math.round(((thisMonthInTarget / thisMonthTotal ) * 100) * 100) / 100) || 0;
}

export const validateComplianceVariesBy10Percent = (historicAvgPercent, thisMonthPercent) => {
    if (((historicAvgPercent - thisMonthPercent) > 10) || ((thisMonthPercent - historicAvgPercent) > 10)) {
        return <ErrorSign warningType="warning"/>
    } else {
        return "";
    }
};

export const validateVolumesVaryByOver30Percent = (historicAvgInTarget, thisMonthInTarget, historicAvgTotal, thisMonthTotal) => {
    if (historicAvgInTarget > thisMonthInTarget * 1.3 ||
        historicAvgInTarget < thisMonthInTarget * 0.7 ||
        historicAvgTotal > thisMonthTotal * 1.3 ||
        historicAvgTotal < thisMonthTotal * 0.7) {
            return <ErrorSign warningType="warning"/>
        } else {
            return "";
        }
};

export const validateInTargetMoreThanTotal = (thisMonthTarget, thisMonthTotal) => {
    return thisMonthTarget > thisMonthTotal ? <ErrorSign warningType="error"/> : ""; 
};

export const InTargetMoreThanTotal = (thisMonthTarget, thisMonthTotal) => {
    return thisMonthTarget > thisMonthTotal ? "*" : ""; 
};