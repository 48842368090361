import React, { useState, useEffect, useCallback } from 'react';
import { Loader, Card, Button, Confirm } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { Prompt } from 'react-router';
import { Link } from 'react-router-dom';
import axios from 'axios';
import ReviewTable from '../reviewTable/ReviewTable';
import { HEADERS, FIELDS, SORT_FIELDS, FIELD_HEADERS } from '../reviewTable/ReviewTableSchema';
import { SUCCESS } from '../../constants/dataUploadConsts';
import { inTargetFieldsAreValid } from '../../utils/reviewTableUtils';
import { logout } from '../../auth/authRedirect';
import './ConfirmationPages.scss';

const ConfirmationPage = props => {
    const [data, setData] = useState(null);
    const [error, setError] = useState({ visible: false, message: "" });
    const [cancelSubmission, setCancelSubmission] = useState(false);
    const [cancelSubmissionModalVisible, setCancelSubmissionModalVisible] = useState(false);
    const [submissionConfirmed, setSubmissionConfirmed] = useState(false);
    const [inTargetError, setInTargetError] = useState(false);
    const [tableRows, setTableRows] = useState([]);
    const { fileName, jwtIdToken, exp } = props;

    useEffect(() => {
        const fetchData = async() => {
            try {
                const { data } = await axios.post(process.env.REACT_APP_VALIDATE_URL, {FileName : fileName},
                    { headers: { Authorization: `Bearer ${jwtIdToken}`, "Ocp-Apim-Subscription-Key": process.env.REACT_APP_OCP_SUBSCRIPTION_KEY }});
                if (data.Rows.length > 0) {
                    const rowsWithKeys = Object.values([...data.Rows]).map((row, index) => { return { ReactKey: index, ...row } });
                    data["Rows"] = rowsWithKeys;
                    setInTargetError(!inTargetFieldsAreValid(data.Rows));
                    setTableRows(data.Rows);
                }
                setData(data);
            } catch (error) {
                if (new Date().getTime() >= exp * 1000) {
                    logout();
                } else {
                    console.error(error);
                    setError({visible: true, message: `Unable to connect to Validation API.\n ${error.response.data.ErrorMessage}`});
                }
            }
        }
        fetchData();
    }, [jwtIdToken, fileName, exp]);

    const handleSubmission = () => {
        setSubmissionConfirmed(true);
    }
    const handleSubmissionCancel = () => {
        setCancelSubmission(true);
    }

    const SubmissionLoading = ({ text }) => {
        return <Loader active inline='centered'>{text}</Loader>
    }

    const SubmissionCancelled = ({ text }) => {
        const [cancelled, setCancelled] = useState(false);
        const [error, setError] = useState(false);

        const deleteFile = useCallback(async() => {
            try {
                await axios.delete(process.env.REACT_APP_SUBMIT_URL, 
                {
                    data: { FileName: fileName },
                    headers: { Authorization: `Bearer ${jwtIdToken}`, "Ocp-Apim-Subscription-Key": process.env.REACT_APP_OCP_SUBSCRIPTION_KEY }
                });
                setCancelled(true);
            } catch (error) {
                if (new Date().getTime() >= exp * 1000) {
                    logout();
                } else {
                    setError(error);
                }
            }
          }, []);

        useEffect(() => {
            deleteFile();
        }, [deleteFile])

        if (cancelled === true) {
            return (
                <Card className="data-upload-card upload-cancelled">
                    <Card.Content>
                        <h2 className="success">Submission cancelled successfully.</h2>
                        <Link to="/data-upload"><Button>Data Upload</Button></Link>
                    </Card.Content>
                </Card>
            )
        } else if (error !== false) {
            return <h1 className="error">{error.toString()}</h1>
        } else {
            return <SubmissionLoading text={text}/>
        }
    };

    const SubmissionAccepted = ({ text }) => {
        const [submitted, setSubmitted] = useState(false);
        const [error, setError] = useState(false);

        const submitFile = useCallback(async() => {
            try {
                await axios.post(process.env.REACT_APP_SUBMIT_URL, { FileName: fileName},
                { headers: { Authorization: `Bearer ${jwtIdToken}`, "Ocp-Apim-Subscription-Key": process.env.REACT_APP_OCP_SUBSCRIPTION_KEY }});
                setSubmitted(true);
            } catch (error) {
                if (new Date().getTime() >= exp * 1000) {
                    logout();
                } else {
                    setError(error);
                }
            }
          }, []);

        useEffect(() => {
            submitFile();
        }, [submitFile])

        if (submitted === true) {
            return (
                <Card className="data-upload-card upload-complete">
                    <Card.Content>
                        <h2 className="success">{SUCCESS.FILE_UPLOAD}</h2>
                    </Card.Content>
                </Card>
            )
        } else if (error !== false) {
            return <h1 className="error">{error.toString()}</h1>
        } else {
            return <SubmissionLoading text={text}/>
        }
    };

    const CriticalErrors = () => {
        const criticalErrors = data.CriticalErrors.map((err, index) => {
            return <li className="error" key={index}>{err}</li>
        });
        
        return criticalErrors.length > 0 ?
        <Card className="validation-card">
            <Card.Content>
                <h1 className="error underline">Critical Errors</h1>
                <ul>
                    {criticalErrors}
                </ul>
                <Link to="/data-upload"><Button className="resubmission-btn" size="large">Return to Data Upload</Button></Link>
            </Card.Content>
        </Card>
        :   <></>
    };

    const Warnings = () => {
        const warnings = data.Warnings.map((warn, index) => {
            return <li className="warning" key={index}>{warn}</li>
        });

        return warnings.length > 0 ?
        <Card className="validation-card">
            <Card.Content>
                <h1 className="warning underline">Warnings</h1>
                <ul>
                    {warnings}
                </ul>
            </Card.Content>
        </Card>
        :   <></>
    };

    const ReviewTableData = () => {
        return data.CriticalErrors.length === 0 ?
            <>
            <Prompt
                when={submissionConfirmed === false}
                message={"Any changes made will be lost. Are you sure you wish to leave the page?"}
            />
            <div className="review-data-table-container">
                <ReviewTable
                    tableName="review-data-table"
                    apiUrl={process.env.REACT_APP_VALIDATE_URL}
                    headers={HEADERS}
                    tableRows={tableRows}
                    setTableRows={setTableRows}
                    fieldHeaders={FIELD_HEADERS}
                    fieldsEdit={FIELDS}
                    fieldsAdd={FIELDS}
                    sortFields={SORT_FIELDS}
                    dropdowns={data.Dropdowns}
                    editable={true}
                    fileName={fileName}
                    setInTargetError={setInTargetError}
                />
            </div>
            </>
        : <></>
    }

    const ActionButtons = () => {
        return (
        <div className="action-buttons">
            {data.CriticalErrors.length > 0 ?
                <></>
                :
                <>
                <Confirm open={cancelSubmissionModalVisible}
                    content="Are you sure you wish to abort the upload process?"
                    cancelButton="No"
                    confirmButton="Yes"
                    onCancel={() => setCancelSubmissionModalVisible(false)}
                    onConfirm={handleSubmissionCancel}/>
                <Button disabled={(data.CriticalErrors.length > 0) || (inTargetError === true)}
                    className="submission-btn green"
                    size="large"
                    onClick={handleSubmission}>Confirm Submission</Button>
                <Button
                    className="cancel-submission-btn red"
                    size="large"
                    onClick={() => setCancelSubmissionModalVisible(true)}>Cancel Submission</Button>
                </>
            }
        </div>
        );
    }

    const ShowContent = () => {
        if (data.CriticalErrors.length > 0) {
            return (
            <>
                <CriticalErrors/>
                <ActionButtons/>
            </>
            )
        } else {
            return (
            <>
                <Warnings/>
                <ReviewTableData/>
                <ActionButtons/>
            </>
            );
        }
    }

    const ShowLoading = () => {
        return error.visible === false ?
            <Loader active inline='centered'>Validating Data</Loader> : error.message.split("\n").map((line, i) => <h1 key={i} className="error">{line}</h1>);
    }

    if (data === null) {
        return <ShowLoading/>
    } else if (submissionConfirmed === true) {
        return <SubmissionAccepted text="Submitting"/>
    } 
    else if (cancelSubmission === true) {
        return <SubmissionCancelled text="Cancelling Submission"/>
    } else {
        return (
            <div className="validation-container">
                <h1>Upload Confirmation</h1>
                <ShowContent/>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const jwtIdToken = state.authReducer.authResponse.jwtIdToken;
    const exp = state.authReducer.authResponse.idTokenClaims.exp;
    return {
        jwtIdToken,
        exp
    }
};

export default connect(mapStateToProps)(ConfirmationPage);
