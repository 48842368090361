export const HEADERS = [
    "Code",
    "Title",
    "Definition",
    "Valid From",
    "Valid To",
    "Type",
    "Report Type",
    "Target",
    "D-MeX Qualitative",
    "D-MeX Quantitative",
    "D-MeX Reportable Qualitative",
    "D-MeX Reportable Quantitative",
    "Category",
    "Customer Type",
    "Codes Viewable"
 ];
 
 export const INITIAL_FIELDS = {
    Code: "",
    Title: "",
    Definition: "",
    ValidFrom: "",
    ValidTo: "",
    Type: "",
    ReportType: "",
    Target: "",
    DMeXQualitative: "",
    DMeXQuantitative: "",
    DMexReportableQualitative: "",
    DMexReportableQuantitative: "",
    Category: "",
    CustomerType: "",
    CodesViewable: ""
 };

export const FIELD_HEADERS = [
    "ReactKey",
    "Code",
    "Title",
    "Definition",
    "Type",
    "Report Type",
    "Target",
    "DMeX Qualitative",
    "DMeX Quantitative",
    "DMeX Reportable Qualitative",
    "DMeX Reportable Quantitative",
    "Category",
    "Customer Type",
    "Codes Viewable",
    "Valid From",
    "Valid To",
];

export const FIELDS = {
    ReactKey: "hidden-field",
    Code: "read-only",
    Title: "text-area",
    Definition: "text-area",
    ValidFrom: "read-only",
    ValidTo: "read-only",
    Type: "read-only",
    ReportType: "read-only",
    Target: "read-only",
    DMeXQualitative: "read-only",
    DMeXQuantitative: "read-only",
    DMexReportableQualitative: "read-only",
    DMexReportableQuantitative: "read-only",
    Category: "read-only",
    CustomerType: "read-only",
    CodesViewable: "read-only"
};

//TODO: Remove array. Implemented due to lower case x on DMeX Reportable fields.
export const SORT_FIELD_HEADERS = [
    "ReactKey",
    "Code",
    "Title",
    "Definition",
    "Type",
    "Report Type",
    "Target",
    "D-MeX Qualitative",
    "D-MeX Quantitative",
    "D-Mex Reportable Qualitative",
    "D-Mex Reportable Quantitative",
    "Category",
    "Customer Type",
    "Codes Viewable",
    "Valid From",
    "Valid To",
];

export const SORT_FIELDS = {
    "Code" : "Code",
    "Title": "Title",
    "Definition": "Definition",
    "Valid From": "ValidFrom",
    "Valid To": "ValidTo",
    "Type": "Type",
    "Report Type": "ReportType",
    "Target": "Target",
    "D-MeX Qualitative": "DMeXQualitative",
    "D-MeX Quantitative": "DMeXQuantitative",
    "D-MeX Reportable Qualitative": "DMexReportableQualitative",
    "D-MeX Reportable Quantitative": "DMexReportableQuantitative",
    "Category": "Category",
    "Customer Type": "CustomerType",
    "Codes Viewable": "CodesViewable"
};