import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { setAuthDetails } from '../../actions/authActions';
import { setTemplateTableVisibility } from '../../actions/dataUploadActions';
import { useDropzone } from 'react-dropzone';
import PropTypes from 'prop-types';
import { Card, Button, Form, Icon, Modal } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { Prompt } from 'react-router';
import UploadValidationModal from './UploadValidationModal';
import ConfirmationPage from './ConfirmationPage';
import { ERRORS, WARNINGS, MISC } from '../../constants/dataUploadConsts';
import { downloadTemplate } from '../../utils/dataUploadUtils';
import readXlsxFile from 'read-excel-file';
import './DataUploadMainComponent.scss';

const DataUploadMainComponent = props => {
    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState(null);
    const [uploadStatus, setUploadStatus] = useState({ uploadIsComplete: false, uploadInProgress: false, percentCompleted: 0});
    const [uploadValidationAccepted, setUploadValidationAccepted] = useState(false);
    const [error, setError] = useState({ showError: false, message: '' });
    const [uploadValidationError, setUploadValidationError] = useState({ visible: false, message: "" });
    const [entriesWithErrors, setEntriesWithErrors] = useState({});
    const [openUploadValidationModal, setOpenUploadValidationModal] = useState(false);
    const { current, setAuthDetails, setTemplateTableVisibility, versions } = props;

    useEffect(() => {
        setTemplateTableVisibility(true);
    }, [setTemplateTableVisibility])

    const handleFileRemoval = () => { setEntriesWithErrors(null); setFile(null) };
    const handleTemplateDownload = async() => { 
        await setAuthDetails();
        await downloadTemplate(current?.FileName);
    }

    const onDrop = useCallback(async(selectedFile) => {
        if (selectedFile.length === 0) {
            setError({ showError: true, message: ERRORS.INVALID_FILE });
            handleFileRemoval();
            return;
        } else if (selectedFile.length === 1) {
            if (selectedFile[0].size < MISC.MIN_FILE_SIZE) {
                setError({ showError: true, message: ERRORS.MIN_FILE_SIZE_ERROR});
                return;
            } else if (selectedFile[0].size > MISC.MAX_FILE_SIZE) {
                setError({ showError: true, message: ERRORS.MAX_FILE_SIZE_ERROR});
                return;
            }
            try {
                 await readXlsxFile(selectedFile[0]).then((rows) => {
                    const title = rows[1][0];
                    const version = rows[1][2];
                    const waterCompany = rows[3][1];
                    const reportingPeriod = rows[4][1];

                    let entries = rows.splice(13, rows.length);
                    let entriesWithErrorsArray = entries.filter((row) => {
                        return row[9] !== null;
                    });

                    setEntriesWithErrors({ title, entriesWithErrorsArray, version, waterCompany, reportingPeriod });
                    setOpenUploadValidationModal(true);
                 });
                setFile(selectedFile[0]);
                setError({ visible: true, list: [] });
            } catch (err) {
                setError({ visible: true, list: [err] });
                handleFileRemoval();
            }
        }
      }, []);


    const DataUploadCard = () => {
        const {getRootProps, getInputProps} = useDropzone({
            accept: '.xls, .xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            multiple: false,
            disabled: uploadStatus.uploadInProgress,
            onDrop
        });

        return (
            <>
            <Modal
                className="error-modal"
                centered={false}
                open={error.showError}
                onClose={() => setError({ showError: false, message: ''})}
                header='Error'
                content={<Modal.Content><p>{error.message}</p></Modal.Content>}
                actions={['OK']}
            />
            <UploadValidationModal
                open={openUploadValidationModal}
                setOpen={setOpenUploadValidationModal}
                file={file}
                setFile={setFile}
                entriesWithErrors={entriesWithErrors}
                setEntriesWithErrors={setEntriesWithErrors}
                setUploadValidationAccepted={setUploadValidationAccepted}
                uploadStatus={uploadStatus}
                setUploadStatus={setUploadStatus}
                setFileName={setFileName}
                versions={versions}
                uploadValidationError={uploadValidationError}
                setUploadValidationError={setUploadValidationError}

            />
            <Prompt
                when={file !== null && uploadStatus.uploadIsComplete === false}
                message={WARNINGS.PAGE_LEAVE}
            />
            <Card className="data-upload-card">
                <Card.Content>
                    <Form size="big">
                        <Form.Field>
                            <h2>Data Upload</h2>
                        </Form.Field>
                        
                        <Form.Field> 
                            <div {...getRootProps()} className={`dropzone-input ${uploadStatus.uploadInProgress}`}>
                                <input {...getInputProps()} />
                                <Icon name="upload"></Icon>
                                {file === null ? <p>{MISC.UPLOAD_TEXT}</p> : <p>{file.name}</p>} 
                            </div>
                        </Form.Field>

                        <Form.Group className="data-upload-form">
                            <Form.Field className="data-upload-btn-field">
                            <Button size="large" onClick={handleTemplateDownload}><Icon name="download"></Icon>Download Template</Button>
                            </Form.Field>
                        </Form.Group>

                        <Form.Group className="data-upload-additional-options">
                            <Form.Field className="data-upload-btn-field">
                                <Link to="/resubmission"><Button className="resubmission-btn" size="large">Amend My Data</Button></Link>
                            </Form.Field>
                            <Form.Field className="data-upload-btn-field">
                                <Link to="/data-upload/qualitative-data-upload"><Button size="large">Qualitative Data Upload</Button></Link>
                            </Form.Field>
                        </Form.Group> 
                    </Form>
                </Card.Content>
            </Card>
        </>
        )
    }

    if (uploadValidationAccepted === true) {
        return (
            <ConfirmationPage
                fileName={fileName}
            />
        )
    } else {
        return <DataUploadCard/>
    }
};

DataUploadMainComponent.propTypes = {
    file: PropTypes.object,
    uploadStatus: PropTypes.object,
    error: PropTypes.object,
    handleFileUpload: PropTypes.func.isRequired,
    handleFileRemoval: PropTypes.func.isRequired,
    handleTemplateDownload: PropTypes.func.isRequired,
};

DataUploadMainComponent.defaultProps = {
    file: null,
    uploadIsComplete: {
        uploadIsComplete: false,
        uploadInProgress: false,
        percentCompleted: 0
    },
    error: { showError: false, message: '' },
    handleFileUpload: () => null,
    handleFileRemoval: () => null,
    handleTemplateDownload: () => null,
};

const mapStateToProps = (state) => {
    const user = state.accountReducer.Account.User;
    return {
        user,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        setTemplateTableVisibility: (bool) => dispatch(setTemplateTableVisibility(bool)),
        setAuthDetails: () => dispatch(setAuthDetails())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DataUploadMainComponent);