import { SET_TEMPLATE_TABLE_VISIBILITY } from '../actions/dataUploadActions';

const initialState = {
  templateTableIsVisible: true,
};

const dataUploadReducer = (state = initialState, action) => {
    switch (action.type) {
      case SET_TEMPLATE_TABLE_VISIBILITY:
        return { ...state, templateTableIsVisible: action.payload };
      default:
        return state;
    };
};

export default dataUploadReducer;