import {
    GET_POWER_BI_PUBLIC_CONFIG, GET_POWER_BI_PUBLIC_CONFIG_ERROR,
    SET_POWER_BI_AUTHENTICATED_CONFIG, SET_POWER_BI_AUTHENTICATED_CONFIG_ERROR,
    RESET_NUM_OF_ATTEMPTS
} from '../actions/powerBIActions';

const initialState = {
    powerBiMainDashboardConfig: null,
    powerBiAuditLogConfig: null,
    authenticated: false,
    numOfAttempts: 0,
    error: null,
}

const powerBiReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_POWER_BI_PUBLIC_CONFIG:
            return { ...state, powerBiMainDashboardConfig: action.payload.powerBiMainDashboardConfig, numOfAttempts: action.payload.numOfAttempts }
        case GET_POWER_BI_PUBLIC_CONFIG_ERROR:
            return { ...state, error: action.payload.error, numOfAttempts: action.payload.numOfAttempts}
        case SET_POWER_BI_AUTHENTICATED_CONFIG:
            return { ...state, powerBiMainDashboardConfig: action.payload.powerBiMainDashboardConfig, 
                powerBiAuditLogConfig: action.payload.powerBiAuditLogConfig, authenticated: true, numOfAttempts: action.payload.numOfAttempts }
        case SET_POWER_BI_AUTHENTICATED_CONFIG_ERROR:
            return { ...state, error: action.payload.error, numOfAttempts: action.payload.numOfAttempts }
        case RESET_NUM_OF_ATTEMPTS:
            return { ...state, numOfAttempts: action.payload }
        default: return state;
    };
};

export default powerBiReducer;