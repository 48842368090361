import React from 'react'
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';

const BaseLayout = props => {
  return (
    <>
      <Header/>
        <main className='main-content'>{props.children}</main>
      <Footer/>
    </>
  );
}; 

export default BaseLayout;