import moment from 'moment';

export const customTableEditHandleOnChange = (e, data, editableRow, cellName, rIndex, fieldType) => {
    let items = [...editableRow];
    let item = {...editableRow[rIndex]};

    switch(fieldType) {
        case "checkbox": item[`${cellName}`] = data.checked === true ? "Yes" : "No"; break;
        case "dropdown":
        case "dropdown-required": item[`${cellName}`] = data.value; break;
        case "number": item[`${cellName}`] = data.value; break;
        case "number-min-max": data.value >= data.min && data.value <= data.max ? item[`${cellName}`] = data.value : e.currentTarget.value = data.defaultValue; break; 
        case "date-input":
        case "date-input-required": item[`${cellName}`] = data.value; break;
        default: item[`${cellName}`] = e.currentTarget.value; break;
    }

    items[rIndex] = item;
    return items;
};

export const customTableAddHandleOnChange = (e, data, editableRow, cellName, fieldType) => {
    let item = {...editableRow};

    switch(fieldType) {
        case "checkbox": item[`${cellName}`] = data.checked === true ? "Yes" : "No"; break;
        case "dropdown":
        case "dropdown-required": item[`${cellName}`] = data.value; break;
        case "number": item[`${cellName}`] = data.value; break;
        case "number-min-max": data.value >= data.min && data.value <= data.max ? item[`${cellName}`] = data.value : e.currentTarget.value = data.defaultValue; break; 
        case "date-input":
        case "date-input-required": item[`${cellName}`] = data.value; break;
        default: item[`${cellName}`] = e.currentTarget.value; break;
    }

    return item;
};

export const checkForEditFieldErrors = (editableRow, index, fieldsEdit, headers) => {
    let fieldErrors = [];

    Object.entries(editableRow[index]).forEach((obj, objIndex) => {

        if (obj[0] === "ReactKey") {
            return;
        }

        if (fieldsEdit[obj[0]] === "date-input-required" || fieldsEdit[obj[0]] === "date-input") {         
                
            // Date validation
            if (!moment(obj[1], 'DD/MM/YYYY', true).isValid()) {
                fieldErrors.push(`${headers[objIndex]} has an invalid date.`)
            }

        } else {
            if ((fieldsEdit[obj[0]].includes("-required")) && (obj[1] === null || obj[1] === "")) {
                fieldErrors.push(`${headers[objIndex]} requires a value.`);
            }
         } 
    });

    return fieldErrors;
};


export const checkForAddFieldErrors = (editableRow, fieldsAdd, headers) => {
    let fieldErrors = [];

    Object.entries(editableRow).forEach((obj, index) => {

        if (obj[0] === "ReactKey") {
            return;
        }

        if (fieldsAdd[obj[0]] === "date-input-required" || fieldsAdd[obj[0]] === "date-input") {         
                
            // Date validation
            if (!moment(obj[1], 'DD/MM/YYYY', true).isValid()) {
                fieldErrors.push(`${headers[index]} has an invalid date.`)
            }

        } else {
            if ((fieldsAdd[obj[0]].includes("-required")) && (obj[1] === null || obj[1] === "")) {
                fieldErrors.push(`${headers[index]} requires a value.`);
            }
        } 
    });

    return fieldErrors;
};

export const searchFilter = (tableRows, value) => {
    return tableRows.filter(item => {
        return Object.keys(item).some(key => {
            if (item[key] !== null) {
                return item[key].toString().toLowerCase().trim().includes(value.toLowerCase().trim());
            } else {
                return null;
            }
        });
    });
};

export const updateSearchEditData = (searchState, editableRow) => {
    const updatedSearchData = [...searchState];
    const index = searchState.findIndex(row => row.ReactKey === editableRow.ReactKey);
    updatedSearchData[index] = editableRow;
    return updatedSearchData
};

export const updateSearchAddData = (searchState, editableRow, index) => {
    const newSearchState = [...searchState];
    newSearchState.push({ ReactKey: index, ...editableRow });
    return newSearchState;
};

export const getDefaultSortDirection = (fieldHeaders, defaultSelection = "") => {
    const headers = fieldHeaders.reduce((ac,a) => ({...ac,[a.replace(/\s/g,'')]:'desc'}),{});
    return { ...headers, lastClicked: defaultSelection };
};

export const sortByDate = (data, header, direction) => {
    if (direction === "asc") {
        return data.sort(function(a, b){
            const aa = a[header].split('/').reverse().join(),
            bb = b[header].split('/').reverse().join();
            return aa < bb ? -1 : (aa > bb ? 1 : 0);
        });
    } else {
        return data.sort(function(a, b){
            const aa = a[header].split('/').reverse().join(),
            bb = b[header].split('/').reverse().join();
            return aa < bb ? -1 : (aa > bb ? 1 : 0);
        }).reverse();
    }
}

export const handleTableSort = (header, uiHeader, direction, tableRows, searchState, defaultSortDirection, setSortDirection, setSearchState, setTableRows) => {
    const tableRowsCopy = [...tableRows];
    const searchStateCopy = [...searchState];
    let sortedData = [];
    let newSearchState = [];

    try {
        if (direction === "asc") {
            if (moment(tableRowsCopy[0][header], 'DD/MM/YYYY', true).isValid()) {
                sortedData = sortByDate(tableRowsCopy, header, "asc");
                newSearchState = sortByDate(searchStateCopy, header, "asc");
            } else if (parseInt(tableRowsCopy[0][header]) || tableRowsCopy[0][header] === null) {
                sortedData = tableRowsCopy.sort((a, b) => a[header] - b[header]);
                newSearchState = searchStateCopy.sort((a, b) => a[header] - b[header]);
            } else {
                sortedData = tableRowsCopy.sort((a, b) => a[header]?.toString().localeCompare(b[header]?.toString()));
                newSearchState = searchStateCopy.sort((a, b) => a[header]?.toString().localeCompare(b[header]?.toString()));
            }

        } else if (direction === "desc") {
            if (moment(tableRowsCopy[0][header], 'DD/MM/YYYY', true).isValid()) {
                sortedData = sortByDate(tableRowsCopy, header, "desc");
                newSearchState = sortByDate(searchStateCopy, header, "desc");
            } else if (parseInt(tableRowsCopy[0][header]) || tableRowsCopy[0][header] === null ) {
                sortedData = tableRowsCopy.sort((a, b) => b[header] - a[header]);
                newSearchState = searchStateCopy.sort((a, b) => b[header] - a[header]);
            } else {
                sortedData = tableRowsCopy.sort((a, b) => b[header]?.toString().localeCompare(a[header]?.toString()));
                newSearchState = searchStateCopy.sort((a, b) => b[header]?.toString().localeCompare(a[header]?.toString()));
            }
        }
        else {
            sortedData = tableRowsCopy;
            newSearchState = searchStateCopy;
        }

        const newDirection = direction === "asc" ? "desc" : "asc";
        setSortDirection({...defaultSortDirection, [header]: newDirection, lastClicked: uiHeader});
        setSearchState(newSearchState);
        setTableRows(sortedData)
    } catch (err) {
        console.log(err);
    }
};

export const getSortIconDirection = (direction) => {
    return direction === "asc" ? "arrow down" : "arrow up"; 
};
