import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Table, Pagination, Search, Icon } from 'semantic-ui-react';
import ReviewTableEditModal from './ReviewTableEditModal';
import ResponseMessage from '../CustomTable/ResponseMessage/ResponseMessage';
import { getDefaultSortDirection, handleTableSort, getSortIconDirection, searchFilter } from '../../utils/tableUtils';
import { getHistoricAvgPercent, getMonthPercent, validateComplianceVariesBy10Percent, validateVolumesVaryByOver30Percent, validateInTargetMoreThanTotal } from '../../utils/reviewTableUtils';
import '../CustomTable/CustomTable.scss';

const ReviewTable = props => {
    const { headers, sortFields, fieldHeaders, setInTargetError, tableRows, setTableRows } = props;
    const [openEditModal, setOpenEditModal] = useState({ visible: false, index: null});
    const [message, setMessage] = useState({ visible: false, positive: false, negative: false, header: "", text: "" });
    const [searchState, setSearchState] = useState(tableRows);
    const paginationInitialState = { activePage: 1, rowStart: 0, rowEnd: 20 };
    const [pagination, setPagination] = useState(paginationInitialState);
    const [sortDirection, setSortDirection] = useState(getDefaultSortDirection(fieldHeaders));

    const handleSearchChange = (e, { value }) => {
        if (pagination !== paginationInitialState) {
            setPagination(paginationInitialState);
        }
        const filteredData = searchFilter(tableRows, value);
        setSearchState(filteredData);
    }

    const handlePaginationChange = (e, { activePage }) => {
        const rowStart = (activePage - 1) * paginationInitialState.rowEnd;
        const rowEnd = activePage * paginationInitialState.rowEnd;
        setPagination({ activePage, rowStart, rowEnd });
    }

    const tableHeaders = headers.map((header, index) => {
        return (
        <Table.HeaderCell 
            key={index}
            className="table-header"
            textAlign="center"
            onClick={() => handleTableSort(sortFields[header], header, sortDirection[sortFields[header]], tableRows, searchState, getDefaultSortDirection(fieldHeaders), setSortDirection, setSearchState, setTableRows)}
            content={
            <>
                {header} 
                {header === sortDirection.lastClicked ? <Icon className="table-sort-icon" size="small" name={getSortIconDirection(sortDirection[sortFields[header]])}></Icon> : <></>}
            </>
            }>
        </Table.HeaderCell>
        );
    });

    const TableRow = ({ rIndex, row }) => {
        return (
            <Table.Row key={rIndex}>
                <Table.Cell textAlign="center" className="table-action-buttons" collapsing>
                    <ReviewTableEditModal
                        tableName={props.tableName}
                        open={openEditModal.visible}
                        tableRows={tableRows}
                        setTableRows={setTableRows}
                        apiUrl={props.apiUrl}
                        headers={props.headers}
                        fieldsEdit={props.fieldsEdit}
                        fieldsAdd={props.fieldsAdd}
                        dropdowns={props.dropdowns}
                        index={tableRows.findIndex(x => x.ReactKey === row.ReactKey)}
                        openEditModal={openEditModal}
                        setOpenEditModal={setOpenEditModal}
                        setMessage={setMessage}
                        searchState={searchState}
                        setSearchState={setSearchState}
                        fileName={props.fileName}
                        setInTargetError={setInTargetError}
                    />
                </Table.Cell>
                <Table.Cell textAlign="center">{row.Metric}</Table.Cell>
                <Table.Cell textAlign="center">{row.HistoricAvgInTarget}</Table.Cell>
                <Table.Cell textAlign="center">{row.HistoricAvgTotal}</Table.Cell>
                <Table.Cell textAlign="center">{getHistoricAvgPercent(row.HistoricAvgInTarget, row.HistoricAvgTotal)}</Table.Cell>
                <Table.Cell textAlign="center">{row.ThisMonthInTarget}</Table.Cell>
                <Table.Cell textAlign="center">{row.ThisMonthTotal}</Table.Cell>
                <Table.Cell textAlign="center">{getMonthPercent(row.ThisMonthInTarget, row.ThisMonthTotal)}</Table.Cell>
                <Table.Cell textAlign="center">
                    {validateComplianceVariesBy10Percent(getHistoricAvgPercent(row.HistoricAvgInTarget, row.HistoricAvgTotal), getMonthPercent(row.ThisMonthInTarget, row.ThisMonthTotal))}
                </Table.Cell>
                <Table.Cell textAlign="center">
                    {validateVolumesVaryByOver30Percent(row.historicAvgInTarget, row.ThisMonthInTarget, row.HistoricAvgTotal, row.ThisMonthTotal)}
                    </Table.Cell>
                <Table.Cell textAlign="center">
                    {validateInTargetMoreThanTotal(row.ThisMonthInTarget, row.ThisMonthTotal)}
                </Table.Cell>
            </Table.Row>
        )
    }

    const Body = () => {
        if (searchState.length === 0) {
            return <><Table.Row><Table.Cell colSpan='16'><h1 className="table-no-results">No results found.</h1></Table.Cell></Table.Row></>
        } else if (searchState.length > 0) {
            return searchState.slice(pagination.rowStart, pagination.rowEnd).map((row, rIndex) => {
                return <TableRow key={rIndex} rIndex={rIndex} row={row}/>
            });
        } else {
            return tableRows.slice(pagination.rowStart, pagination.rowEnd).map((row, rIndex) => {
                return <TableRow key={rIndex} rIndex={rIndex} row={row}/>
            });
        }
    };

    const Footer = () => {
        return (
            <Table.Row>
                <Table.HeaderCell colSpan='16'>
                    <Pagination
                        defaultActivePage={pagination.activePage}
                        onPageChange={handlePaginationChange}
                        totalPages={searchState.length === 0 ? 0 : searchState.length > 0 ? Math.ceil(searchState.length / paginationInitialState.rowEnd) : Math.ceil(tableRows.length / paginationInitialState.rowEnd)}
                        ellipsisItem={null}
                        disabled={searchState.length === 0 ? true : searchState.length > 0 ? searchState.length < paginationInitialState.rowEnd : tableRows.length < paginationInitialState.rowEnd}
                    />
                </Table.HeaderCell>
            </Table.Row>
        )
    }

    const renderTable = () => {
        return tableRows.length > 0
        ?
            <div className="custom-table-container">
                {message.visible ? <ResponseMessage {...message} setMessage={setMessage}/> : <></>}
                <Search className="table-search" placeholder="Search" onSearchChange={handleSearchChange} open={false}></Search>
                <Table striped celled compact>
                    <Table.Header>
                        <Table.Row>
                            {props.editable ? <Table.HeaderCell singleLine textAlign="center">Action</Table.HeaderCell> : <></>}
                            {tableHeaders}
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        <Body/>
                    </Table.Body>
                    <Table.Footer>
                        <Footer/>
                    </Table.Footer>
                </Table>
            </div>
        :
        <h2 className="no-table-data">No table data.</h2>
    };

    return renderTable();
}

ReviewTable.propTypes = {
    tableName: PropTypes.string,
    headers: PropTypes.array,
    body: PropTypes.array,
    fieldsEdit: PropTypes.object,
    fieldsAdd: PropTypes.object,
    dropdowns: PropTypes.object,
    numbers: PropTypes.object,
    apiUrl: PropTypes.string,
    editable: PropTypes.bool,
    rowDeletion: PropTypes.bool,
};

ReviewTable.defaultProps = {
    tableName: "table-" + new Date().getTime(),
    headers: [],
    body: [],
    fieldsEdit: {},
    fieldsAdd: {},
    dropdowns: {},
    apiUrl: null,
    editable: false,
    rowDeletion: false,
};

export default ReviewTable;