import React, { useState, useEffect, useCallback } from 'react';
import { Loader } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { setAuthDetails } from '../../actions/authActions';
import { getAccountDetails } from '../../actions/accountActions';
import DataUploadDisabled from '../../components/dataUploadMainComponent/DataUploadDisabled';
import DataUploadMainComponent from '../../components/dataUploadMainComponent/DataUploadMainComponent';
import TemplatesTable from '../../components/TemplatesTable/TemplatesTable';
import axios from 'axios';
import { USER_ROLE } from '../../constants/userConsts';
import { HEADERS, FIELDS_EDIT, FIELDS_ADD, INITIAL_STATE, FIELD_HEADERS, SORT_FIELDS } from '../../components/TemplatesTable/TemplatesTableSchema';
import { logout } from '../../auth/authRedirect';
import './DataUpload.scss';

const DataUpload = props => {
    const [data, setData] = useState(null);
    const [error, setError] = useState({ visible: false, message: "" });
    const [globalMessage, setGlobalMessage] = useState({ visible: false, positive: false, negative: false, header: "", text: "" });
    const { setAuthDetails, dataUploadIsAvailable, jwtIdToken, userRole, exp } = props;
    
    const fetchData = useCallback(async() => {
        await setAuthDetails();
        try {
            setData(null);
            const { data } = await axios.post(process.env.REACT_APP_TEMPLATE_URL, {},
                { headers: { Authorization: `Bearer ${jwtIdToken}`, "Ocp-Apim-Subscription-Key": process.env.REACT_APP_OCP_SUBSCRIPTION_KEY }});
            const rowsWithKeys = Object.values([...data.Rows]).map((row, index) => { return { ReactKey: index, ...row } });
            data["Rows"] = rowsWithKeys;
            setData(data);
        } catch (error) {
            if (new Date().getTime() >= exp * 1000) {
                logout();
            } else {
                console.error(error);
                setError({visible: true, message: `Unable to connect to Template API.\n ${error.response.data.ErrorMessage}`});
            }
        }
      }, [setAuthDetails, jwtIdToken, exp]);

    useEffect(() => {
        if (userRole !== USER_ROLE.USER) {
            fetchData();
        }
    }, [fetchData, userRole, dataUploadIsAvailable]);

    const ShowLoading = () => {
        return error.visible === false ?
        <Loader active inline='centered'>Retrieving Templates</Loader> : error.message.split("\n").map((line, i) => <h1 key={i} className="error">{line}</h1>);
    }

    const ShowDataUploadCard = props => {
        return (
            <div className="data-upload-container">
                <DataUploadMainComponent {...props}/>
            </div>
        );
    }

    const ShowAccessDeniedMessage = () => {
        return (
            <div className="data-upload-container">
                <h1 className="error">Access Denied</h1>
            </div>
        );
    }

    if (userRole === USER_ROLE.USER) {
        return <ShowAccessDeniedMessage/>
    } else if (data === null) {
        return <ShowLoading/>
    } else if (dataUploadIsAvailable === "No" && userRole !== USER_ROLE.SUPER_ADMIN) {
        return (
        <>
            <DataUploadDisabled 
                current={data.Rows.filter(r => r.Current === "Yes")[0]}
            />
            <TemplatesTable
                tableName="templates-table"
                apiUrl={process.env.REACT_APP_TEMPLATE_URL}
                headers={HEADERS}
                rows={data.Rows}
                fieldHeaders={FIELD_HEADERS}
                fieldsAdd={FIELDS_ADD}
                fieldsEdit={FIELDS_EDIT}
                initialFields={INITIAL_STATE}
                sortFields={SORT_FIELDS}
                editable={false}
                fetchData={fetchData}
                globalMessage={globalMessage}
                setGlobalMessage={setGlobalMessage}
            />
        </>
        );
    } else if (dataUploadIsAvailable === "Yes" && userRole === USER_ROLE.WATER_COMPANY_ADMIN) {
        return (
            <>
                <ShowDataUploadCard
                    versions={data.Rows.map(r => r.TemplateVersion)}
                    current={data.Rows.filter(r => r.Current === "Yes")[0]}
                />
                <TemplatesTable
                    tableName="templates-table"
                    apiUrl={process.env.REACT_APP_TEMPLATE_URL}
                    headers={HEADERS}
                    rows={data.Rows}
                    fieldHeaders={FIELD_HEADERS}
                    fieldsAdd={FIELDS_ADD}
                    fieldsEdit={FIELDS_EDIT}
                    initialFields={INITIAL_STATE}
                    sortFields={SORT_FIELDS}
                    editable={false}
                    fetchData={fetchData}
                    globalMessage={globalMessage}
                    setGlobalMessage={setGlobalMessage}
                />
            </>
        )
    } else if (userRole === USER_ROLE.SUPER_ADMIN) {
        return (
            <>
            <ShowDataUploadCard
                versions={data.Rows.map(r => r.TemplateVersion)}
                current={data.Rows.filter(r => r.Current === "Yes")[0]}
            />
            <TemplatesTable
                tableName="templates-table"
                apiUrl={process.env.REACT_APP_TEMPLATE_URL}
                headers={HEADERS}
                rows={data.Rows}
                fieldHeaders={FIELD_HEADERS}
                fieldsAdd={FIELDS_ADD}
                fieldsEdit={FIELDS_EDIT}
                initialFields={INITIAL_STATE}
                sortFields={SORT_FIELDS}
                editable={true}
                fetchData={fetchData}
                globalMessage={globalMessage}
                setGlobalMessage={setGlobalMessage}
            />
        </>
        )
    }
};

const mapStateToProps = (state) => {
    const userRole = state.authReducer.authResponse.idTokenClaims.extension_UserRole;
    const dataUploadIsAvailable = state.accountReducer.Account.User.UploadPageAvailable;
    const jwtIdToken = state.authReducer.authResponse.jwtIdToken;
    const exp = state.authReducer.authResponse.idTokenClaims.exp;
    return {
        userRole,
        dataUploadIsAvailable,
        jwtIdToken,
        exp,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        setAuthDetails: () => dispatch(setAuthDetails()),
        getAccountDetails: (jwtIdToken) => dispatch(getAccountDetails(jwtIdToken)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DataUpload);