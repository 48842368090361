import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import PowerBi from '../../components/powerBi/PowerBiMainDashboard/PowerBiMainDashboard';
import { Loader, Button } from 'semantic-ui-react';
import { IENotice } from '../../constants/generalConsts';
import { getPowerBiPublicConfig, setPowerBiAuthenticatedConfig, resetNumOfAttempts } from '../../actions/powerBIActions';
import './Dashboard.scss';

const Dashboard = props => {
    const { powerBiMainDashboardConfig, account, authenticated, jwtIdToken, getPowerBiPublicConfig, setPowerBiAuthenticatedConfig, resetNumOfAttempts, numOfAttempts, error } = props;
    const [errorMessage, setErrorMessage] = useState({ visible: false, message: "" });

    const publicDashboardRequest = useCallback(async() => {
        if (numOfAttempts === 0) {
            getPowerBiPublicConfig();
        } else if (powerBiMainDashboardConfig === null && account === null && numOfAttempts > 0 && numOfAttempts < 3) {
            const timeout = setTimeout(() => {
                getPowerBiPublicConfig(); 
                clearTimeout(timeout); 
            }, [10000]);
        }
      }, [getPowerBiPublicConfig, account, powerBiMainDashboardConfig, numOfAttempts]);

      const privateDashboardRequest = useCallback(async() => {
        if (numOfAttempts === 0) {
            setPowerBiAuthenticatedConfig(jwtIdToken, account.User.rID, account.User.wID);
        } else if (numOfAttempts > 0 && numOfAttempts < 3) {
            const timeout = setTimeout(() => {
                setPowerBiAuthenticatedConfig(jwtIdToken, account.User.rID, account.User.wID);
                clearTimeout(timeout);
            }, [10000]);
        }
      }, [setPowerBiAuthenticatedConfig, jwtIdToken, account, numOfAttempts]);

    useEffect(() => {

        if (account === null && powerBiMainDashboardConfig === null) {
            publicDashboardRequest();
        }

        if (account !== null && authenticated === false && powerBiMainDashboardConfig === null) {
            privateDashboardRequest();
        }

    }, [publicDashboardRequest, privateDashboardRequest, powerBiMainDashboardConfig, getPowerBiPublicConfig, setPowerBiAuthenticatedConfig, numOfAttempts, account, authenticated, jwtIdToken]);


    if (powerBiMainDashboardConfig === null && numOfAttempts === 0) {
        return <Loader active inline='centered'>Loading Dashboard</Loader>
    }
    
    const handleRetry = async() => {
        await resetNumOfAttempts(2);
        if (account === null && powerBiMainDashboardConfig === null) {
            publicDashboardRequest();
        } else if (account !== null && authenticated === false && powerBiMainDashboardConfig === null) {
            privateDashboardRequest()
        }
    };

    const ErrorMessage = () => {
        return errorMessage.visible === true ? error.message.split("\n").map((line, i) => <h1 key={i} className="error">{line}</h1>) : <></>;
    }

    if (numOfAttempts >= 3) {
        return (
            <div className="power-bi-dashboard-error-container">
                <h1 className="error">Unable to connect to Dashboard.</h1>
                {error ? <h1 className="error">{error}</h1> : <></>}
                <IENotice/>
                <Button className="retry-btn" onClick={handleRetry} size="large">Retry</Button>
            </div>
        );
    } else {
        return (
            <>
                <ErrorMessage/>
                <PowerBi setErrorMessag={setErrorMessage}/>
            </>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    const powerBiMainDashboardConfig = state.powerBiReducer.powerBiMainDashboardConfig;
    const authenticated = state.powerBiReducer.authenticated;
    const account = state.accountReducer.Account;
    const jwtIdToken = state.authReducer.authResponse.jwtIdToken;
    const numOfAttempts = state.powerBiReducer.numOfAttempts;
    const error = state.powerBiReducer.error;
    return {
        powerBiMainDashboardConfig,
        authenticated,
        account,
        jwtIdToken,
        numOfAttempts,
        error,
        ownProps
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getPowerBiPublicConfig: () => dispatch(getPowerBiPublicConfig()),
        setPowerBiAuthenticatedConfig: (jwtIdToken, rID, wID) => dispatch(setPowerBiAuthenticatedConfig(jwtIdToken, rID, wID)),
        resetNumOfAttempts: (num) => dispatch(resetNumOfAttempts(num))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
