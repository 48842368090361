import React, { useState } from 'react'
import { Button, Popup, Form, Modal, Grid, Message, Input, Dropdown } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { DateInput } from 'semantic-ui-calendar-react';
import ReportingPeriodsCompaniesEditModal from './ReportingPeriodsCompaniesEditModal';
import { customTableEditHandleOnChange, checkForEditFieldErrors, updateSearchEditData } from '../../utils/tableUtils';
import moment from 'moment';
import { FIELD_HEADERS } from './ReportingPeriodsTableSchema';
import { logout } from '../../auth/authRedirect';
import '../CustomTable/EditModal/EditModal.scss';

const ReportingPeriodsTableModal = (props) => {
    const { tableRows, setTableRows, index, apiUrl, openEditModal, setOpenEditModal, fieldsEdit, setMessage, searchState, setSearchState } = props;
    const [editableRow, setEditableRow] = useState(tableRows);
    const [open, setOpen] = useState(props.open);
    const [error, setError] = useState({ visible: false, list: [] });
    const [submittingChanges, setSubmittingChanges] = useState(false);
    const [companyModalIsOpen, setCompanyModalIsOpen] = useState(false);
    const jwtIdToken = useSelector(state => state.authReducer.authResponse.jwtIdToken);
    const exp = useSelector(state => state.authReducer.authResponse.idTokenClaims.exp);

    const openModal = () => setOpen(true);
    const closeModal = () => { setOpen(false); setError({ visible: false, list: [] }); }
    const closeModalCancel = () => {
        setError({ visible: false, list: [] });
        if (openEditModal.visible) {
            const tr = [...tableRows];
            const previousTableRows = tr.splice(0, tr.length - 1);
            setTableRows(previousTableRows);
            setOpenEditModal({ visible: false, index: null });
        } else {
            setEditableRow(tableRows);
            setOpen(false);
        }
    };

    const getPeriod = (periodType, periodStart) => {
        switch(periodType) {
            case "Month": return moment(periodStart).endOf("month").format("DD/MM/YYYY");
            case "Quarter": return moment(periodStart).endOf("quarter").format("DD/MM/YYYY");
            case "Annual": return moment(periodStart).endOf("year").format("DD/MM/YYYY");
            default: return "";
        }
    }

    const handleOnChange = (e, data, cellName, rIndex, fieldType) => {
        if (cellName === "PeriodType") {
            let items = [...editableRow];
            let item = {...editableRow[rIndex]};
            
            item['PeriodType'] = data.value;
            item['PeriodStart'] = "";
            item['PeriodEnd'] = "";
            item['Deadline'] = "";
            
            items[rIndex] = item;
            setEditableRow(items);

        } else if (cellName === "PeriodStart" && fieldType === "date-input") {

            let items = [...editableRow];
            let item = {...editableRow[rIndex]};

            item['PeriodStart'] = data.value;
            item['PeriodEnd'] = getPeriod(item['PeriodType'], moment(data.value, "DD/MM/YYYY"));
            item['Deadline'] = moment(item['PeriodEnd'], "DD-MM-YYYY").set("date", 21).add(1, 'months').format("DD/MM/YYYY");

            items[rIndex] = item;
            setEditableRow(items);
        } else {
            setEditableRow(customTableEditHandleOnChange(e, data, editableRow, cellName, rIndex, fieldType));
        }
    };

    const handleSubmit = async() => {
        setSubmittingChanges(true);
        const fieldErrors = checkForEditFieldErrors(editableRow, index, fieldsEdit, FIELD_HEADERS);

        if (fieldErrors.length > 0) {
            setError({visible: true, list: fieldErrors});
            setSubmittingChanges(false);
            return;
        } else {
            setError({visible: false, list: []});
        }

        try {
            await axios.put(apiUrl, { ...editableRow[index] }, 
                { headers: { Authorization: `Bearer ${jwtIdToken}`, "Ocp-Apim-Subscription-Key": process.env.REACT_APP_OCP_SUBSCRIPTION_KEY }});
            setMessage({ visible: true, positive: true, negative: false, header: "Changes saved", text: "Your changes have been successfully updated." });
            setTableRows(editableRow);
            if (searchState !== undefined && searchState !== "") {
                setSearchState(updateSearchEditData(searchState, editableRow[index]));
            }
            closeModal();
        } catch (error) {
            if (new Date().getTime() >= exp * 1000) {
                logout();
            } else {
                console.error(error);
                setMessage({ visible: true, positive: false, negative: true, header: `Unable to save changes.`, text: error.response.data.ErrorMessage });
                closeModalCancel();
            }
        } finally {
            setSubmittingChanges(false);
        }
    }

    const modalRow = () => {
        return  (
                <React.Fragment>
                    <Grid>

                        {/* Companies */}
                        <Grid.Column width={6} className="label-column">
                            <label className="edit-modal-label">Companies:</label>
                        </Grid.Column>
                        <Grid.Column className="modal-field" width={10}>
                            <ReportingPeriodsCompaniesEditModal
                                editableRow={editableRow}
                                setEditableRow={setEditableRow}
                                index={index}
                                companyModalIsOpen={companyModalIsOpen}
                                setCompanyModalIsOpen={setCompanyModalIsOpen}
                            />
                        </Grid.Column>

                        {/* Period Type */}
                        <Grid.Column width={6} className="label-column">
                            <label className="edit-modal-label">Period Type:</label>
                        </Grid.Column>
                        <Grid.Column className="dropdown-field" width={10}>
                            <Form.Input>
                            <Dropdown onChange={(e, data) =>  handleOnChange(e, data, "PeriodType", props.index, "dropdown")}
                                placeholder={editableRow[index]["PeriodType"]} selection fluid scrolling={true} selectOnBlur={false} defaultValue={editableRow[index]["PeriodType"]}
                                options={props.dropdowns["PeriodType"].map((dropdown, index) => { return { key: index, value: dropdown, text: dropdown }})} required/>
                            </Form.Input>
                        </Grid.Column>

                        {/* Period Start */}
                        <Grid.Column width={6} className="label-column">
                            <label className="edit-modal-label">Period Start:</label>
                        </Grid.Column>
                        <Grid.Column className="date-input-field" width={10}>
                            <Form.Input>
                                <DateInput onChange={(e, data) => handleOnChange(e, data, "PeriodStart", props.index, "date-input")} closable={true} closeOnMouseLeave={false}
                                    placeholder="DD/MM/YYYY" popupPosition="bottom center" animation='none' dateFormat="DD/MM/YYYY" maxLength={10}
                                    value={editableRow[index] !== undefined ?editableRow[index]["PeriodStart"] : ""} required/>
                            </Form.Input>
                        </Grid.Column>

                        {/* Period End */}
                        <Grid.Column width={6} className="label-column">
                            <label className="edit-modal-label">Period End:</label>
                        </Grid.Column>
                        <Grid.Column className="date-input-field" width={10}>
                            <Form.Input>
                                <DateInput onChange={(e, data) => handleOnChange(e, data, "PeriodEnd", props.index, "date-input")} closable={true} closeOnMouseLeave={false}
                                    placeholder="DD/MM/YYYY" popupPosition="bottom center" animation='none' dateFormat="DD/MM/YYYY" maxLength={10}
                                    value={editableRow[index] !== undefined ? editableRow[index]["PeriodEnd"] : ""} required/>
                            </Form.Input>
                        </Grid.Column>

                        {/* Deadline */}
                        <Grid.Column width={6} className="label-column">
                            <label className="edit-modal-label">Deadline:</label>
                        </Grid.Column>
                        <Grid.Column className="date-input-field" width={10}>
                            <Form.Input>
                                <DateInput onChange={(e, data) => handleOnChange(e, data, "Deadline", props.index, "date-input")} closable={true} closeOnMouseLeave={false}
                                    placeholder="DD/MM/YYYY" popupPosition="bottom center" animation='none' dateFormat="DD/MM/YYYY" maxLength={10}
                                    value={editableRow[index] !== undefined ? editableRow[index]["Deadline"] : ""} required/>
                            </Form.Input>
                        </Grid.Column>

                        {/* Initial Email Reminder */}
                        <Grid.Column width={6} className="label-column">
                            <label className="edit-modal-label">Initial Email Reminder (days):</label>
                        </Grid.Column>
                        <Grid.Column className="number-field" width={10}>
                            <Form.Input>
                                <Input onChange={(e, data) => handleOnChange(e, data, "InitialEmailReminder", props.index, "number")} min={0}
                                    type="number" defaultValue={editableRow[index]["InitialEmailReminder"]} required/>
                            </Form.Input>
                        </Grid.Column>

                        {/* Second Email Reminder */}
                        <Grid.Column width={6} className="label-column">
                            <label className="edit-modal-label">Second Email Reminder (days):</label>
                        </Grid.Column>
                        <Grid.Column className="number-field" width={10}>
                            <Form.Input>
                                <Input onChange={(e, data) => handleOnChange(e, data, "SecondEmailReminder", props.index, "number")} min={0}
                                    type="number" defaultValue={editableRow[index]["SecondEmailReminder"]} required/>
                            </Form.Input>
                        </Grid.Column>

                        {/* Third Email Reminder */}
                        <Grid.Column width={6} className="label-column">
                            <label className="edit-modal-label">Third Email Reminder (days):</label>
                        </Grid.Column>
                        <Grid.Column className="number-field" width={10}>
                            <Form.Input>
                                <Input onChange={(e, data) => handleOnChange(e, data, "ThirdEmailReminder", props.index, "number")} min={0}
                                    type="number" defaultValue={editableRow[index]["ThirdEmailReminder"]} required/>
                            </Form.Input>
                        </Grid.Column>
                    </Grid>
                </React.Fragment>
        );
    }

    return (
    <>
        <Popup content="Edit row" trigger={<Button onClick={openModal} icon="edit"></Button>}/>
        <Modal
            className="edit-modal"
            as={Form}
            onClose={void(0)}
            onOpen={openModal}
            open={open}
            onSubmit={handleSubmit}
            >
            <Modal.Header>Edit row</Modal.Header>
            <Modal.Content className="edit-modal-content" scrolling>
               {modalRow()}
            </Modal.Content>
            <Modal.Actions>
                {error.visible ? 
                    <Message
                        className="edit-modal-error-message"
                        negative
                        header="Error"
                        list={error.list}
                    ></Message>
                    :
                    <></>
                }
                <Button type="button" onClick={closeModalCancel} content="Cancel"/>
                <Button disabled={submittingChanges === true} 
                    loading={submittingChanges} type="submit" icon="save" content="Save changes" color="green"/>
            </Modal.Actions>
        </Modal>
    </>
    );
}

ReportingPeriodsTableModal.propTypes = {
    tableName: PropTypes.string,
    tableRows: PropTypes.array,
    setTableRows: PropTypes.func,
    editableRow: PropTypes.array,
    index: PropTypes.number,
    apiUrl: PropTypes.string
}

ReportingPeriodsTableModal.defaultProps = {
    apiUrl: null
};

export default ReportingPeriodsTableModal;
