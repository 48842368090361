import React, { useState } from 'react'
import { Button, Popup, Form, Modal, Grid, Message, Input, Dropdown } from 'semantic-ui-react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { getChangedAddValues } from '../../utils/qualitativeDataUploadUtils';
import { customTableAddHandleOnChange, checkForAddFieldErrors } from '../../utils/tableUtils';
import { logout } from '../../auth/authRedirect';

const QualitativeDataUploadTableAddModal = props => {
	const { apiUrl, fieldsAdd, headers, fetchData, setGlobalMessage } = props;
    const [editableRow, setEditableRow] = useState(props.initialFields);
    const [open, setOpen] = useState(props.open);
    const [error, setError] = useState({ visible: false, list: [] });
    const [submittingChanges, setSubmittingChanges] = useState(false);
    const jwtIdToken = useSelector(state => state.authReducer.authResponse.jwtIdToken);
    const exp = useSelector(state => state.authReducer.authResponse.idTokenClaims.exp);

	const openModal = () => setOpen(true);
	const closeModal = () => {
        setError({ visible: false, list: [] });
		setEditableRow(props.initialFields);
		setOpen(false);
	}
	
	const handleOnChange = (e, data, cellName, fieldType) => {
        setEditableRow(customTableAddHandleOnChange(e, data, editableRow, cellName, fieldType));
    };

	const handleSubmit = async() => {
        setSubmittingChanges(true);

        const fieldErrors = checkForAddFieldErrors(editableRow, fieldsAdd, headers);

        if (fieldErrors.length > 0) {
			setError({visible: true, list: fieldErrors});
			setSubmittingChanges(false);
            return;
        } else {
            setError({visible: false, list: []});
        }

        const postData = getChangedAddValues(editableRow);

        try {
            const promises = postData.map(async (data) => 
                await axios.put(apiUrl, data, 
                    { headers: { Authorization: `Bearer ${jwtIdToken}`, "Ocp-Apim-Subscription-Key": process.env.REACT_APP_OCP_SUBSCRIPTION_KEY }})
            );
            await Promise.all(promises);
            await fetchData();
            setGlobalMessage({ visible: true, positive: true, negative: false, header: "Changes saved", text: "Your changes have been successfully updated." })
        } catch (error) {
            if (new Date().getTime() >= exp * 1000) {
                logout();
            } else {
                console.error(error);
                await fetchData();
                setGlobalMessage({ visible: true, positive: false, negative: true, header: `Unable to save changes.`, text: error.response.data.ErrorMessage });
                setError({ visible: false, list: [] });
            }
        } finally {
			closeModal();
            setSubmittingChanges(false);
        }
     }

	 const modalRow = () => {
        return  (
                <React.Fragment>
                    <Grid>

					    {/* Company Name */}
                        <Grid.Column width={6} className="label-column">
                            <label className="edit-modal-label">Company Name:</label>
                        </Grid.Column>
                        <Grid.Column className="dropdown-field" width={10}>
                            <Form.Input>
                            <Dropdown onChange={(e, data) =>  handleOnChange(e, data, "CompanyName", "dropdown")}
                                placeholder={editableRow["CompanyName"]} selection fluid scrolling={true} selectOnBlur={false} defaultValue={editableRow["CompanyName"]}
                                options={props.dropdowns["CompanyName"].map((dropdown, index) => { return { key: index, value: dropdown, text: dropdown }})}/>
                            </Form.Input>
                        </Grid.Column>

                        {/* Q1 Score */}
						<Grid.Column width={6} className="label-column">
                            <label className="edit-modal-label">Q1 Score:</label>
                        </Grid.Column>
                        <Grid.Column className="number-field" width={10}>
							<Form.Input>
                                <Input onChange={(e, data) => handleOnChange(e, data, "Q1_Score", "number")}
                                    type="number" step="0.01" defaultValue={editableRow["Q1_Score"]}/>
                            </Form.Input>
                        </Grid.Column>

                        {/* Q2 Score */}
						<Grid.Column width={6} className="label-column">
                            <label className="edit-modal-label">Q2 Score:</label>
                        </Grid.Column>
                        <Grid.Column className="number-field" width={10}>
							<Form.Input>
                                <Input onChange={(e, data) => handleOnChange(e, data, "Q2_Score", "number")}
                                    type="number" step="0.01" defaultValue={editableRow["Q2_Score"]}/>
                            </Form.Input>
                        </Grid.Column>

                        {/* Q2 Year To Date */}
						<Grid.Column width={6} className="label-column">
                            <label className="edit-modal-label">Q2 Year To Date:</label>
                        </Grid.Column>
                        <Grid.Column className="number-field" width={10}>
							<Form.Input>
                                <Input onChange={(e, data) => handleOnChange(e, data, "Q2_YTD", "number")}
                                    type="number" step="0.01" defaultValue={editableRow["Q2_YTD"]}/>
                            </Form.Input>
                        </Grid.Column>

                        {/* Q3 Score */}
						<Grid.Column width={6} className="label-column">
                            <label className="edit-modal-label">Q3 Score:</label>
                        </Grid.Column>
                        <Grid.Column className="number-field" width={10}>
							<Form.Input>
                                <Input onChange={(e, data) => handleOnChange(e, data, "Q3_Score", "number")}
                                    type="number" step="0.01" defaultValue={editableRow["Q3_Score"]}/>
                            </Form.Input>
                        </Grid.Column>

                        {/* Q3 Year To Date */}
						<Grid.Column width={6} className="label-column">
                            <label className="edit-modal-label">Q3 Year To Date:</label>
                        </Grid.Column>
                        <Grid.Column className="number-field" width={10}>
							<Form.Input>
                                <Input onChange={(e, data) => handleOnChange(e, data, "Q3_YTD", "number")}
                                    type="number" step="0.01" defaultValue={editableRow["Q3_YTD"]}/>
                            </Form.Input>
                        </Grid.Column>

                        {/* Q4 Score */}
						<Grid.Column width={6} className="label-column">
                            <label className="edit-modal-label">Q4 Score:</label>
                        </Grid.Column>
                        <Grid.Column className="number-field" width={10}>
							<Form.Input>
                                <Input onChange={(e, data) => handleOnChange(e, data, "Q4_Score", "number")}
                                    type="number" step="0.01" defaultValue={editableRow["Q4_Score"]}/>
                            </Form.Input>
                        </Grid.Column>

                        {/* Q4 Year To Date */}
						<Grid.Column width={6} className="label-column">
                            <label className="edit-modal-label">Q4 Year To Date:</label>
                        </Grid.Column>
                        <Grid.Column className="number-field" width={10}>
							<Form.Input>
                                <Input onChange={(e, data) => handleOnChange(e, data, "Q4_YTD", "number")}
                                    type="number" step="0.01" defaultValue={editableRow["Q4_YTD"]}/>
                            </Form.Input>
                        </Grid.Column>

                        {/* Year */}
                        <Grid.Column width={6} className="label-column">
                            <label className="edit-modal-label">Year:</label>
                        </Grid.Column>
                        <Grid.Column className="dropdown-field" width={10}>
                            <Form.Input>
                            <Dropdown onChange={(e, data) =>  handleOnChange(e, data, "Yr", "dropdown")}
                                placeholder={editableRow["Yr"]} selection fluid scrolling={true} selectOnBlur={false} defaultValue={editableRow["Yr"]}
                                options={props.dropdowns["Year"].map((dropdown, index) => { return { key: index, value: dropdown, text: dropdown }})}/>
                            </Form.Input>
                        </Grid.Column>
                    </Grid>
                </React.Fragment>
        );
	};
	
	return (
		<>
			<Popup content="Add row" trigger={<Button className="table-add-btn" onClick={openModal}>Add</Button>}/>
			<Modal
				className="edit-modal"
				as={Form}
				onClose={void(0)}
				onOpen={openModal}
				open={open}
				onSubmit={handleSubmit}
				>
				<Modal.Header>Add row</Modal.Header>
				<Modal.Content className="edit-modal-content" scrolling>
					{modalRow()}
				</Modal.Content>
				<Modal.Actions>
					{error.visible ? 
						<Message
							className="edit-modal-error-message"
							negative
							header="Error"
							list={error.list}
						></Message>
						:
						<></>
					}
					<Button type="button" onClick={closeModal} content="Cancel"/>
					<Button disabled={submittingChanges === true} 
						loading={submittingChanges === true} type="submit" icon="save" content="Save changes" color="green"/>
				</Modal.Actions>
			</Modal>
		</>
		);
}

export default QualitativeDataUploadTableAddModal;