import React from 'react';
import { DEVELOPER_CONTACT_DETAILS, WATER_COMPANIES_CONTACT_DETAILS, WATER_COMPANIES_HEADING, NOTES } from '../../constants/contactConsts';
import './Contact.scss';

const Contact = () => {

    const DeveloperSection = () => {
        return (
            <div className="developer-services-contact">
                <h3>{DEVELOPER_CONTACT_DETAILS.heading}</h3>
                <li><a href={`mailto:${DEVELOPER_CONTACT_DETAILS.email}`}>{DEVELOPER_CONTACT_DETAILS.email}</a></li>
            </div>
        )
    };

    const WaterCompaniesSection = () => {
        const waterCompanies = WATER_COMPANIES_CONTACT_DETAILS.map((company, index) => {
            return <li key={index}><a href={company.url} target="_blank" rel="noopener noreferrer">{company.name}</a></li>
        });

        return (
            <div className="water-companies-contact">
                <h3>{WATER_COMPANIES_HEADING}</h3>
                <ul>{waterCompanies}</ul>
            </div>
        )
    };

    const NotesSection = () => {
        
        const notes = NOTES.map((note, index) => {
            return <p key={index}>{note}</p>
        });

        return (
            <div className="notes-section">
                <h4>Notes</h4>
                {notes}
            </div>
        )
    }

    return (
        <div className="contact-page-container">
            <h2 className="title">Contacts</h2>
            <DeveloperSection/>
            <WaterCompaniesSection/>
            <NotesSection/>
        </div>
    )
};

export default Contact;