import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import authReducer from './reducers/authReducer';
import accountReducer from './reducers/accountReducer';
import powerBiReducer from './reducers/powerBIReducer';
import dataUploadReducer from './reducers/dataUploadReducer';

const rootReducer = combineReducers({ authReducer, powerBiReducer, accountReducer, dataUploadReducer});
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(rootReducer, composeEnhancer(applyMiddleware(thunk)));

export default store;