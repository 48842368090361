import React, { useEffect, useCallback }  from 'react';
import { connect } from 'react-redux';
import { Loader, Button } from 'semantic-ui-react';
import { getPowerBiPublicConfig, setPowerBiAuthenticatedConfig, resetNumOfAttempts } from '../../actions/powerBIActions';
import { IENotice } from '../../constants/generalConsts';
import PowerBiAuditLog from '../../components/powerBi/PowerBiAuditLog/PowerBiAuditLog';

const AuditLog = props => {
    const { powerBiAuditConfig, account, authenticated, jwtIdToken, setPowerBiAuthenticatedConfig, resetNumOfAttempts, numOfAttempts, error } = props;

    const privateDashboardRequest = useCallback(async() => {
        if (numOfAttempts === 0) {
            setPowerBiAuthenticatedConfig(jwtIdToken);
        } else if (numOfAttempts > 0 && numOfAttempts < 3) {
            const timeout = setTimeout(() => {
                setPowerBiAuthenticatedConfig(jwtIdToken);
                clearTimeout(timeout);
            }, [10000]);
        }
    }, [setPowerBiAuthenticatedConfig, jwtIdToken, numOfAttempts]);

    useEffect(() => {
        if (account !== null && authenticated === false && powerBiAuditConfig === null) {
            privateDashboardRequest();
        }

    }, [privateDashboardRequest, powerBiAuditConfig, setPowerBiAuthenticatedConfig, numOfAttempts, account, authenticated, jwtIdToken]);


    if (powerBiAuditConfig === null && numOfAttempts === 0) {
        return <Loader active inline='centered'>Loading Audit Log</Loader>
    }
    
    const handleRetry = async() => {
        await resetNumOfAttempts(2);
        if (account !== null && authenticated === false && powerBiAuditConfig === null) {
            privateDashboardRequest();
        }
    };

    if (numOfAttempts >= 3) {
        return (
            <div className="power-bi-dashboard-error-container">
                <h1 className="error">Unable to connect to Dashboard.</h1>
                {error ? <h1 className="error">{error}</h1> : <></>}
                <IENotice/>
                <Button className="retry-btn" onClick={handleRetry} size="large">Retry</Button>
            </div>
        );
    } else {
        return <PowerBiAuditLog/>
    }
}


const mapStateToProps = (state) => {
    const powerBiAuditConfig = state.powerBiReducer.powerBiAuditLogConfig;
    const authenticated = state.powerBiReducer.authenticated;
    const account = state.accountReducer.Account;
    const jwtIdToken = state.authReducer.authResponse.jwtIdToken;
    const numOfAttempts = state.powerBiReducer.numOfAttempts;
    const error = state.powerBiReducer.error;
    return {
        powerBiAuditConfig,
        authenticated,
        account,
        jwtIdToken,
        numOfAttempts,
        error,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getPowerBiPublicConfig: () => dispatch(getPowerBiPublicConfig()),
        setPowerBiAuthenticatedConfig: (jwtIdToken) => dispatch(setPowerBiAuthenticatedConfig(jwtIdToken)),
        resetNumOfAttempts: (num) => dispatch(resetNumOfAttempts(num))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AuditLog);