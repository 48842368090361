import React from 'react'
import { Icon } from 'semantic-ui-react';
import CookieNotice from '../cookieNotice/CookieNotice';
import { FOOTER_LINKS } from '../../constants/footerConsts';
import waterUkLogo from '../../assets/images/wateruk.svg';
import capitaLogo from '../../assets/images/Capita_Logo_128x41.png';

const Footer = () => {

    const FooterFollow = () => {
        const { SOCIAL_MEDIA_LINKS } = FOOTER_LINKS; 
        const links = SOCIAL_MEDIA_LINKS.map((link, index) => {
            return (
                <a key={index} href={link.url} target="_blank" rel="noopener noreferrer" alt={link.title}>
                    <Icon name={link.icon}/>
                </a>
            )
        });

        return (
            <>
            <section className="footer-follow">

                {/* Water Logo */}
                <img className="water-uk-logo" src={waterUkLogo} alt="Water UK"/>

                {/* Social Media Heading */}
                <h4>Follow us on social media:</h4>

                {/* Social Media Links */}
                <div className="social-media-links">
                    {links}
                </div>
            </section>
            </>
        );
    }
    
    const FooterLinks = () => {
        const { WATER_UK_LINKS } = FOOTER_LINKS;
        const links = WATER_UK_LINKS.map((column, colIndex) => {
            return (
                <ul key={colIndex}>{
                    column.map((link, index) => <li key={index}><a href={link.url} target="_blank" rel="noopener noreferrer">{link.title}</a></li>
                    )}
                </ul>
            );
        });
    
        return (
            <section className="footer-links">
                <div className="footer-links-inner">
                    {links}
                </div>
            </section>
        );
    }
    
    const CapitaBranding = () => {
        const { CAPITA_LOGO } = FOOTER_LINKS;
        return (
          <section className='footer-capita-branding'>
            <h4>Built and designed by</h4>
            <a href={CAPITA_LOGO.url} target="_blank" rel="noopener noreferrer">
                <img src={capitaLogo} alt={CAPITA_LOGO.title} />
            </a>
          </section>
        );
    }

    const Copyright = () => {
        return (
            <div className="footer-copyright">
                <div className="container">
                    <p>&copy; Water UK {new Date().getFullYear()}. All rights reserved.</p>
                </div>
            </div>
        );
    }

    return (
      <footer>
      <CookieNotice/>
        <div className="container">
            <div className="footer-grid">
                <FooterFollow />
                <FooterLinks />
                <CapitaBranding />
            </div>
        </div>
        <Copyright/>
      </footer>
    );
}

export default Footer