export const HEADERS = [
    "Companies",
    "Period Type",
    "Period Start",
    "Period End",
    "Deadline",
    "Current",
    "Initial Email Reminder (days)",
    "Second Email Reminder (days)",
    "Third Email Reminder (days)"
];

export const FIELD_HEADERS = [
    "ReactKey",
    "Current",
    "Period ID",
    "Period Type",
    "Period Start",
    "Period End",
    "Deadline",
    "Open",
    "Initial Email Reminder",
    "Second Email Reminder",
    "Third Email Reminder",
    "Companies"
];

export const FIELDS_ADD = {
    PeriodID: "hidden-field",
    Companies: "modal",
    PeriodType: "dropdown",
    PeriodStart: "date-input-required",
    PeriodEnd: "date-input-required",
    Deadline: "date-input-required",
    Current: "hidden-field",
    Open: "hidden-field",
    InitialEmailReminder: "number",
    SecondEmailReminder: "number",
    ThirdEmailReminder: "number"
};

export const FIELDS_EDIT = {
    PeriodID: "hidden-field",
    Companies: "modal",
    PeriodType: "dropdown",
    PeriodStart: "date-input-required",
    PeriodEnd: "date-input-required",
    Deadline: "date-input-required",
    Current: "hidden-field",
    Open: "hidden-field",
    InitialEmailReminder: "number",
    SecondEmailReminder: "number",
    ThirdEmailReminder: "number"
};

export const INITIAL_FIELDS = {
    Companies: "",
    PeriodType: "Month",
    PeriodStart: "",
    PeriodEnd: "",
    Deadline: "",
    Open: "",
    InitialEmailReminder: 14,
    SecondEmailReminder: 7,
    ThirdEmailReminder: 2
};

export const COMPANIES_HEADERS = [
    "Company Name",
    "Eligible",
    "Period Open",
    "Submitted"
];

export const COMPANIES_FIELDS = {
    CompanyName: "read-only",
    Eligible: "checkbox",
    PeriodOpen: "checkbox",
    Submitted: "checkbox"
};

export const SORT_FIELDS = {
    "Companies": "Companies",
    "Period Type": "PeriodType",
    "Period Start": "PeriodStart",
    "Period End": "PeriodStart",
    "Deadline": "Deadline",
    "Current": "Current",
    "Initial Email Reminder (days)": "InitialEmailReminder",
    "Second Email Reminder (days)": "SecondEmailReminder",
    "Third Email Reminder (days)": "ThirdEmailReminder"
};