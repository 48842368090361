import React, { useState } from 'react'
import { Button, Popup, Form, Modal, Grid, Message, Input } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { getChangedEditValues } from '../../utils/qualitativeDataUploadUtils';
import { customTableEditHandleOnChange } from '../../utils/tableUtils';
import '../CustomTable/EditModal/EditModal.scss';
import { logout } from '../../auth/authRedirect';

const QualitativeDataUploadTableEditModal = (props) => {
    const { tableRows, setTableRows, index, apiUrl, openEditModal, setOpenEditModal, setGlobalMessage, fetchData } = props;
    const [editableRow, setEditableRow] = useState(tableRows);
    const [open, setOpen] = useState(props.open);
    const [error, setError] = useState({ visible: false, list: [] });
    const [submittingChanges, setSubmittingChanges] = useState(false);
    const jwtIdToken = useSelector(state => state.authReducer.authResponse.jwtIdToken);
    const exp = useSelector(state => state.authReducer.authResponse.idTokenClaims.exp);

    const openModal = () => setOpen(true);
    const closeModal = () => { setOpen(false); setError({ visible: false, list: [] }); }
    const closeModalCancel = () => {
        setError({ visible: false, list: [] });
        if (openEditModal.visible) {
            const tr = [...tableRows];
            const previousTableRows = tr.splice(0, tr.length - 1);
            setTableRows(previousTableRows);
            setOpenEditModal({ visible: false, index: null });
        } else {
            setEditableRow(tableRows);
            setOpen(false);
        }
    };

    const handleOnChange = (e, data, cellName, rIndex, fieldType) => {
        setEditableRow(customTableEditHandleOnChange(e, data, editableRow, cellName, rIndex, fieldType));
    };

    const checkForChanges = (tableRows, editableRow) => JSON.stringify(tableRows) === JSON.stringify(editableRow) ? true : false;

    const handleSubmit = async() => {
        setSubmittingChanges(true);

        const postData = getChangedEditValues(tableRows[index], editableRow[index]);

        try {
            const promises = postData.map(async (data) => 
                await axios.put(apiUrl, data, 
                    { headers: { Authorization: `Bearer ${jwtIdToken}`, "Ocp-Apim-Subscription-Key": process.env.REACT_APP_OCP_SUBSCRIPTION_KEY }})
            );
            await Promise.all(promises);
            await fetchData();
            setGlobalMessage({ visible: true, positive: true, negative: false, header: "Changes saved", text: "Your changes have been successfully updated." })
            closeModal();
        } catch (error) {
            if (new Date().getTime() >= exp * 1000) {
                logout();
            } else {
                console.error(error);
                await fetchData();
                setGlobalMessage({ visible: true, positive: false, negative: true, header: `Unable to save changes.`, text: error.response.data.ErrorMessage });
                closeModalCancel();
            }
        } finally {
            setSubmittingChanges(false);
        }
    }

    const modalRow = () => {
        return  (
                <React.Fragment>
                    <Grid>
						
                        {/* Company Name */}
                        <Grid.Column width={6} className="label-column">
                            <label className="edit-modal-label">Company Name:</label>
                        </Grid.Column>
                        <Grid.Column className="dropdown-field" width={10}>
                            {editableRow[index]["CompanyName"]}
                        </Grid.Column>

                        {/* Q1 Score */}
						<Grid.Column width={6} className="label-column">
                            <label className="edit-modal-label">Q1 Score:</label>
                        </Grid.Column>
                        <Grid.Column className="number-field" width={10}>
                            {editableRow[index].Q1_Editable === "Yes" ?
							<Form.Input>
                                <Input onChange={(e, data) => handleOnChange(e, data, "Q1_Score", props.index, "number")}
                                    type="number" step="0.01" defaultValue={editableRow[index]["Q1_Score"]}/>
                            </Form.Input>
                            : editableRow[index]["Q1_Score"]}
                        </Grid.Column>

                        {/* Q2 Score */}
						<Grid.Column width={6} className="label-column">
                            <label className="edit-modal-label">Q2 Score:</label>
                        </Grid.Column>
                        <Grid.Column className="number-field" width={10}>
                            {editableRow[index].Q2_Editable === "Yes" ?
							<Form.Input>
                                <Input onChange={(e, data) => handleOnChange(e, data, "Q2_Score", props.index, "number")}
                                    type="number" step="0.01" defaultValue={editableRow[index]["Q2_Score"]}/>
                            </Form.Input>
                            : editableRow[index]["Q2_Score"]}
                        </Grid.Column>

                        {/* Q2 Year To Date */}
						<Grid.Column width={6} className="label-column">
                            <label className="edit-modal-label">Q2 Year To Date:</label>
                        </Grid.Column>
                        <Grid.Column className="number-field" width={10}>
                            {editableRow[index].Q2_Editable === "Yes" ?
							<Form.Input>
                                <Input onChange={(e, data) => handleOnChange(e, data, "Q2_YTD", props.index, "number")}
                                    type="number" step="0.01" defaultValue={editableRow[index]["Q2_YTD"]}/>
                            </Form.Input>
                            : editableRow[index]["Q2_YTD"]}
                        </Grid.Column>

                        {/* Q3 Score */}
						<Grid.Column width={6} className="label-column">
                            <label className="edit-modal-label">Q3 Score:</label>
                        </Grid.Column>
                        <Grid.Column className="number-field" width={10}>
                            {editableRow[index].Q3_Editable === "Yes" ?
							<Form.Input>
                                <Input onChange={(e, data) => handleOnChange(e, data, "Q3_Score", props.index, "number")}
                                    type="number" step="0.01" defaultValue={editableRow[index]["Q3_Score"]}/>
                            </Form.Input>
                            : editableRow[index]["Q3_Score"]}
                        </Grid.Column>

                        {/* Q3 Year To Date */}
						<Grid.Column width={6} className="label-column">
                            <label className="edit-modal-label">Q3 Year To Date:</label>
                        </Grid.Column>
                        <Grid.Column className="number-field" width={10}>
                            {editableRow[index].Q3_Editable === "Yes" ?
							<Form.Input>
                                <Input onChange={(e, data) => handleOnChange(e, data, "Q3_YTD", props.index, "number")}
                                    type="number" step="0.01" defaultValue={editableRow[index]["Q3_YTD"]}/>
                            </Form.Input>
                            : editableRow[index]["Q3_YTD"]}
                        </Grid.Column>

                        {/* Q4 Score */}
						<Grid.Column width={6} className="label-column">
                            <label className="edit-modal-label">Q4 Score:</label>
                        </Grid.Column>
                        <Grid.Column className="number-field" width={10}>
                            {editableRow[index].Q4_Editable === "Yes" ?
							<Form.Input>
                                <Input onChange={(e, data) => handleOnChange(e, data, "Q4_Score", props.index, "number")}
                                    type="number" step="0.01" defaultValue={editableRow[index]["Q4_Score"]}/>
                            </Form.Input>
                            : editableRow[index]["Q4_Score"]}
                        </Grid.Column>

                        {/* Q4 Year To Date */}
						<Grid.Column width={6} className="label-column">
                            <label className="edit-modal-label">Q4 Year To Date:</label>
                        </Grid.Column>
                        <Grid.Column className="number-field" width={10}>
                            {editableRow[index].Q4_Editable === "Yes" ?
							<Form.Input>
                                <Input onChange={(e, data) => handleOnChange(e, data, "Q4_YTD", props.index, "number")}
                                    type="number" step="0.01" defaultValue={editableRow[index]["Q4_YTD"]}/>
                            </Form.Input>
                            : editableRow[index]["Q4_YTD"]}
                        </Grid.Column>

                        {/* Year */}
                        <Grid.Column width={6} className="label-column">
                            <label className="edit-modal-label">Year:</label>
                        </Grid.Column>
                        <Grid.Column className="dropdown-field" width={10}>
                            {editableRow[index]["Yr"]}
                        </Grid.Column>

                    </Grid>
                </React.Fragment>
        );
    }

    return (
    <>
        <Popup content="Edit row" trigger={<Button onClick={openModal} icon="edit"></Button>}/>
        <Modal
            className="edit-modal"
            as={Form}
            onClose={void(0)}
            onOpen={openModal}
            open={open}
            onSubmit={handleSubmit}
            >
            <Modal.Header>Edit row</Modal.Header>
            <Modal.Content className="edit-modal-content" scrolling>
               {modalRow()}
            </Modal.Content>
            <Modal.Actions>
                {error.visible ? 
                    <Message
                        className="edit-modal-error-message"
                        negative
                        header="Error"
                        list={error.list}
                    ></Message>
                    :
                    <></>
                }
                <Button type="button" onClick={closeModalCancel} content="Cancel"/>
                <Button disabled={checkForChanges(tableRows[index], editableRow[index]) || submittingChanges === true}
                    loading={submittingChanges} type="submit" icon="save" content="Save changes" color="green"/>
            </Modal.Actions>
        </Modal>
    </>
    );
}

QualitativeDataUploadTableEditModal.propTypes = {
    tableName: PropTypes.string,
    tableRows: PropTypes.array,
    setTableRows: PropTypes.func,
    editableRow: PropTypes.array,
    index: PropTypes.number,
    apiUrl: PropTypes.string
}

QualitativeDataUploadTableEditModal.defaultProps = {
    apiUrl: null
};

export default QualitativeDataUploadTableEditModal;
