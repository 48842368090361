import { GET_ACCOUNT_DETAILS, GET_ACCOUNT_DETAILS_ERROR } from '../actions/accountActions';

const initialState = {
  Account: null,
  Error: null
};

const accountReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_ACCOUNT_DETAILS:
        return { ...state, Account: action.payload };
      case GET_ACCOUNT_DETAILS_ERROR:
        return { ...state, Error: action.payload };
      default:
        return state;
    };
};

export default accountReducer;