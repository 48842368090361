export const getFileName = (path) => {
    return path.split("_")[0].replace(".xlsx", "") + path.substring(path.lastIndexOf('.'), path.length);
};

export const downloadTemplate = async(fileName) => {
    try {
        let a = document.createElement('a');
        a.href = `https://sawaterukdev.blob.core.windows.net/${process.env.REACT_APP_AZURE_CONTAINER_NAME}/Templates/${fileName}`;
        a.download = getFileName(fileName);
        a.click();
        a.remove();
    } catch (err) {
        console.log(err);
    }
};