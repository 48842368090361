export const HEADERS = [
    "Code",
    "Title",
    "Definition",
    "Type",
    "Report Type",
    "Target"
 ];
 
 export const INITIAL_FIELDS = {
    Code: "",
    Title: "",
    Definition: "",
    Type: "",
    ReportType: "",
    Target: ""
 };

export const FIELD_HEADERS = [
    "ReactKey",
    "Code",
    "Title",
    "Definition",
    "Type",
    "Report Type",
    "Target",
];

export const FIELDS = {
    ReactKey: "hidden-field",
    Code: "read-only",
    Title: "text-area",
    Definition: "text-area",
    Type: "read-only",
    ReportType: "read-only",
    Target: "read-only",
};

//TODO: Remove array. Implemented due to lower case x on DMeX Reportable fields.
export const SORT_FIELD_HEADERS = [
    "ReactKey",
    "Code",
    "Title",
    "Definition",
    "Type",
    "Report Type",
    "Target"
];

export const SORT_FIELDS = {
    "Code" : "Code",
    "Title": "Title",
    "Definition": "Definition",
    "Type": "Type",
    "Report Type": "ReportType",
    "Target": "Target",
};