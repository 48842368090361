export const HEADERS = [
    "File Name",
    "Template Version",
    "Current",
    "Valid From",
    "Valid To",
    "Uploaded By",
    "Uploaded Date",
    "Version Comments"
];

export const FIELD_HEADERS = [
    "File Name",
    "Template Version",
    "Current",
    "Valid From",
    "Valid To",
    "Uploaded By",
    "Uploaded Date",
    "Version Comments"
];

export const INITIAL_STATE = {
    FileName: "",
    TemplateVersion: "",
    Current: "",
    ValidFrom: "",
    ValidTo: "01/01/2100",
    UploadedBy: "",
    UploadedDate: "",
    VersionComments: ""
};

export const FIELDS_EDIT = {
    FileName: "read-only",
    TemplateVersion: "read-only",
    Current: "read-only",
    ValidFrom: "date-input-required",
    ValidTo: "date-input-required",
    UploadedBy: "read-only",
    UploadedDate: "read-only",
    VersionComments: "text-area"
};

export const FIELDS_ADD = {
    FileName: "read-only",
    TemplateVersion: "read-only",
    ValidFrom: "date-input-required",
    ValidTo: "date-input-required",
    UploadedBy: "read-only",
    UploadedDate: "read-only",
    VersionComments: "text-area"
};

export const SORT_FIELDS = {
    "File Name": "FileName",
    "Template Version": "TemplateVersion",
    "Current": "Current",
    "Valid From": "ValidFrom",
    "Valid To": "ValidTo",
    "Uploaded By": "UploadedBy",
    "Uploaded Date": "UploadedDate",
    "Version Comments": "VersionComments"
};