import React, { useState, useEffect } from 'react';
import { Loader } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { setAuthDetails } from '../../actions/authActions';
import CustomTable from '../../components/CustomTable/CustomTable';
import axios from 'axios';
import { HEADERS, FIELDS, FIELD_HEADERS, INITIAL_STATE, SORT_FIELDS } from './CompanyDetailsTableSchema';
import { logout } from '../../auth/authRedirect';
import './CompanyDetails.scss';

const CompanyDetails = props => {
    const [data, setData] = useState(null);
    const [error, setError] = useState({ visible: false, message: "" });
    const { setAuthDetails, jwtIdToken, exp } = props;

    useEffect(() => {
        const fetchData = async() => {
            await setAuthDetails();
                try {
                    const { data } = await axios.post(process.env.REACT_APP_COMPANIES_URL, {},
                        { headers: { Authorization: `Bearer ${jwtIdToken}`, "Ocp-Apim-Subscription-Key": process.env.REACT_APP_OCP_SUBSCRIPTION_KEY }});
                    const rowsWithKeys = Object.values([...data.Rows]).map((row, index) => { return { ReactKey: index, ...row } });
                    data["Rows"] = rowsWithKeys;
                    setData(data);
                } catch (error) {
                    if (new Date().getTime() >= exp * 1000) {
                        logout();
                    } else {
                        console.error(error);
                        setError({visible: true, message: `Unable to connect to Company Details API.\n ${error.response.data.ErrorMessage}`});
                    }
                }
            }
        fetchData();
    }, [setAuthDetails, jwtIdToken, exp]);

    const ShowTable = () => {
        return (
            <>
                <h1>Company Details</h1>
                <CustomTable
                    tableName="company-details-table"
                    apiUrl={process.env.REACT_APP_COMPANIES_URL}
                    headers={HEADERS}
                    rows={data.Rows}
                    fieldsAdd={FIELDS}
                    fieldsEdit={FIELDS}
                    fieldHeaders={FIELD_HEADERS}
                    initialFields={INITIAL_STATE}
                    sortFields={SORT_FIELDS}
                    dropdowns={data.Dropdowns}
                    editable={true}
                    addable={true}
                    />
            </>
        )
    }

    const ShowLoading = () => {
        return error.visible === false ?
            <Loader active inline='centered'>Retrieving Company Details</Loader> : error.message.split("\n").map((line, i) => <h1 key={i} className="error">{line}</h1>);
    }

    return (
        <div className="company-details-container">
            {data === null ? <ShowLoading/> : <ShowTable/>}
        </div>
    );
}

const mapStateToProps = (state, ownProps) => {
    const jwtIdToken = state.authReducer.authResponse.jwtIdToken;
    const exp = state.authReducer.authResponse.idTokenClaims.exp;
    return {
        jwtIdToken,
        exp,
        ownProps
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        setAuthDetails: () => dispatch(setAuthDetails())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyDetails);
