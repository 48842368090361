import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Menu, Dropdown, Button, Icon } from 'semantic-ui-react';
import { NavLink, Link } from 'react-router-dom';
import { USER_ROLE } from '../../constants/userConsts'
import { NAVIGATION_LINKS } from '../../constants/headerConsts';
import waterUkLogo from '../../assets/images/wateruk.svg';
import { signIn, logout } from '../../auth/authRedirect';
import userHandbookPublic from '../../assets/docs/UserHandbook/Public/User_Handbook.pdf';
import userHandbookWaterCompany from '../../assets/docs/UserHandbook/WaterCompany/User_Handbook.pdf';

const Header = props => {
    const { userRole, disabledMenus } = props;

    const getNavItems = (role) => {
        switch(role) {
            case USER_ROLE.PUBLIC: return NAVIGATION_LINKS.PUBLIC;
            case USER_ROLE.USER: return NAVIGATION_LINKS.USER;
            case USER_ROLE.WATER_COMPANY_ADMIN: return NAVIGATION_LINKS.WATER_COMPANY_ADMIN;
            case USER_ROLE.SUPER_ADMIN: return NAVIGATION_LINKS.SUPER_ADMIN;

            default: return NAVIGATION_LINKS.PUBLIC;
        }
    }

    const Logo = () => {
        return (
            <div className="logo-container">
            <Link to="/">
                <img className="water-uk-logo" src={waterUkLogo} alt="Water UK"/>
                <h4 style={{ margin: 0, textAlign: "left" }}>Developer Services</h4>
            </Link>
            </div>
        )
    };

    const UserHandbookBtn = () => {
        return (
            <Link className="data-dictionary-download-link"
                to={userRole === USER_ROLE.PUBLIC ?
                    userHandbookPublic
                    :
                    userHandbookWaterCompany
                }
                target="_blank"
                download="User Handbook">
                <Button style={{ paddingTop: "1em", paddingBottom: "1em"}}>
                    <Icon name="download"></Icon>
                    User Handbook
                </Button>
            </Link>
        );
    }

    const handleLogin = () => signIn();
    const handleLogout = () => logout();

    const NavItems = () => {
        const navLinks = getNavItems(userRole).map((menu, index) => {
            return (
                <Menu.Item key={index} disabled={disabledMenus.includes(menu.title)}>
                    {menu.url ?
                            disabledMenus.includes(menu.title) ?
                            <Link className="link-disabled" to="#">{menu.title}</Link>    
                            :
                            menu.title === "Login" ?
                            <Link to="/" onClick={handleLogin}>{menu.title}</Link>
                            :
                            menu.title === "Logout" ?
                            <Link to="/" onClick={handleLogout}>{menu.title}</Link>
                            :
                            <NavLink exact to={menu.url}>{menu.title}</NavLink>
                        :
                            <Dropdown item text={menu.title}>
                                <Dropdown.Menu>
                                    {menu.subMenus.map((subMenu, sIndex) => {
                                        return (
                                            <Dropdown.Item key={sIndex} as={NavLink} to={subMenu.url}>
                                                {subMenu.title}
                                            </Dropdown.Item>
                                        )
                                    })}
                                </Dropdown.Menu>
                            </Dropdown>
                    }
                </Menu.Item>
            )
        });
        return (<Menu className="header-navigation" secondary><UserHandbookBtn/>{navLinks}</Menu>);
    };

    return (
        <header>
            <div className="container">
                <Logo/>
                <NavItems/>
            </div>
        </header>
    );
}

Header.propTypes = {
    disabledMenus: PropTypes.array
};

Header.defaultProps = {
    disabledMenus: []
}

const mapStateToProps = (state, ownProps) => {
    const userRole = state.authReducer.authResponse.jwtIdToken !== null ? state.authReducer.authResponse.idTokenClaims.extension_UserRole : USER_ROLE.PUBLIC;
    return { userRole, ownProps }
}

export default connect(mapStateToProps)(Header);