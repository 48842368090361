import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

export const metricsPagePublicCsvReport = (csvData, fileName) => {
    const tableData = csvData.map(r => ({
        "Code": r.Code,
        "Title": r.Title.trim(),
        "Definition": r.Definition.trim(),
        "Type": r.Type,
        "Report Type": r.ReportType,
        "Target": r.Target,
    }));
    exportToFile(tableData, fileName, "public");
};

export const metricsPagePrivateCsvExport = (csvData, fileName) => {
    const tableData = csvData.map(r => ({
        "Code": r.Code,
        "Title": r.Title,
        "Definition": r.Definition,
        "Valid From": r.ValidFrom,
        "Valid To": r.ValidTo,
        "Type": r.Type,
        "Report Type": r.ReportType,
        "Target": r.Target,
        "D-MeX Reportable Qualitative": r.DMexReportableQualitative,
        "D-MeX Reportable Quantitative": r.DMexReportableQuantitative,
        "D-MeX Qualitative": r.DMeXQualitative,
        "D-MeX Quantitative": r.DMeXQuantitative,
        "Category": r.Category,
        "Customer Type": r.CustomerType,
        "Codes Viewable": r.CodesViewable,
    }));
    exportToFile(tableData, fileName, "private");
};

const metricsPublicColSizes = [
    {wch:12},
    {wch:50},
    {wch:50},
    {wch:10},
    {wch:12},
    {wch:20}
];

const metricsPrivateColSizes = [
    {wch:12},
    {wch:50},
    {wch:50},
    {wch:12},
    {wch:12},
    {wch:12},
    {wch:12}
]

const exportToFile = (tableData, fileName, access) => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const wscols = access === "public" ? metricsPublicColSizes : metricsPrivateColSizes
    const ws = XLSX.utils.json_to_sheet(tableData);
    
    ws["!cols"] = wscols;
    const wb = { Sheets: { "Sheet 1": ws }, SheetNames: [`Sheet 1`] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], {type: fileType});
    FileSaver.saveAs(data, fileName + fileExtension);
};