import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { setAuthDetails } from './actions/authActions';
import * as Msal from 'msal';
import { msalConfig } from './auth/authConfig';
import { authRedirectCallBack, signIn, logout } from './auth/authRedirect';
import Cookies from 'universal-cookie';
import { getAccountDetails } from './actions/accountActions';
import { USER_ROLE } from './constants/userConsts'; 
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import BaseLayout from './layouts/base-layout';
import Homepage from './pages/homepage/Homepage';
import Dashboard from './pages/dashboard/Dashboard';
import Contact from './pages/contact/Contact';
import AuditLog from './pages/auditLog/AuditLog';
import CompanyDetails from './pages/companyDetails/CompanyDetails';
import ReportingPeriods from './pages/reportingPeriods/ReportingPeriods';
import Resubmission from './pages/resubmission/Resubmission'
import DataUpload from './pages/dataUpload/DataUpload';
import QualitativeDataUpload from './pages/qualitativeDataUpload/QualitativeDataUpload';
import MetricDefinitions from './pages/metricDefinitions/MetricDefinitions';
import MetricsReportPrivate from './pages/metricsReport/Private/MetricsReportPrivate';
import UserManagement from './pages/userManagement/UserManagement';
import PageLoading from './components/pageLoading/PageLoading';
import TermsAndConditions from './components/termsAndConditions/TermsAndConditions';
import MetricsReportPublic from './pages/metricsReport/Public/MetricsReportPublic';

export const msalAuth = new Msal.UserAgentApplication(msalConfig);
msalAuth.handleRedirectCallback(authRedirectCallBack);

const AuthenticatedRoutes = (props) => {
  const { getAccountDetails, accountReducer, authReducer, setAuthDetails, oid } = props;
  const { Account, Error } = accountReducer;
  const { authResponse} = authReducer;
  const userRole = authResponse.account !== null ? authResponse.idTokenClaims.extension_UserRole : null;
  const jwtIdToken = authResponse.account !== null ? authResponse.jwtIdToken : null;
  const cookies = new Cookies();

  //Check if JWT has expired
  if (Date.now() >= authResponse.idToken.exp * 1000) {
    logout();
  }

  useEffect(() => {
    if (Account === null) {
      getAccountDetails(jwtIdToken);
    }
  }, [getAccountDetails, Account, authResponse, jwtIdToken, setAuthDetails]);

  if (Account === null) {
    return <PageLoading error={Error}/>
  }

  if (cookies.get(`${oid}_termsAndConditions`)?.accepted !== true) {
    return (
      <Router>
      <BaseLayout>
        <Switch>
          <Route path='*' component={TermsAndConditions} />
        </Switch>
      </BaseLayout>
    </Router>
    );
  };

  switch(userRole) {
    case USER_ROLE.USER:
      return (
        <Router>
          <BaseLayout>
            <Switch>
              <Route exact path="/" component={Dashboard}/>
              <Route exact path="/homepage" component={Homepage}/>
              <Route exact path='/dashboard' component={Dashboard}/>
              <Route exact path='/metrics-report' component={MetricsReportPrivate} />
              <Route exact path='/contact' component={Contact}/>
              <Redirect to='/' component={Dashboard} />
              </Switch>
          </BaseLayout>
        </Router>
      )
    case USER_ROLE.WATER_COMPANY_ADMIN:
      return (
        <Router>
          <BaseLayout>
            <Switch>
              <Route exact path="/" component={Dashboard}/>
              <Route exact path="/homepage" component={Homepage}/>
              <Route exact path='/dashboard' component={Dashboard}/>
              <Route exact path='/audit-log' component={AuditLog}/>
              <Route exact path='/data-upload' component={DataUpload} />
              <Route exact path='/data-upload/qualitative-data-upload' component={QualitativeDataUpload}/>
              <Route exact path='/resubmission' component={Resubmission} />
              <Route exact path='/metrics-report' component={MetricsReportPrivate} />
              <Route exact path='/reporting-periods' component={ReportingPeriods} />
              <Route exact path='/user-management' component={UserManagement} />
              <Route exact path='/contact' component={Contact}/>
              <Redirect to='/' component={Dashboard} />
              </Switch>
          </BaseLayout>
        </Router>
      );
    case USER_ROLE.SUPER_ADMIN:
      return (
      <Router>
        <BaseLayout>
          <Switch>
            <Route exact path="/" component={Dashboard}/>
            <Route exact path="/homepage" component={Homepage}/>
            <Route exact path='/dashboard' component={Dashboard}/>
            <Route exact path='/audit-log' component={AuditLog}/>
            <Route exact path='/company-details' component={CompanyDetails}/>
            <Route exact path='/reporting-periods' component={ReportingPeriods} />
            <Route exact path='/data-upload' component={DataUpload} />
            <Route exact path='/data-upload/qualitative-data-upload' component={QualitativeDataUpload}/>
            <Route exact path='/resubmission' component={Resubmission} />
            <Route exact path='/metric-definitions' component={MetricDefinitions} />
            <Route exact path='/metrics-report' component={MetricsReportPrivate} />
            <Route exact path='/user-management' component={UserManagement} />
            <Route exact path='/contact' component={Contact}/>
            <Redirect to='/' component={Dashboard} />
            </Switch>
        </BaseLayout>
      </Router>
    );
    default: return <></>
  }
};

const UnauthenticatedRoutes = () => {
  return (
    <Router>
    <BaseLayout>
      <Switch>
        <Route exact path="/" component={Homepage}/>
        <Route exact path="/homepage" component={Homepage}/>
        <Route exact path='/dashboard' component={Dashboard}/>
        <Route exact path='/metrics-report' component={MetricsReportPublic}/>
        <Route exact path='/contact' component={Contact}/>
        <Redirect to='/' component={Dashboard} />
      </Switch>
    </BaseLayout>
  </Router>
  )
} 

const App = props => {
  const { authReducer } = props;

  if (authReducer.authResponse.jwtIdToken === null) {
    if (authReducer.authResponse.idToken !== undefined) {
      signIn();
      return <PageLoading/>
    } else {
      return <UnauthenticatedRoutes/>
    }
  } else {
    return <AuthenticatedRoutes {...props}/>
  }
};

const mapStateToProps = (state) => {
  const accountReducer = state.accountReducer;
  const authReducer = state.authReducer;
  const jwtIdToken = state.authReducer.authResponse.jwtIdToken;
  const oid = state.authReducer.authResponse?.idTokenClaims ? state.authReducer.authResponse.idTokenClaims.oid : USER_ROLE.PUBLIC;
  return {
    accountReducer,
    authReducer,
    jwtIdToken,
    oid
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getAccountDetails: (jwtIdToken) => dispatch(getAccountDetails(jwtIdToken)),
    setAuthDetails: () => dispatch(setAuthDetails())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);