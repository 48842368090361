export const DEVELOPER_CONTACT_DETAILS = {
    heading: "Queries relating to Developer Services reporting should be directed to Water UK.",
    email: "developers@water.org.uk"
};

export const WATER_COMPANIES_HEADING = "Individual water companies provide information via the links below."

export const WATER_COMPANIES_CONTACT_DETAILS = [
    { name: "Affinity Water", url: "https://www.affinitywater.co.uk/developing" },
    { name: "Anglian Water", url: "https://www.anglianwater.co.uk/developers/" },
    { name: "Bournemouth Water", url: "https://www.bournemouthwater.co.uk/developers/" },
    { name: "Bristol Water", url: "https://www.bristolwater.co.uk/" },
    { name: "Cambridge Water", url: "https://www.cambridge-water.co.uk/developer" },
    { name: "Dee Valley Water", url: "https://www.hdcymru.co.uk/" },
    { name: "Dŵr Cymru", url: "https://developers.dwrcymru.com/" },
    { name: "Essex & Suffolk Water", url: "https://www.eswater.co.uk/services/developers/" },
    { name: "Northumbrian Water", url: "https://www.nwl.co.uk/services/developers/" },
    { name: "Portsmouth Water", url: "https://www.portsmouthwater.co.uk/developers/" },
    { name: "Severn Trent Water", url: "https://www.stwater.co.uk/building-and-developing/overview/" },
    { name: "South East Water", url: "https://www.southeastwater.co.uk/" },
    { name: "Southern Water", url: "https://www.southernwater.co.uk/developing" },
    { name: "South Staffs Water", url: "https://www.south-staffs-water.co.uk/developer" },
    { name: "South West Water", url: "https://www.southwestwater.co.uk/developer-services/" },
    { name: "Sutton and East Surrey Water", url: "https://seswater.co.uk/developers" },
    { name: "Thames Water", url: "https://www.thameswater.co.uk/developers" },
    { name: "United Utilities", url: "https://www.unitedutilities.com/builders-developers/" },
    { name: "Wessex Water", url: "https://www.wessexwater.co.uk/developers" },
    { name: "Yorkshire Water", url: "https://www.yorkshirewater.com/developers/" }
]

export const NOTES = [
    "Cambridge Water is part of South Staffordshire plc and its levels of service results are included within those of South Staffs Water.",
    "Essex and Suffolk Water is part of the Northumbrian Water Group and its results are included within those of Northumbrian Water."
];