import React, {  useEffect } from 'react';
import { models } from "powerbi-client";
import { PowerBIEmbed } from 'powerbi-client-react';
import { connect } from 'react-redux';
import { setAuthDetails } from '../../../actions/authActions';
import { getPowerBiPublicConfig, setPowerBiAuthenticatedConfig } from '../../../actions/powerBIActions';
import { getAccountDetails } from '../../../actions/accountActions';
import { logout } from '../../../auth/authRedirect';
import './PowerBiMainDashboard.scss';

const PowerBiMainDashboard = props => {
    const { jwtIdToken, powerBiMainDashboardConfig, authenticated, getPowerBiPublicConfig, 
        setPowerBiAuthenticatedConfig, getAccountDetails, setAuthDetails, exp, powerBiExp, setErrorMessage } = props;

    if (powerBiExp === null) {
        logout();
    }

    useEffect(() => {
        const tokenCheck = async() => {
            const currentTime = new Date().getTime();
            if (authenticated === true) {
                try {
                    if (currentTime >= exp * 1000) {
                        logout();
                    } else if (currentTime > new Date(new Date(powerBiExp).getTime()) - (15 * 60000)) {
                        await setAuthDetails();
                        await getAccountDetails();
                        await setPowerBiAuthenticatedConfig(jwtIdToken);
                    }
                } catch (error) {
                    logout();
                }
            } else {
                await getPowerBiPublicConfig();
            }

            const performTokenCheck = setInterval(() => {
                tokenCheck();
            }, 2700000);
            return () => clearInterval(performTokenCheck);
        }
        tokenCheck();
    }, [jwtIdToken, getPowerBiPublicConfig, getAccountDetails, setPowerBiAuthenticatedConfig, 
        powerBiMainDashboardConfig, exp,powerBiExp, authenticated, setAuthDetails, setErrorMessage]);

    const reportConfig = {
		type: 'report',
		embedUrl: `https://app.powerbi.com/reportEmbed?reportId=${powerBiMainDashboardConfig?.rID}&groupId=${powerBiMainDashboardConfig?.wID}`,
        tokenType: models.TokenType.Embed,
        accessToken: powerBiMainDashboardConfig?.token,
        settings: {
            navContentPaneEnabled: false,
			panes: {
				filters: {
					expanded: false,
					visible: false
				}
			},
			background: models.BackgroundType.Transparent,
		}
    };

    const eventHandlersMap = new Map([
		['loaded', function () {
			console.log('Report has loaded');
		}],
		['rendered', function () {
            console.log('Report has rendered');
		}],
		['error', function (event) { 
			if (event) {
				console.error(event.detail);
			}
		}]
    ]);
	
    return (
        <PowerBIEmbed
            embedConfig = { reportConfig }
            eventHandlers = { eventHandlersMap }
            cssClassName = { "power-bi-dashboard" }
            getEmbeddedComponent = { (embeddedReport) => { }}
        />
    )
}

const mapStateToProps = (state) => {
    const powerBiMainDashboardConfig = state.powerBiReducer.powerBiMainDashboardConfig;
    const jwtIdToken = state.authReducer.authResponse !== null ? state.authReducer.authResponse.jwtIdToken : null;
    const authenticated = state.powerBiReducer.authenticated;
    const exp = state.authReducer.authResponse.idTokenClaims?.exp !== undefined ? state.authReducer.authResponse.idTokenClaims.exp : null;
    const powerBiExp = state.powerBiReducer.powerBiMainDashboardConfig?.expiration;
    return {
        powerBiMainDashboardConfig,
        jwtIdToken,
        authenticated,
        exp,
        powerBiExp
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getPowerBiPublicConfig: () => dispatch(getPowerBiPublicConfig()),
        setPowerBiAuthenticatedConfig: (jwtIdToken) => dispatch(setPowerBiAuthenticatedConfig(jwtIdToken)),
        setAuthDetails: () => dispatch(setAuthDetails()),
        getAccountDetails: (jwtIdToken) => dispatch(getAccountDetails(jwtIdToken))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PowerBiMainDashboard);