import React, { useState } from 'react';
import { connect } from 'react-redux';
import { setAuthDetails } from '../../actions/authActions';
import { setTemplateTableVisibility } from '../../actions/dataUploadActions';
import { Button, Modal, Loader } from 'semantic-ui-react';
import axios from 'axios';
import { logout } from '../../auth/authRedirect';
import { USER_ROLE } from '../../constants/userConsts';
import './UploadValidationModal.scss';

const UploadValidationModal = props => {
    const { open, setOpen, file, setFile, entriesWithErrors, setEntriesWithErrors, jwtIdToken, userRole, exp, uploadValidationError, setUploadValidationError,
        setAuthDetails, companyName, setTemplateTableVisibility, setUploadValidationAccepted, setUploadStatus, setFileName, versions } = props;
    const [submittingChanges, setSubmittingChanges] = useState(false);
    const { title, entriesWithErrorsArray, reportingPeriod, version, waterCompany } = entriesWithErrors || "";

    const dataIsValid = () => {
        if (title !== "Water UK Upload Template" || title === undefined) {
            return false;
        } else if (reportingPeriod ===  "" || reportingPeriod === null || reportingPeriod === undefined) {
            return false;
        } else if (waterCompany?.trim() !== companyName?.trim() && userRole !== USER_ROLE.SUPER_ADMIN) {
            return false;
        } else if (waterCompany === "" || waterCompany === null || waterCompany === undefined) {
            return false;
        } else if (!versions.includes(version)) {
            return false;
        } else {
            return true;
        }
    }

    const openModal = () => {
        setOpen(true);
    }

    const closeModal = async() => {
        await setAuthDetails()
        setFile(null);
        setEntriesWithErrors([]);
        setOpen(false);
    }

    const handleFileUpload = async() => {
        setSubmittingChanges(true);
        const extension = `.${file.name.split('.').pop()}`;
        const fileName = `${file.name.substring(0, file.name.lastIndexOf("."))} - ${new Date().toISOString().slice(0, 19).replace('T', ' ') + extension}`;

        try {
            const url = `${process.env.REACT_APP_UPLOAD_URL}?FileName=${fileName}&WaterCo=${companyName}`;

            const config = {
                headers: { Authorization: `Bearer ${jwtIdToken}`, "Ocp-Apim-Subscription-Key": process.env.REACT_APP_OCP_SUBSCRIPTION_KEY },
            }

            await axios.put(url, file, config);
            setFileName(fileName);
            setUploadValidationAccepted(true);
            setUploadStatus({uploadIsComplete: true, uploadInProgress: false });
            setTemplateTableVisibility(false);
            setSubmittingChanges(false);
            setOpen(false);
        } catch (error) {
            if (new Date().getTime() >= exp * 1000) {
                logout();
            } else {
                setUploadStatus({ uploadIsComplete: false, uploadInProgress: false });
                setUploadValidationError({ visible: true, message: `${error}` });
                setSubmittingChanges(false);
            }
        }
    }

    const Errors = () => {
        const PotentialErrors = () => {
            const pErrors = entriesWithErrorsArray !== undefined ? entriesWithErrorsArray.map((error, index) => {
                return (
                    <React.Fragment key={index}>
                        <h3>{error[0]}</h3>
                        <p>{error[9]}</p>
                    </React.Fragment>
                );
            }) : [];

            if (pErrors.length > 0) {
                return (
                    <>
                        <h1 className="warning underline">Possible Errors</h1>
                        {pErrors}
                    </>
                );
            } else {
                return <></>
            }
        }

        const CriticalErrors = () => {
            return (
            <>
                {!dataIsValid() ? <h1 className="error underline">Errors Found</h1> : <></>}
                {title !== "Water UK Upload Template" ? <p className="error">Invalid file.</p> : <></>}
                {reportingPeriod === "" || reportingPeriod === null || reportingPeriod === undefined ? <p className="error">Invalid reporting period.</p> : <></>}
                {waterCompany !== companyName && userRole !== USER_ROLE.SUPER_ADMIN ?
                    <p className="error">{`You cannot upload data for ${waterCompany?.trim()} as you are logged in as ${companyName?.trim()}.`}</p> : <></>}
                {waterCompany === "" || waterCompany === null || waterCompany === undefined ? <p className="error">Invalid water company.</p> : <></>}
                {!versions.includes(version) ? <p className="error">Invalid template version.</p> : <></>}
            </>
            );
        };

        const UploadError = () => {
            return uploadValidationError.visible === true ? <h1 className="error">{uploadValidationError.message}</h1> : <></>
        }

        if (!dataIsValid() || entriesWithErrorsArray.length > 0) {
            return (
                <>
                    <CriticalErrors/>
                    <PotentialErrors/>
                </>
            )
        } else if (dataIsValid() && entriesWithErrorsArray.length === 0 && submittingChanges === false) {
            return (
                <>
                    <UploadError/>
                    <h2 style={{ textAlign: "center" }} className="success">No file issues detected.</h2>
                </>
            )
        } else {
            return <div className="loader-container"><Loader className="modal-loader">Uploading File</Loader></div>
        }
    }
	
	return (
		<>
			<Modal
				className="edit-modal"
				onClose={void(0)}
				onOpen={openModal}
				open={open}
				>
				<Modal.Header>Data Upload</Modal.Header>
				<Modal.Content className="edit-modal-content" scrolling>
					<Errors/>
				</Modal.Content>
				<Modal.Actions>
					<Button disabled={submittingChanges === true} type="button" onClick={closeModal} content="Cancel Upload"/>
                    <Button 
                        disabled={submittingChanges === true || dataIsValid() === false}
                        loading={submittingChanges === true} 
                        onClick={handleFileUpload} type="submit" icon="upload" content="Upload" color="green"/>
				</Modal.Actions>
			</Modal>
		</>
		);
}

const mapStateToProps = (state) => {
    const jwtIdToken = state.authReducer.authResponse.jwtIdToken;
    const companyName = state.accountReducer.Account.User.CompanyName;
    const userRole = state.authReducer.authResponse.idTokenClaims.extension_UserRole;
    const exp = state.authReducer.authResponse.idTokenClaims.exp;
    return {
        jwtIdToken,
        companyName,
        userRole,
        exp
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setTemplateTableVisibility: (bool) => dispatch(setTemplateTableVisibility(bool)),
        setAuthDetails: () => dispatch(setAuthDetails())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UploadValidationModal);