import React, { useState } from 'react';
import { Button, Popup, Form, Modal, Grid, Message, Input, Dropdown, Checkbox } from 'semantic-ui-react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { customTableAddHandleOnChange, checkForAddFieldErrors, updateSearchAddData } from '../../utils/tableUtils';
import { logout } from '../../auth/authRedirect';

const UserManagementTableAddModal = props => {
	const { tableRows, setTableRows, apiUrl, fieldsAdd, headers, setMessage, searchState, setSearchState } = props;
    const [editableRow, setEditableRow] = useState(props.initialFields);
    const [open, setOpen] = useState(props.open);
    const [error, setError] = useState({ visible: false, list: [] });
    const [submittingChanges, setSubmittingChanges] = useState(false);
    const jwtIdToken = useSelector(state => state.authReducer.authResponse.jwtIdToken);
    const exp = useSelector(state => state.authReducer.authResponse.idTokenClaims.exp);

	const openModal = () => setOpen(true);
	const closeModal = () => {
        setError({ visible: false, list: [] });
		setEditableRow(props.initialFields);
		setOpen(false);
	}

	const handleOnChange = (e, data, cellName, fieldType) => {
        setEditableRow(customTableAddHandleOnChange(e, data, editableRow, cellName, fieldType));
    };

	const handleSubmit = async() => {
        setSubmittingChanges(true);

        const fieldErrors = checkForAddFieldErrors(editableRow, fieldsAdd, headers);

        if (fieldErrors.length > 0) {
            setError({visible: true, list: fieldErrors});
            setSubmittingChanges(false);
            return;
        } else {
            setError({visible: false, list: []});
        }

        try {
            await axios.put(apiUrl, { ...editableRow }, 
                { headers: { Authorization: `Bearer ${jwtIdToken}`, "Ocp-Apim-Subscription-Key": process.env.REACT_APP_OCP_SUBSCRIPTION_KEY }})
            setMessage({ visible: true, positive: true, negative: false, header: "Changes saved", text: "Your changes have been successfully updated." });
			const tr = [...tableRows];
            tr.push({ ReactKey: tableRows.length, ...editableRow });
            setTableRows(tr);
            if (searchState !== undefined && searchState !== "") {
                setSearchState(updateSearchAddData(searchState, editableRow, tableRows.length));
            }
        } catch (error) {
            if (new Date().getTime() >= exp * 1000) {
                logout();
            } else {
                console.error(error);
                setMessage({ visible: true, positive: false, negative: true, header: `Unable to save changes.`, text: error.response.data.ErrorMessage });
                setError({ visible: false, list: [] });
            }
        } finally {
			closeModal();
            setSubmittingChanges(false);
        }
    }

    const modalRow = () => {
        return  (
                <React.Fragment>
                    <Grid>

                        {/* Forename */}
                        <Grid.Column width={6} className="label-column">
                            <label className="edit-modal-label">Forename:</label>
                        </Grid.Column>
                        <Grid.Column className="input-field" width={10}>
                            <Form.Input>
                                <Input onChange={(e, data) => handleOnChange(e, data, "Forename", "input")} type="text" defaultValue={editableRow["Forename"]} required></Input>
                            </Form.Input>
                        </Grid.Column>

                        {/* Surname */}
                        <Grid.Column width={6} className="label-column">
                            <label className="edit-modal-label">Surname:</label>
                        </Grid.Column>
                        <Grid.Column className="input-field" width={10}>
                            <Form.Input>
                                <Input onChange={(e, data) => handleOnChange(e, data, "Surname", "input")} type="text" defaultValue={editableRow["Surname"]} required></Input>
                            </Form.Input>
                        </Grid.Column>

                        {/* Email */}
                        <Grid.Column width={6} className="label-column">
                            <label className="edit-modal-label">Email Address:</label>
                        </Grid.Column>
                        <Grid.Column className="email-field" width={10}>
                            <Form.Input>
                                <Input onChange={(e, data) => handleOnChange(e, data, "EmailAddress", "email")} type="email" defaultValue={editableRow["EmailAddress"]} required></Input>
                            </Form.Input>
                        </Grid.Column>

                        {/* Active */}
                        <Grid.Column width={6} className="label-column">
                            <label className="edit-modal-label">Active:</label>
                        </Grid.Column>
                        <Grid.Column className="checkbox-field" width={10}>
                            <Form.Input>
                                <Checkbox onChange={(e, data) => handleOnChange(e, data, "Active", "checkbox")} defaultChecked={editableRow["Active"] === "Yes" ? true : false} toggle required/>
                            </Form.Input>
                        </Grid.Column>

                        {/* User Role */}
                        <Grid.Column width={6} className="label-column">
                            <label className="edit-modal-label">User Role:</label>
                        </Grid.Column>
                        <Grid.Column className="dropdown-field" width={10}>
                            <Form.Input>
                            <Dropdown onChange={(e, data) =>  handleOnChange(e, data, "UserRole", "dropdown")}
                                placeholder={editableRow["UserRole"]} selection fluid scrolling={true} selectOnBlur={false} defaultValue={editableRow["UserRole"]}
                                options={props.dropdowns["UserRole"].map((dropdown, index) => { return { key: index, value: dropdown, text: dropdown }})} required/>
                            </Form.Input>
                        </Grid.Column>

                        {/* Company */}
                        <Grid.Column width={6} className="label-column">
                            <label className="edit-modal-label">Company:</label>
                        </Grid.Column>
                        <Grid.Column className="dropdown-field" width={10}>
                            <Form.Input>
                            <Dropdown onChange={(e, data) =>  handleOnChange(e, data, "CompanyName", "dropdown")}
                                placeholder={editableRow["CompanyName"]} selection fluid scrolling={true} selectOnBlur={false} defaultValue={editableRow["CompanyName"]}
                                options={props.dropdowns["CompanyName"].map((dropdown, index) => { return { key: index, value: dropdown, text: dropdown }})} required/>
                            </Form.Input>
                        </Grid.Column>

                    </Grid>
                </React.Fragment>
        );
    }
	
	return (
		<>
			<Popup content="Add row" trigger={<Button className="table-add-btn" onClick={openModal}>Add</Button>}/>
			<Modal
				className="edit-modal"
				as={Form}
				onClose={void(0)}
				onOpen={openModal}
				open={open}
				onSubmit={handleSubmit}
				>
				<Modal.Header>Add row</Modal.Header>
				<Modal.Content className="edit-modal-content" scrolling>
					{modalRow()}
				</Modal.Content>
				<Modal.Actions>
					{error.visible ? 
						<Message
							className="edit-modal-error-message"
							negative
							header="Error"
							list={error.list}
						></Message>
						:
						<></>
					}
					<Button type="button" onClick={closeModal} content="Cancel"/>
					<Button disabled={submittingChanges === true} 
						loading={submittingChanges === true} type="submit" icon="save" content="Save changes" color="green"/>
				</Modal.Actions>
			</Modal>
		</>
		);
}

export default UserManagementTableAddModal;