export const HEADERS = [
    "Company Name",
    "Q1 Score",
    "Q2 Score",
    "Q2 Year To Date",
    "Q3 Score",
    "Q3 Year To Date",
    "Q4 Score",
    "Q4 Year To Date",
    "Year"
];

export const FIELD_HEADERS = [
    "CompanyName",
    "Q1_Score",
    "Q2_Score",
    "Q2_YTD",
    "Q3_Score",
    "Q3_YTD",
    "Q4_Score",
    "Q4_YTD",
    "Yr"
];

export const FIELDS_EDIT = {
    CompanyName: "dropdown-required",
    Q1_Score: "number",
    Q2_Score: "number",
    Q2_YTD: "number",
    Q3_Score: "number",
    Q3_YTD: "number",
    Q4_Score: "number",
    Q4_YTD: "number",
    Yr: "dropdown-required"
};

export const FIELDS_ADD = {
    CompanyName: "dropdown-required",
    Q1_Score: "number",
    Q2_Score: "number",
    Q2_YTD: "number",
    Q3_Score: "number",
    Q3_YTD: "number",
    Q4_Score: "number",
    Q4_YTD: "number",
    Yr: "dropdown-required"
};

export const INITIAL_FIELDS = {
    CompanyName: "",
    Q1_Score: null,
    Q2_Score: null,
    Q2_YTD: null,
    Q3_Score: null,
    Q3_YTD: null,
    Q4_Score: null,
    Q4_YTD: null,
    Yr: null,
};

export const SORT_FIELDS = {
    "Company Name": "CompanyName",
    "Q1 Score": "Q1_Score",
    "Q2 Score": "Q2_Score",
    "Q2 Year To Date": "Q2_YTD",
    "Q3 Score": "Q3_Score",
    "Q3 Year To Date": "Q3_YTD",
    "Q4 Score": "Q4_Score",
    "Q4 Year To Date": "Q4_YTD",
    "Year": "Yr"
};