import React, { useState, useEffect } from 'react';
import { Loader } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { setAuthDetails } from '../../../actions/authActions';
import axios from 'axios';
import MetricDefinitionsTable from '../../../components/metricDefinitionsTable/MetricDefinitionsTable';
import { HEADERS, FIELDS, INITIAL_FIELDS, FIELD_HEADERS, SORT_FIELDS, SORT_FIELD_HEADERS } from './MetricsReportPrivateTableSchema';
import { logout } from '../../../auth/authRedirect';
import './MetricsReportPrivate.scss';

const MetricsReportPrivate = props => {
    const [data, setData] = useState(null);
    const [error, setError] = useState({ visible: false, message: "" });
    const { setAuthDetails, jwtIdToken, exp } = props;
    
    useEffect(() => {
        const fetchData = async() => {
            await setAuthDetails();
            try {
                const { data } = await axios.get(process.env.REACT_APP_METRICS_URL,
                    { headers: { Authorization: `Bearer ${jwtIdToken}`, "Ocp-Apim-Subscription-Key": process.env.REACT_APP_OCP_SUBSCRIPTION_KEY }});
                const rowsWithKeys = Object.values([...data.Rows]).map((row, index) => { return { ReactKey: index, ...row } });
                data["Rows"] = rowsWithKeys;
                setData(data);
            } catch (error) {
                if (new Date().getTime() >= exp * 1000) {
                    logout();
                } else {
                    console.error(error);
                    setError({visible: true, message: `Unable to connect to Metrics Report API.\n ${error.response.data.ErrorMessage}`});
                }
            }
        }
        fetchData();
    }, [setAuthDetails, jwtIdToken, exp]);

    const ShowTable = () => {
        return (
            <>
                <h1>Metrics Report</h1>
                <MetricDefinitionsTable
                    tableName="metric-report-table-private"
                    headers={HEADERS}
                    fieldHeaders={FIELD_HEADERS}
                    rows={data.Rows}
                    fieldsEdit={FIELDS}
                    fieldsAdd={FIELDS}
                    initialFields={INITIAL_FIELDS}
                    sortFields={SORT_FIELDS}
                    sortFieldHeaders={SORT_FIELD_HEADERS}
                    dropdowns={data.Dropdowns}
                    editable={false}
                />
            </>
        );
    };

    const ShowLoading = () => {
        return error.visible === false ?
            <Loader active inline='centered'>Retrieving Metrics Report</Loader> : error.message.split("\n").map((line, i) => <h1 key={i} className="error">{line}</h1>);
    };

    return (
        <div className="metrics-report-container">
            {data === null ? <ShowLoading/> : <ShowTable/>}
        </div>
    );
}

const mapStateToProps = (state, ownProps) => {
    const emailAddress = state.authReducer.authResponse.idTokenClaims.emails[0];
    const jwtIdToken = state.authReducer.authResponse.jwtIdToken;
    const exp = state.authReducer.authResponse.idTokenClaims.exp;
    return {
        emailAddress,
        jwtIdToken,
        exp,
        ownProps
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        setAuthDetails: () => dispatch(setAuthDetails())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MetricsReportPrivate);