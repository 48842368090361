import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Table, Pagination, Checkbox, Search, Button, Modal, Icon } from 'semantic-ui-react';
import ResponseMessage from '../CustomTable/ResponseMessage/ResponseMessage';
import { getDefaultSortDirection, handleTableSort, getSortIconDirection, searchFilter } from '../../utils/tableUtils';
import { COMPANIES_HEADERS } from '../reportingPeriodsTable/ReportingPeriodsTableSchema';
import moment from 'moment';

const ResubmissionTable = props => {
    const { headers, sortFields, fieldHeaders, rows, setSelectedReportPeriod } = props;
    const [tableRows, setTableRows] = useState(rows);
    const [message, setMessage] = useState({ visible: false, positive: false, negative: false, header: "", text: "" });
    const [searchTerm, setSearchTerm] = useState("");
    const [searchState, setSearchState] = useState(tableRows);
    const paginationInitialState = { activePage: 1, rowStart: 0, rowEnd: 10 }
    const [pagination, setPagination] = useState(paginationInitialState);
    const [sortDirection, setSortDirection] = useState(getDefaultSortDirection(fieldHeaders));

    const handleSearchChange = (e, { value }) => {
        if (pagination !== paginationInitialState) {
            setPagination(paginationInitialState);
        }
        setSearchTerm(value);
        const filteredData = searchFilter(tableRows, value);
        setSearchState(filteredData);
    }

    const handlePaginationChange = (e, { activePage }) => {
        const rowStart = (activePage - 1) * paginationInitialState.rowEnd;
        const rowEnd = activePage * paginationInitialState.rowEnd;
        setPagination({ activePage, rowStart, rowEnd });
    }

    const tableHeaders = headers.map((header, index) => {
        return (
        <Table.HeaderCell 
            key={index}
            className="table-header"
            textAlign="center"
            onClick={() => handleTableSort(sortFields[header], header, sortDirection[sortFields[header]], tableRows, searchState, getDefaultSortDirection(fieldHeaders), setSortDirection, setSearchState, setTableRows)}
            content={
            <>
                {header} 
                {header === sortDirection.lastClicked ? <Icon className="table-sort-icon" size="small" name={getSortIconDirection(sortDirection[sortFields[header]])}></Icon> : <></>}
            </>
            }>
        </Table.HeaderCell>
        );
    });

    const CompaniesTable = props => {
        const headers = COMPANIES_HEADERS.map((header, index) => {
            return <Table.HeaderCell key={index} textAlign="center">{header}</Table.HeaderCell>
        });

        const CompaniesTableBody = () => props.row.Companies.map((row, index) => {
            return (
            <Table.Row key={index}>
                <Table.Cell textAlign="center">
                    {row.CompanyName}
                </Table.Cell>
                <Table.Cell textAlign="center">
                    {row.Eligible}
                </Table.Cell>
                <Table.Cell textAlign="center">
                    {row.PeriodOpen}
                </Table.Cell>
                <Table.Cell textAlign="center">
                    {row.Submitted === "Yes" ? "Yes" : "No"}
                </Table.Cell>
            </Table.Row>
            )
        });

        return (
            <Table striped singleLine>
            <Table.Header>
            <Table.Row>
                {headers}
            </Table.Row>
            </Table.Header>
            <Table.Body>
                <CompaniesTableBody/>
            </Table.Body>
            </Table>
        )
    }

    const updateSearchEditData = (searchState, editableRow) => {
        const updatedSearchData = [...searchState];
        const index = searchState.findIndex(row => row.ReactKey === editableRow.ReactKey);
        updatedSearchData[index] = editableRow;
        return updatedSearchData
    };

    const resetCheckedFields = (tableRowsCopy) => {
        return tableRowsCopy.map((row) => {
            return {...row, Checked: "No" }
        })
    }

    const handleCheckBoxClicked = (e, data, row) => {
        const tableRowsCopy = [...tableRows];
        const index = tableRows.findIndex(x => x.ReactKey === row.ReactKey);
        let newRows = resetCheckedFields(tableRowsCopy);
        const newRow = { ...newRows[index], Checked: data.checked === true ? "Yes" : "No" }

        newRows[index] = newRow;
        
        if ((newRow.ReactKey === row.ReactKey) && data.checked === false) {
            setSelectedReportPeriod(null);
        } else {
            setSelectedReportPeriod(newRow);
        }

        setTableRows(newRows);
        if (searchState !== undefined && searchState !== "") {
            setSearchState(updateSearchEditData(newRows, newRow));
            const filteredData = searchFilter(newRows, searchTerm);
            setSearchState(filteredData);
        }
    };

    const handleResetSelection = () => {
        const uncheckedRows = resetCheckedFields(tableRows);
        setTableRows(uncheckedRows);
        setSelectedReportPeriod(null);

        if (searchState !== undefined && searchState !== "") {
            const filteredData = searchFilter(uncheckedRows, searchTerm);
            setSearchState(filteredData);
        }
    }

    const TableCells = ({ rIndex, row }) => {
        return (
            <Table.Row key={rIndex}>
                <Table.Cell textAlign="center" className="table-action-buttons" collapsing>
                <Checkbox onChange={(e, data) => handleCheckBoxClicked(e, data, row)}
                        checked={row.Checked === "Yes" ? true : false}/>
                </Table.Cell>
                <Table.Cell textAlign="center">               
                <Modal trigger={<li className="view-companies">View Companies</li>} 
                    header={`Companies`}
                    content={<Modal.Content scrolling>
                        <div className="view-metrics-definition-modal display-linebreak">
                            <CompaniesTable row={row}/>
                        </div>
                        </Modal.Content>} 
                    actions={[{ key: 'OK', content: 'OK', positive: true }]}/>
                </Table.Cell>
                <Table.Cell textAlign="center">{row.PeriodType}</Table.Cell>
                <Table.Cell textAlign="center">{row.PeriodStart}</Table.Cell>
                <Table.Cell textAlign="center">{row.PeriodEnd}</Table.Cell>
                <Table.Cell textAlign="center">{row.Deadline}</Table.Cell>
                <Table.Cell textAlign="center">
                    { moment(moment(), 'DD-MM-YYYY').isBetween(moment(row.PeriodEnd, 'DD-MM-YYYY'), moment(row.Deadline, 'DD-MM-YYYY'), null, '(]') ? "Yes" : "No" }
                </Table.Cell>
                <Table.Cell textAlign="center" width={2}>{row.InitialEmailReminder}</Table.Cell>
                <Table.Cell textAlign="center" width={2}>{row.SecondEmailReminder}</Table.Cell>
                <Table.Cell textAlign="center" width={2}>{row.ThirdEmailReminder}</Table.Cell>
            </Table.Row>
        );
    }

    const Body = () => {
        if (searchState.length === 0) {
            return <><Table.Row><Table.Cell colSpan='16'><h1 className="table-no-results">No results found.</h1></Table.Cell></Table.Row></>
        } else if (searchState.length > 0) {
            return searchState.slice(pagination.rowStart, pagination.rowEnd).map((row, rIndex) => {
                return <TableCells key={rIndex} rIndex={rIndex} row={row}/>
            });
        } else {
            return tableRows.slice(pagination.rowStart, pagination.rowEnd).map((row, rIndex) => {
                return <TableCells key={rIndex} rIndex={rIndex} row={row}/>
            });
        }
    };

    const Footer = () => {
        return (
            <Table.Row>
                <Table.HeaderCell colSpan='16'>
                    <Pagination
                       defaultActivePage={pagination.activePage}
                       onPageChange={handlePaginationChange}
                       totalPages={searchState.length === 0 ? 0 : searchState.length > 0 ? Math.ceil(searchState.length / paginationInitialState.rowEnd) : Math.ceil(tableRows.length / paginationInitialState.rowEnd)}
                       ellipsisItem={null}
                       disabled={searchState.length === 0 ? true : searchState.length > 0 ? searchState.length < paginationInitialState.rowEnd : tableRows.length < paginationInitialState.rowEnd}
                    />
                    <Button style={{ marginLeft: '2em' }} onClick={handleResetSelection}>Reset Selection</Button>
                </Table.HeaderCell>
            </Table.Row>
        )
    }

    const renderTable = () => {
        return tableRows.length > 0
        ?
            <div className="custom-table-container">
                {message.visible ? <ResponseMessage {...message} setMessage={setMessage}/> : <></>}
                <Search className="table-search" placeholder="Search" onSearchChange={handleSearchChange} open={false}></Search>
                <Table striped celled compact>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell singleLine textAlign="center">Action</Table.HeaderCell>
                            {tableHeaders}
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        <Body/>
                    </Table.Body>
                    <Table.Footer>
                        <Footer/>
                    </Table.Footer>
                </Table>

            </div>
        :
        <h2 className="no-table-data">No table data.</h2>
    };

    return renderTable();
}

ResubmissionTable.propTypes = {
    tableName: PropTypes.string,
    headers: PropTypes.array,
    body: PropTypes.array,
    fieldsEdit: PropTypes.object,
    fieldsAdd: PropTypes.object,
    dropdowns: PropTypes.object,
    apiUrl: PropTypes.string,
    editable: PropTypes.bool,
    rowDeletion: PropTypes.bool,
    initialFields: PropTypes.object
};

ResubmissionTable.defaultProps = {
    tableName: "table-" + new Date().getTime(),
    headers: [],
    body: [],
    fieldsEdit: {},
    fieldsAdd: {},
    dropdowns: {},
    apiUrl: null,
    editable: false,
    rowDeletion: false,
    initialFields: {}
};

export default ResubmissionTable;