import React, { useState } from 'react';
import { Modal, Button, Table, Form, Checkbox } from 'semantic-ui-react';
import { COMPANIES_HEADERS } from './ReportingPeriodsTableSchema';
import './ReportingPeriodsCompanies.scss';

const ReportingPeriodsCompaniesEditModal = props => {
    const { companyModalIsOpen, setCompanyModalIsOpen, editableRow, setEditableRow, index } = props;
    const [editableCompanies, setEditableCompanies] = useState(editableRow[index].Companies)

    const headers = COMPANIES_HEADERS.map((header, index) => {
        return <Table.HeaderCell key={index} textAlign="center">{header}</Table.HeaderCell>
    });

    const handleOnChange = (e, data, cellName, rIndex) => {
        let companiesCopy = [...editableCompanies];
        let updatedCompany = {...editableCompanies[rIndex]};
        updatedCompany[cellName] = data.checked === true ? "Yes" : "No";
        companiesCopy[rIndex] = updatedCompany;
        setEditableCompanies(companiesCopy);
    };

    const handleSaveChanges = () => {
        let editableRowsCopy =  [...editableRow];
        let editableRowCopy = {...editableRow[index]}
        editableRowCopy = editableCompanies;
        editableRowsCopy[index]["Companies"] = editableRowCopy;
        setEditableRow(editableRowsCopy)
        setCompanyModalIsOpen(false);
    };

    const handleCancel = () => {
        let originalCompanies = [...editableRow[index].Companies];
        setEditableCompanies(originalCompanies);
        setCompanyModalIsOpen(false)
    };

    const Body = () => editableCompanies.map((row, index) => {
        return (
            <Table.Row key={index}>
                <Table.Cell textAlign="center">
                    {row.CompanyName}
                </Table.Cell>
                <Table.Cell textAlign="center">
                    <Form.Input className="company-checkbox">
                        <Checkbox onChange={(e, data) => handleOnChange(e, data, "Eligible", index)}
                            defaultChecked={row.Eligible === "Yes" ? true : false} toggle/>
                    </Form.Input>
                </Table.Cell>
                <Table.Cell textAlign="center">
                    <Form.Input className="company-checkbox">
                        <Checkbox onChange={(e, data) => handleOnChange(e, data, "PeriodOpen", index)}
                            defaultChecked={row.PeriodOpen === "Yes" ? true : false} toggle/>
                    </Form.Input>
                </Table.Cell>
                <Table.Cell textAlign="center">
                    <Form.Input className="company-checkbox">
                        {row.Submitted === "Yes" ? "Yes" : "No"}
                    </Form.Input>
                </Table.Cell>
            </Table.Row>
        );      
    });

    return (
        <Modal
        dimmer="blurring"
        onClose={void(0)}
        onOpen={() => setCompanyModalIsOpen(true)}
        open={companyModalIsOpen}
        trigger={<Button size="large" type="button">Edit Companies</Button>}
        >
        <Modal.Header>Companies</Modal.Header>
        <Modal.Content scrolling>
        <Table striped singleLine>
            <Table.Header>
            <Table.Row>
                {headers}
            </Table.Row>
            </Table.Header>
            <Table.Body>
                <Body/>
            </Table.Body>
        </Table>
        </Modal.Content>
        <Modal.Actions className="reporting-periods-companies-modal-action">
        <Button onClick={handleCancel}>Cancel</Button>
        <Button content="Continue" color="green" onClick={handleSaveChanges}/>
        </Modal.Actions>
        </Modal>
    )
}

export default ReportingPeriodsCompaniesEditModal;
